@import "../../assets/sass/importFiles";
.list-wrapper {
  .list-ul {
    list-style: none;
    margin: 0;
  }
  .list-item {
    color: $purple4;
    font-size: 13px;
    padding: 10px;
    cursor: pointer;
    position: relative;
    transition: 0.3s all;
    border-bottom: 1px solid #eae3ff;
    &:nth-child(even) {
      //background: $offwhite4;
    }
    &:hover {
      font-weight: 500;
    }
    &.selected-item {
      background: $offwhite2;
      font-weight: 500;
    }
    &.activeItem {
      color: $yellow;
    }
  }
}
