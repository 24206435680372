//--------------------------- OVERALL PROJECT VARIABLES STARTS-----------------------
//variables
//--COLOR VARIABLES
//original colors
$blue: #3ba2f6;
$green: #04caa7;
$lightBlue: #3cd4f6;
$yellow: #f6aa3a;
$orange: #f17849;
$red: #f54949;
$lightPurple: #8f82f5;
$lightGreen: #94d954;
$mediumPurple: #b78ebf;

////others
$white: #ffffff;
$black: #000000;
$blueDark: #0f5a90;
$gray: #646464;
$gray2: #c3c3c3;
$gray3: #cccccc;
$gray60: #999999;
$gray20: #333333;
$darkBlue: #3e335e;
$darkGreen: #006400;
$blue-white: #e7f4fd;
$purple: #8d82ab;
$purple2: #9783cf;
// $purple2: #7b65b8; // old color
$purple3: #beb6e0;
$purple4: #9783cf;
// $purple4: #9783cf; //old color
$purple5: #5e4999;
$purple6: #6650a3;
$purple6: #6b54ab;
$purple7: #beb6d9;
$purple8: #726496;
$lightPurple2: #cdc5eb;
// $lightPurple2: #cdc5eb;//old color

$ViewsCnt: #f1edff;
$offwhite: #f5f2ff;
$offwhite2: #dfd9f7;
$offwhite3: #f3f0ff;
$offwhite4: #f9f7ff;
$linkedinBg: #00819f;
$success: #7cb342;
$info: #1976d2;
$warning: #ffa000;
$error: #e53935;
$tooltip: #eaf3fa;
$loadingmaskText: #666;
$loadingWrapper: #e5e5e5;
$blue1: #807db9;
//side nave skelton colors

$grayScale: #ddd;
$base-color: #f4f4f4;
$shine-color: rgba(229, 229, 229, 0.8);
$shine-background-color: #584d78;

$imp: !important;
$RubikRegular: "rubik", Arial, sans-sarif;
$RubikBold: "rubikbold", Arial, sans-sarif;
$RubikMedium: "rubikmedium", Arial, sans-sarif;
//--FONTAWESOME ICONS STARTS
$fa-angle-left: "\f104";
$fa-warning: "\f071";
$fa-angle-right: "\f105";
$fa-info-circle: "\f05a";
$fa-remove: "\f00d";
$fa-pencil: "\f040";
$fa-plus-circle: "\f055";
$down: "\f107";
$up: "\f106";
$fa-arrows: "\f047";
$fa-circle: "\f111";
$fa-eye: "\f06e";
$fa-check: "\f00c";
$fa-times: "\f00d";
$fa-chevron-left: "\f053";
$fa-chevron-right: "\f054";
$fa-toggle-on: "\f205";
$fa-toggle-off: "\f204";
$fa-question-circle: "\f059";
$fa-question: "\f128";
$fa-arrows: "\f047";
$fa-square-o: "\f096";
$fa-check-square-o: "\f046";
$fa-plus: "\f067";
$fa-caret-down: "\f0d7";
$fa-file-pdf-o: "\f1c1";
$fa-ellipsis-h: "\f141";
$fa-file-image-o: "\f1c5";
$fa-facebook: "\f09a";
$fa-linkedin: "\f0e1";

//icon moon icons
$fi-document: "\e005";
$fi-documents: "\e006";
$fi-clipboard: "\e008";
$fi-calendar: "\e00d";
$fi-presentation: "\e00e";
$fi-video: "\e011";
$fi-toolbox: "\e014";
$fi-briefcase: "\e015";
$fi-bargraph: "\e018";
$fi-edit: "\e01c";
$fi-ribbon: "\e01e";
$fi-flag: "\e024";
$fi-gears: "\e02b";
$fi-key: "\e02c";
$fi-pricetags: "\e02f";
$fi-pencil: "\e032";
$fi-tools: "\e033";
$fi-tools-2: "\e034";
$fi-linegraph: "\e039";
$fi-globe: "\e045";
$fi-square-check: "\e046";
$fi-map-pin: "\e047";
$fi-chat: "\e049";
$fi-stop: "\e054";
$fi-alarmclock: "\e059";
$fi-facebook: "\e05d";
$fi-twitter: "\e05e";
$fi-googleplus: "\e05f";
$fi-rss: "\e060";
$fi-tumblr: "\e061";
$fi-linkedin: "\e062";
$fi-dribbble: "\e063";
$fi-access_alarms: "\e191";
$fi-check: "\e5ca";
$fi-close: "\e5cd";
$fi-cog: "\e810";
$fi-envelope: "\e818";
$fi-license: "\e822";
$fi-user: "\e82a";
$fi-store: "\e82d";
$fi-alarm: "\e855";
$fi-alarm1: "\e858";
$fi-schedule: "\e8b5";
$fi-puzzle: "\e900";
$fi-collaboration-puzzle: "\e901";
$fi-share-icon: "\e902";
$fi-ApprovalRejected: "\e904";
$fi-Approved: "\e905";
$fi-Attachement: "\e906";
$fi-Fwd: "\e907";
$fi-Home: "\e908";
$fi-Rwd: "\e909";
$fi-SendForApproval: "\e90a";
$fi-Timepicker: "\e90b";
$fi-WaitingForApproval: "\e90c";
$fi-eye: "\e90d";
$fi-calendar1: "\e90e";
$fi-calendar2: "\e90f";
$fi-calendar3: "\e910";
$fi-calendar4: "\e911";
$fi-eye1: "\e912";
$fi-eye-off: "\e913";
$fi-comments: "\e914";
$fi-dots-three-horizontal: "\e915";
$fi-vector_65_02: "\e916";
$fi-DashboardCompany: "\e917";
$fi-user1: "\e918";
$fi-checkmark: "\e919";
$fi-cancel: "\e91a";
$fi-DashboardFreelance: "\e91b";
$fi-present: "\e91c";
$fi-ic_settings_backup_restore_48px: "\e91d";
$fi-cart: "\e91e";
$fi-resume: "\e91f";
$fi-feedback: "\e920";
$fi-message: "\e921";
$fi-product: "\e922";
$fi-request: "\e923";
$fi-help: "\e924";
$fi-settingIcon: "\e925";
$fi-statistics: "\e926";
$fi-certification: "\e927";
$fi-close1: "\e928";
$fi-flag1: "\e929";
$fi-industry: "\e92a";
$fi-language: "\e92b";
$fi-leftArrow: "\e92c";
$fi-menu: "\e92d";
$fi-profile: "\e92e";
$fi-request1: "\e92f";
$fi-save: "\e930";
$fi-search: "\e931";
$fi-shortlist: "\e932";
$fi-skill: "\e933";
$fi-trash: "\e934";
$fi-location: "\e935";
$fi-skillIcon: "\e936";
$fi-keyIcon: "\e937";
$fi-edit1: "\e938";
$fi-send: "\e939";
$fi-visit-request: "\e93a";
$fi-finger: "\e93b";
$fi-saveIcon: "\e93c";
$fi-back: "\e93d";
$fi-hand: "\e93e";
$fi-reply: "\e93f";
$fi-saveCloud: "\e940";
$fi-createMessage: "\e941";
$fi-createOpportuonity: "\e942";
$fi-About: "\e943";
$fi-globeIcon: "\e944";
$fi-draftOpportunity: "\e945";
$fi-inboxMessage: "\e946";
$fi-opportunity: "\e947";
$fi-sendOpportunity: "\e948";
$fi-sentMessage: "\e949";
$fi-helpIcon: "\e94a";
$fi-collaboration: "\e94b";
$fi-alert-circle: "\e94c";
$fi-alert-octagon: "\e94d";
$fi-alert-triangle: "\e94e";
$fi-arrow-down: "\e94f";
$fi-arrow-down-circle: "\e950";
$fi-arrow-down-left: "\e951";
$fi-arrow-down-right: "\e952";
$fi-arrow-left: "\e953";
$fi-arrow-left-circle: "\e954";
$fi-arrow-right: "\e955";
$fi-arrow-right-circle: "\e956";
$fi-arrow-up: "\e957";
$fi-arrow-up-circle: "\e958";
$fi-arrow-up-left: "\e959";
$fi-arrow-up-right: "\e95a";
$fi-check-circle: "\e95b";
$fi-compass: "\e95c";
$fi-help-circle: "\e95d";
$fi-pie-chart: "\e95e";
$fi-rotate-ccw: "\e95f";
$fi-rotate-cw: "\e960";
$fi-watch: "\e961";
$fi-checkmark1: "\e962";
$fi-log-out: "\e963";
$fi-savesearch: "\e964";
$fi-approve: "\e965";
$fi-attention: "\e966";
$fi-date-picker: "\e967";
$fi-expenses: "\e968";
$fi-snapshot: "\e969";
$fi-uniE96A: "\e96a";
$fi-uniE96B: "\e96b";
$fi-uniE96C: "\e96c";
$fi-uniE96D: "\e96d";
$fi-uniE96E: "\e96e";
$fi-uniE96F: "\e96f";
$fi-tictell-colored: "\e970";
$fi-timesheet: "\e971";
$fi-plus: "\ea0a";
$fi-cloud-download: "\f00b";
$fi-cloud-upload: "\f00c";
$fi-bars: "\f0c9";
$fi-About1: "\e9009";
$fi-Approve: "\e9019";
$fi-Approve-arrow: "\e9029";
$fi-Approve-check: "\e9039";
$fi-Approve-clock: "\e9049";
$fi-Approve-cross: "\e9059";
$fi-Assort: "\e9069";
$fi-Available: "\e9079";
$fi-Collaboration: "\e9089";
$fi-Collaboration-arrow: "\e9099";
$fi-Collaboration-check: "\e909a";
$fi-Collaboration-clock: "\e909b";
$fi-Collaboration-cross: "\e909c";
$fi-Collaboration-plus: "\e909d";
$fi-Embark: "\e909e";
$fi-Expenses: "\e909f";
$fi-Expenses-plus: "\e9109";
$fi-Headsup: "\e9119";
$fi-iPro: "\e9129";
$fi-Message: "\e9139";
$fi-Message-arrow: "\e9149";
$fi-Message-plus: "\e9159";
$fi-Opportunity: "\e9169";
$fi-Opportunity-arrow: "\e9179";
$fi-Opportunity-check: "\e9189";
$fi-Opportunity-clock: "\e9199";
$fi-Opportunity-minus: "\e919a";
$fi-Opportunity-plus: "\e919b";
$fi-Phillip: "\e919c";
$fi-Pool-new: "\e919d";
$fi-Saved-search: "\e919e";
$fi-Saved-search-plus: "\e919f";
$fi-Score: "\e9209";
$fi-Score-minus: "\e9219";
$fi-Score-plus: "\e9229";
$fi-Search: "\e9239";
$fi-Searcher: "\e9249";
$fi-Shortlist: "\e9259";
$fi-Shortlist-cross: "\e9269";
$fi-Shortlist-plus: "\e9279";
$fi-Snapshot-filled: "\e9289";
$fi-Snapshot-outline: "\e9299";
$fi-Tictell: "\e929a";
$fi-Timesheet: "\e929b";
$fi-Timesheet-plus: "\e929c";
$fi-Workplace: "\e929d";
$fi-Shortlist---clone: "\e9308";

$fi-About               :'\e900';
$fi-Approve             :'\e901';
$fi-Approve-arrow       :'\e902';
$fi-Approve-check       :'\e903';
$fi-Approve-clock       :'\e904';
$fi-Approve-cross       :'\e905';
$fi-Assort              :'\e906';
$fi-Available           :'\e90b';
$fi-Collaboration       :'\e90c';
$fi-Collaboration-arrow :'\e90d';
$fi-Collaboration-check :'\e90e';
$fi-Collaboration-clock :'\e90f';
$fi-Collaboration-cross :'\e910';
$fi-Collaboration-plus  :'\e911';
$fi-Datepicker          :'\e912';
$fi-Delete-inactive     :'\e916';
$fi-Education           :'\e917';
$fi-Embark              :'\e918';
$fi-Expenses            :'\e919';
$fi-Expenses-plus       :'\e91a';
$fi-Headsup             :'\e91b';
$fi-Industry            :'\e920';
$fi-iPro                :'\e921';
$fi-Keyword             :'\e922';
$fi-Language            :'\e923';
$fi-Location            :'\e924';
$fi-Message             :'\e925';
$fi-Message-arrow       :'\e926';
$fi-Message-plus        :'\e927';
$fi-Monitoring          :'\e928';
$fi-Opportunity         :'\e929';
$fi-Opportunity-arrow   :'\e92a';
$fi-Opportunity-check   :'\e92b';
$fi-Opportunity-clock   :'\e92c';
$fi-Opportunity-minus   :'\e92d';
$fi-Opportunity-plus    :'\e92e';
$fi-Phillip             :'\e92f';
$fi-Pool                :'\e930';
$fi-Presentation        :'\e931';
$fi-Role                :'\e932';
$fi-Saved-search        :'\e933';
$fi-Saved-search-plus   :'\e934';
$fi-Score               :'\e935';
$fi-Score-minus         :'\e936';
$fi-Score-plus          :'\e937';
$fi-Search              :'\e938';
$fi-Searcher            :'\e939';
$fi-Shortlist           :'\e93a';
$fi-Shortlist-clone     :'\e93b';
$fi-Shortlist-cross     :'\e93c';
$fi-Shortlist-plus      :'\e93d';
$fi-Skill               :'\e93e';
$fi-Snapshot-filled     :'\e945';
$fi-Snapshot-outline    :'\e946';
$fi-Tictell             :'\e947';
$fi-Timesheet           :'\e948';
$fi-Timesheet-minus     :'\e949';
$fi-Timesheet-plus      :'\e94a';
$fi-Workplace           :'\e94b';
$fi-commenting           :'\e94c';
