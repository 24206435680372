@import "../../../../../assets/sass/importFiles";

.shortlist-resume-component {
  width: 100%;
  border-radius: 4px;
  color: $purple4;
  font-family: "rubik", Arial, sans-sarif;
  list-style: none;
  margin: 0;
  padding: 0;

  .skills {
    width: 50%;
    float: left;
    padding: 5px 7px 0 25px;
    position: relative;
    color: $purple4;
    overflow: hidden;
    height: 22px;
    &:before {
      @extend %fi;
      color: white;
      width: 20px;
      padding-top: 4px;
      height: 20px;
      display: inline-block;
      box-sizing: border-box;
      vertical-align: bottom;
      text-align: center;
      border-radius: 50%;
      background: $purple4;
      font-size: 11px;
      content: $fi-skillIcon;
      position: absolute;
      left: 0;
      top: 2px;
    }
    .SelectedItem & {
      color: $purple4;
    }
  }

  .closeBtn {
    margin-top: -2px;
    right: 10px;
    &:hover {
      background: $red !important;
    }
  }
  .short-list {
    width: 16px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 16px;
    &:after {
      content: "";
      display: inline-block;
      background: url(../../../../../assets/images/Icons-sf606c27a8a.png) -2438px
        0 no-repeat !important;
      height: 19px;
      width: 15px;
    }
    &:active {
      content: "";
      display: inline-block;
      background: url(../../../../../assets/images/Icons-sf606c27a8a.png) -2402px
        0 no-repeat !important;
      height: 19px;
      width: 16px;
    }
  }
  .short-list-active {
    width: 16px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 16px;
    bottom: 35px;
    &:after {
      content: "";
      display: inline-block;
      background: url(../../../../../assets/images/Icons-sf606c27a8a.png) -2402px
        0 no-repeat !important;
      height: 19px;
      width: 16px;
    }
  }

  .profile {
    overflow: hidden;
    width: 90%;
    margin-bottom: 4px;
    height: 15px;
  }
  .location {
    float: left;
    width: 40%;
    height: 22px;
    padding: 5px 7px 0 25px;
    position: relative;
    color: $purple4;
    overflow: hidden;
    &:before {
      content: $fi-location;
      @extend %fi;
      color: white;
      width: 20px;
      height: 20px;
      display: inline-block;
      box-sizing: border-box;
      vertical-align: bottom;
      text-align: center;
      padding-top: 4px;
      border-radius: 50%;
      background: $purple4;
      font-size: 11px;
      position: absolute;
      left: 0;
      top: 2px;
    }
    .SelectedItem & {
      color: $purple4;
    }
  }
  .list-item {
    @extend %clearfix;
  }
  .SkillNum {
    width: 55px;
    padding: 22px 0 0;
    background: $purple2;
    color: $white;
    font-family: $RubikRegular;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: table-cell;
    vertical-align: middle;
  }
  .markedRed {
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 5px;
      background: $red;
    }
  }
  .markedGreen {
    @extend .markedRed;
    &:after {
      background: $green;
    }
  }
  .markedYellow {
    @extend .markedRed;
    &:after {
      background: $yellow;
    }
  }
  .correlation-item {
    .profile {
      padding-left: 50px;
    }
    .location {
      padding-left: 75px;
      &::before {
        left: 50px;
      }
    }
  }
}
