@import "../../../../assets/sass/importFiles";
@import "../../variables.scss";
.monitoring-view {
  min-height: 100%;
  display: flex;
  flex-flow: row;
  > * {
    flex: 1;
  }
  color: #8d82ab;
  background: #dfd9f7;
  width: 100%;
  .mo-col-1 {
    display: flex;
    flex-flow: column;
    margin: 10px;
    justify-content: space-evenly;
    flex-basis: 10%;
    max-width: 200px;
    .mo-nav {
      p {
        min-width: 150px;
      }
      > div {
        display: flex;
        label {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }
  // .mo-nav:hover{
  //   border: 2px solid #8D82AB;
  // }
  .mo-nav {
    cursor: pointer;
    min-width: 180px;
    color: #8d82ab;
    padding: 10px;
    margin: 10px;
    border-radius: 5%;
    > div {
      display: flex;
      label {
        font-size: 16px;
        font-weight: bold;
      }
    }
    p {
      margin-top: 5px;
      font-size: 12px;
      text-align: center;
    }
  }
  .onBorders {
    border: 2px solid #8d82ab;
  }
  .transparentBorder {
    border: 2px solid transparent;
  }
  .mo-col-3 {
    display: flex;
    flex-flow: column;
    margin: 10px;
    justify-content: center;
    flex-basis: 10%;
    max-width: 200px;
    .mo-nav {
      margin-bottom: 20px;
    }
    .count-label {
      max-width: 66px;
      font-size: 16px;
      font-weight: bold;
      word-break: normal;
      text-align: center;
      margin-top: 10px;
    }
    p {
      width: 140px !important;
      text-align: center;
    }
  }
  .mo-col-2 {
    flex: 15%;
    margin: 10px;
    max-width: 30%;
    justify-content: flex-start;
    max-width: 300px;
    .mo-list-item {
      margin: 10% 0px;
      height: 80vh;
      overflow: auto;
      max-height: 80vh;
      li:hover {
        background: white !important;
      }
      .active {
        background: white !important;
      }
      li {
        font-size: 12px;
        border: 1px solid #8d82ab;
        cursor: pointer;
        padding: 10px;
        background: #f0edfe;
        color: #8d82ab !important;
        margin: 5px;
        border-radius: 15px;
        max-width: 95%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .mo-col-4 {
    display: flex;
    flex-flow: column;
    margin: 10px;
    justify-content: center;
    flex-basis: 20%;
    max-width: 400;
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    li {
      padding: 2px;
      background: #f0edfe;
      color: #8d82ab;
      margin: 5px;
      border-radius: 5px;
      max-width: 55px;
      text-align: center;
    }
    .check-items {
      display: flex;
      margin-top: 10px;
      label {
        font-size: 12px;
      }
    }
    .select-input {
      margin-top: 20px;
      background-color: #f0edfe !important;
      border-radius: 10px;
    }
    .action-btn {
      // color: #f0edfe;
      // background: #05caa8;
      // border: transparent;
      // border-radius: 10px;
      // align-self: center;
      // padding: 10px;
      // margin-top: 20px;
      color: white;
      background: #05caa8;
      border: transparent;
      border-radius: 5px;
      align-self: center;
      margin-bottom: 5px;
      padding: 5px 15px;
    }
  }
  .timesheet-icon {
    display: inline-block;
    border: none;
    min-width: 20px;
    min-height: 20px;
    font-size: 0;
    align-self: center;
    margin-top: 0px;
    &:after {
      @extend %Im;
      font-size: 20px;
      content: $fi-Shortlist;
      color: $purple4;
      margin: 0px 10px 0px 0px;
      width: 20px;
      height: 20px;
      background-size: 20px;
    }
  }
  .shortlist-icon {
    @extend .iconsStyles;
    &:after {
      font-size: 30px;
      content: $fi-Shortlist;
    }
  }
  .opportunity-icon {
    @extend .iconsStyles;
    &:after {
      font-size: 30px;
      content: $fi-Opportunity;
    }
  }
  .saved-searches-icon {
    @extend .iconsStyles;
    &:after {
      font-size: 30px;
      content: $fi-Saved-search;
    }
  }
  .availabilityIcon {
    @extend .iconsStyles;
    width: 35px !important;
    height: 35px !important;
    &:after {
      font-size: 40px;
      content: $fi-Available;
    }
  }
  .scorePlusIcon {
    @extend .iconsStyles;
    width: 35px !important;
    height: 35px !important;
    &:after {
      font-size: 40px;
      content: $fi-Score-plus;
    }
  }
  .newIproIcon {
    @extend .iconsStyles;
    width: 35px !important;
    height: 35px !important;
    &:after {
      font-size: 40px;
      content: $fi-iPro;
    }
  }
  .iconsStyles {
    display: inline-block;
    border: none;
    min-width: 20px;
    min-height: 20px;
    font-size: 0;
    align-self: center;
    margin-top: 0px;
    &:after {
      @extend %Im;
      color: #8d82ab;
      margin: 0px 10px 0px 0px;
      width: 20px;
      height: 20px;
      background-size: 20px;
    }
  }
  input[type="checkbox"] {
    -webkit-transform: scale(1);
    transform: scale(1);
    margin-top: 5px;
    padding: 10px;
    margin-right: 5px;
    background: #fff;
    background: rgb(223, 217, 247);
    color: black;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #8d82ab;
    position: relative;
    /* left: -5px; */
    /* top: -5px; */
    border-radius: 5px;
    height: 16px;
    width: 16px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    &:checked {
      background: rgb(151, 131, 207);
    }
    outline: none;
  }
  .select-input .Select-value-label {
    color: #8d82ab !important;
  }
}

.monitoring-view-mobile {
  .empty-message-search {
    font-family: "rubik", Arial, sans-sarif;
    font-size: 15px;
    color: #beb6e0;
    display: block;
    text-align: center;
    width: 100%;
    margin: 20% auto;
    line-height: 1.2;
  }
  .text-center- {
    text-align: center;
  }
  .main-inner-mobile {
    padding: 10px 2%;
  }

  .ant-collapse-expand-icon {
    position: absolute;
    right: 0;
  }
  .paragraph {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    color: #413c3b;
  }
  .header-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    margin: 0;
    color: #8d83aa;
  }
  display: flex;
  flex-flow: row;
  > * {
    flex: 1;
  }
  color: #8d82ab;
  // background: #dfd9f7;
  width: 100%;
  .mo-col-1 {
    display: flex;
    flex-flow: column;
    margin: 10px;
    justify-content: space-evenly;
    flex-basis: 10%;
    max-width: 200px;
    .mo-nav {
      p {
        min-width: 150px;
      }
      > div {
        display: flex;
        label {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }
  // .mo-nav:hover{
  //   border: 2px solid #8D82AB;
  // }
  .mo-nav {
    cursor: pointer;
    min-width: 180px;
    color: #8d82ab;
    padding: 10px;
    margin: 10px;
    border-radius: 5%;
    > div {
      display: flex;
      label {
        font-size: 16px;
        font-weight: bold;
      }
    }
    p {
      margin-top: 5px;
      font-size: 12px;
      text-align: center;
    }
  }
  .onBorders {
    border: 2px solid #8d82ab;
  }
  .transparentBorder {
    border: 2px solid transparent;
  }
  .mo-col-3 {
    // display: flex;
    // flex-flow: column;
    // margin: 10px;
    // justify-content: center;
    // flex-basis: 10%;
    // max-width: 200px;
    .mo-nav {
      margin-bottom: 20px;
    }
    .count-label {
      max-width: 66px;
      font-size: 16px;
      font-weight: bold;
      word-break: normal;
      text-align: center;
      margin-top: 10px;
    }
    // p {
    //   width: 140px !important;
    //   text-align: center;
    // }
  }
  .mo-col-2 {
    width: 100%;
    .mo-list-item {
      margin: 12px 0px;
      overflow: auto;
      li:hover {
        background: #8f82f5;
      }
      .active {
        background: #8f82f5;
        color: #ffffff;
      }
      li {
        font-weight: 600;
        text-align: center;
        font-size: 12px;
        border: 1px solid #8d82ab;
        cursor: pointer;
        padding: 10px;
        background: #f0edfe;
        color: #8f82f5;
        margin: 5px 0px;
        border-radius: 15px;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .action-btn {
      color: white;
      background: #05caa8;
      border: transparent;
      border-radius: 5px;
      align-self: center;
      margin-bottom: 5px;
      padding: 5px 15px;
      margin-top: 20px;
    }
  }
  .mo-col-4 {
    display: flex;
    flex-flow: column;
    margin: 10px;
    justify-content: center;
    flex-basis: 20%;
    max-width: 400;
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    li {
      padding: 2px;
      background: #f0edfe;
      color: #8d82ab;
      margin: 5px;
      border-radius: 5px;
      max-width: 55px;
      text-align: center;
    }
    .check-items {
      display: flex;
      margin-top: 10px;
      label {
        font-size: 12px;
        color: #413c3b;
        display: flex;
        align-items: center;
      }
    }
    .select-input {
      margin-top: 20px;
      background-color: #f0edfe !important;
      border-radius: 10px;
    }
    .action-btn {
      color: white;
      background: #05caa8;
      border: transparent;
      border-radius: 5px;
      align-self: center;
      margin-bottom: 5px;
      padding: 5px 15px;
      margin-top: 20px;
    }
  }
  .timesheet-icon {
    display: inline-block;
    border: none;

    font-size: 0;
    align-self: center;
    margin-top: 0px;
    &:after {
      @extend %Im;
      font-size: 20px;
      content: $fi-Shortlist;
      color: $purple4;
      margin: 0px 10px 0px 0px;
      width: 20px;
      height: 20px;
      background-size: 20px;
    }
  }
  .shortlist-icon {
    @extend .iconsStyles;
    &:after {
      font-size: 18px;
      content: $fi-Shortlist;
    }
  }
  .opportunity-icon {
    @extend .iconsStyles;
    &:after {
      font-size: 18px;
      content: $fi-Opportunity;
    }
  }
  .saved-searches-icon {
    @extend .iconsStyles;
    &:after {
      font-size: 18px;
      content: $fi-Saved-search;
    }
  }
  .availabilityIcon {
    @extend .iconsStyles;

    &:after {
      font-size: 18px;
      content: $fi-Available;
    }
  }
  .scorePlusIcon {
    @extend .iconsStyles;

    &:after {
      font-size: 18px;
      content: $fi-Score-plus;
    }
  }
  .newIproIcon {
    @extend .iconsStyles;

    &:after {
      font-size: 18px;
      content: $fi-iPro;
    }
  }
  .iconsStyles {
    display: inline-block;
    border: none;
    min-width: 20px;
    min-height: 20px;
    font-size: 0;
    align-self: center;
    margin-top: 0px;
    &:after {
      @extend %Im;
      color: #8d82ab;
      margin: 0px 10px 0px 0px;
      width: 20px;
      height: 20px;
      background-size: 20px;
    }
  }
  input[type="checkbox"] {
    -webkit-transform: scale(1);
    transform: scale(1);
    margin-top: 5px;
    padding: 10px;
    margin-right: 5px;
    background: #fff;
    background: rgb(223, 217, 247);
    color: black;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #8d82ab;
    position: relative;
    /* left: -5px; */
    /* top: -5px; */
    border-radius: 5px;
    height: 16px;
    width: 16px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    &:checked {
      background: rgb(151, 131, 207);
    }
    outline: none;
  }
  .select-input .Select-value-label {
    color: #8d82ab !important;
  }
  .no-data {
    display: flex;
    height: 100%;
    align-items: center;
  }
}
