@import "../../../../assets/sass/importFiles";

.skill {
  margin-top: 30px;

  // padding: 0px 104px 70px 104px;
  @media (max-width: 1000px) {
    // padding: 0px 50px 70px 50px;
  }
  // @media (max-width: 768px) {
  //   padding: 20px 0px;
  // }

  .skillHead {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 125%;
    color: #413c3b;
    // margin: 30px 0px 20px 0px;
  }

  .textBox {
    width: auto;
    background: #f7f6fe;
    border-radius: 8px;
    padding: 10px;
    height: 50px;
    width: 100%;
    // max-width: 317px;
    display: flex;
    align-items: center;
    margin-right: 16px;
    width: 100%;
    margin: 10px 16px 10px 0px;
    /* width: 92%; */
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    span {
      width: 100%;
      /* width: 92%; */
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
    @media (max-width: 600px) {
      max-width: 100%;
      margin: 0;
    }
  }

  .addSkill {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // margin-bottom: 10px;

    div {
      background: #f7f6fe;
      border-radius: 8px;
      width: 650px;
      margin-right: 16px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #413c3b;
      opacity: 0.7;
      padding: 17px;

      @media (max-width: 768px) {
        width: 100%;
        height: 48px;
        padding: 13px;
        margin-right: 10px;
      }
    }
  }

  .skillOptions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // padding-top: 30px;

    .mobileEyeIcon {
      margin-right: 12px;
      cursor: pointer;
      display: none;
      @media (max-width: 600px) {
        display: block;
      }
    }

    @media (max-width: 600px) {
      margin-bottom: 1rem;
    }

    .desktopExperience {
      display: block;
      @media (max-width: 600px) {
        display: none;
      }
    }

    .mobileExperience {
      display: none;
      @media (max-width: 600px) {
        display: block;
      }
    }

    .desktopEyeIcon {
      margin-right: 12px;
      cursor: pointer;
      display: block;

      @media (max-width: 600px) {
        display: none;
      }
    }

    .trashIcon {
      cursor: pointer;
      display: block;

      @media (max-width: 600px) {
        display: none;
      }
    }
  }

  .saveSkill {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: -0.005em;
    text-transform: capitalize;
    color: #ffffff;
    width: 180px;
    height: 58px;
    background: #8f82f5;
    border-radius: 8px;
    border: none;
    margin-top: 20px;
    cursor: pointer;

    @media (max-width: 500px) {
      width: 100%;
    }
  }
}
.suggestedSkills {
  .suggestionPara {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #8d8787;
    margin: 20px 0px 10px 0px;
  }
  .suggestions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .suggestedKeyword {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 125%;
      color: #161616;
      background: #f7f6fe;
      border: 1px solid #8f82f5;
      border-radius: 8px;
      padding: 0.3rem;
      width: max-content;
      cursor: pointer;
      margin: 10px 30px 10px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        margin: 0;
        margin-right: 1rem;
      }
      @media (max-width: 500px) {
        width: 100%;
        margin-right: 0px;
      }
    }
  }
}
.addSkillButton {
  background: #8f82f5;
  border-radius: 8px;
  padding: 9px 17px;
  color: white;
  margin-left: 10px;
  border: none;
  font-size: 20px;
  cursor: pointer;

  @media (max-width: 768px) {
    padding: 8px 16px;
  }
}

.noSuggestionFoundMessage {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 125%;
  text-align: center;
  color: #161616;
  background: #f7f6fe;
  border: 1px solid #8f82f5;
  border-radius: 8px;
  padding: 0.3rem;
  width: 100%;
  margin: 10px 0px 10px 0px !important;
  p {
    margin: 0;
  }
}

.suggestion-popup {
  width: 790px;
  max-width: 100%;
  // height: 560px;
  background: white;
  color: $white;
  border-radius: 25px;
  padding-left: 54px;
  padding-top: 44px;

  @include breakpoint(screen767) {
    padding: 10px 20px 20px 20px;
  }
  .close-button {
    display: none;
  }
}
