@import "../../assets/sass/importFiles";
.phillip-component {
}
.phillip-popup {
  // height: 560px;
  background: white;
  color: $white;
  border-radius: 8px;
  padding-left: 20px;
  padding-top: 20px;

  @include breakpoint(screen767) {
    padding: 10px 20px 20px 20px;
  }
  .close-button {
    display: none;
  }
  .onboard-logo {
    width: auto;
    height: 35px;
  }
  .phillip-footer {
    text-align: right;
    margin: -35px 20px 10px 0px;
  }
  .skip-btn {
    float: right;
    background: none;
    padding: 0;
    border: none;
    position: absolute;
    right: 20px;
    top: 18px;
    cursor: pointer;
    font-size: 12px;
    color: #413c3b;
    opacity: 0.6;
  }
  .description-text {
    font-size: 12px;
    color: #000;
    line-height: 20px;
    &.bold-primary {
      b {
        color: $lightPurple;
      }
    }
  }
  .js_upload_btn {
    background: $green;
    padding: 6px 0px 6px 7px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 3px;
    color: $white;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    font-size: 13px;
    min-width: 155px;
    position: relative;
    overflow: hidden;
    input[type="file"] {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
    }
  }
  .js_input {
    background: rgba(243, 241, 253, 0.7);
    border: 0;
    width: 70%;
    border-radius: 8px;
    font-size: 13px;
    padding: 10px 10px;
    margin: 12px 0px 0px 0px;
  }
  .js_textarea_wrap {
    margin-top: 10px;
    width: 100%;

    .js_textarea {
      background: rgba(243, 241, 253, 0.7);
      border-radius: 8px;
      font-size: 13px;
      padding: 10px 10px;
      margin: 12px 0px 0px 0px;
      resize: none;
      display: block;
    }
    @include breakpoint(screen767) {
      margin-top: px;
      .js_textarea {
        margin: 5px 0px 5px 0px;
      }
    }
  }
  .datepicker-input {
    display: inline-block;
  }
  .custom-date-picker {
    color: $white;
    padding: 10px 20px;
    background-color: #8f82f5;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    font: normal 13px/16px $RubikRegular;
    margin-top: 10px;
    @media (max-width: 767.98px) {
     width: unset;
    }
  }
  .react-datepicker-wrapper:before{
    content: none;
  }
  .js_item {
    padding: 3px 0;
    display: flex;
    align-items: center;
    @include breakpoint(screen767) {
      margin-bottom: 0px;
    }
    &:before {
      content: "";
      background-image: url("../../assets/images/Icons/close-red.png");
      width: 15px;
      height: 17px;
      background-size: contain;
      border-radius: 3px;
      display: inline-block;
      margin-right: 10px;
      vertical-align: baseline;
    }
    &.active {
      &::before {
        background-image: url("../../assets/images/Icons/green-tick.png");
      }
    }
  }

  .date-title {
    margin-bottom: 10px;
    @include breakpoint(screen767) {
      margin-bottom: 3px;
    }
  }
  
  .js_sel_item {
    cursor: pointer;
    position: relative;
    width: 100%;
    padding: 3px 0 0px 20px;
    margin: 5px 0px 5px 0px;
    display: flex;
    align-items: center;
    &:first-of-type {
      margin-top: 10px !important;
    }
    span{
      color: #000;
    }

    @include breakpoint(screen767) {
      margin: 0px 0px 0px 0px;
    }

    &:before {
      content: "";
      background: $white;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      left: 0;
      position: absolute;
      top: 5px;
    }
    &:after {
      background: transparent;
      border: 1px solid $darkBlue;
      border-radius: 50%;
      position: absolute;
      left: 2px;
      top: 7px;
      width: 12px;
      height: 12px;
      content: "";
    }
    &.active {
      &:after {
        background: $darkBlue;
      }
    }
    .react-datepicker-wrapper {
      display: inline-block;
      background: 0;
      color: #fff;
      text-align: center;
      cursor: pointer;
      padding: 0 7px;

      &::before {
        display: none;
      }
    }
  }

  .js_item_loading {
    padding: 3px 0;
    &:before {
      content: "";
      background: url("../../assets/images/loader.gif") no-repeat 98%;
      width: 13px;
      height: 13px;
      border-radius: 3px;
      display: inline-block;
      margin-right: 10px;
      vertical-align: baseline;
      background-size: contain;
    }
    &.active {
      &::before {
        background: $green;
      }
    }
  }

  .js_social_wrap {
    @extend %clearfix;
    padding: 10px 0;
    .linkedin-img {
      float: left;
      padding-right: 10px;
      width: 160px;
      max-width: 50%;
      @include breakpoint(screen640) {
        max-width: 100%;
        width: auto;
        float: none;
      }
    }
    img.facebook-img {
      @extend .linkedin-img;
    }
    .social-btn {
      @extend .linkedin-img;
      .js_social_icon,
      .linkedin-img {
        max-width: 100%;
      }
    }
    p {
      text-align: center;
      margin: 0;
      @include breakpoint(screen640) {
        text-align: left;
        margin-top: 10px;
      }
    }
  }
  .carousel {
    z-index: 1;
    padding-bottom: 40px;
    img {
      pointer-events: auto;
      cursor: pointer;
    }
    .slide {
      background: transparent;
      text-align: inherit;
      padding-left: 2px;
    }
    .control-dots {
      z-index: 1;
      @include breakpoint(screen640) {
        display: block;
        text-align: left;
        padding: 5px 0 0;
        left: 12px;
        width: unset;
      }

      .dot {
        width: 12px;
        height: 12px;
        box-shadow: none;
        vertical-align: middle;
        background: #cdc3e9;
        margin: 0 5px;
        &.selected {
          background: #9783cf;
        }
      }
    }
  }
  .js_slide {
    // min-height: 380px;
    position: relative;
    color: $white;
  }
  .slider-wrapper {
    overflow: visible;
    z-index: 1;
  }
  .js_date_btn {
    background: $purple;
    padding: 4px 7px;
    border: none;
    border-radius: 3px;
    color: $white;
    display: inline-block;
    vertical-align: top;
    font-size: 12px;
    position: relative;
    top: -3px;
  }
  .js_sel_last {
    display: inline-block;
    vertical-align: top;
    margin: 0 10px 10px 0;
  }

  .phillip-img {
    width: 200px;
    display: none;
    @include breakpoint(screen767) {
      display: unset;
      width: 45%;
      margin: 18px 27.5% 0px 27.5%;
    }
  }

  .slides-wrapper {
  }
  .js_slide {
    padding-left: 230px;
    height: 100%;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0px 0px 20px 0px;

    &::before {
      background: url("../../assets/images/onboarding/01.svg") no-repeat 98%
        center;
      background-size: contain;
      content: "";
      // min-height: 100%;
      width: 180px;
      height: 280px;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 40px;
    }

    &.slide-2 {
      &::before {
        background: url("../../assets/images/onboarding/02.svg") no-repeat 60%
          center;
        background-size: contain;
      }
    }

    &.slide-3 {
      &::before {
        background: url("../../assets/images/onboarding/03.svg") no-repeat 60%
          center;
        background-size: contain;
        right: 15px;
      }
    }

    &.slide-4 {
      &::before {
        background: url("../../assets/images/onboarding/04.svg") no-repeat 60%
          center;
        background-size: contain;
        right: 20px;
      }
    }

    @include breakpoint(screen767) {
      padding-left: 0;
      padding-right: 0;
      margin-top: 0px;
      min-height: unset;
      margin-top: 15px;
      justify-content: start;
      &::before {
        display: none;
        width: 200px;
        height: 265px;
        top: -294px;
        left: 70px;
      }
    }
  }

  .continue-button {
    z-index: 2;
    background: none;
    outline: none;
    color: #8E81F5;
    font-weight: 600;
    font-size: 14px;
    border: none
  }

  .continue-button-resume {
    z-index: 2;
    background: none;
    outline: none;
    color: #fff;
    background-color: #8E81F5;
    font-size: 14px;
    border: none
  }

  .finish-button {
    @extend .continue-button;
    float: right;
    display: block;
  }

  .loadingMaskWrapper {
    background: rgba($darkBlue, 0.7);
    .loadingImg {
      width: auto;
    }
  }
  .linkedin-login {
    float: left;
    a {
      height: 35px $imp;
      span {
        height: 35px $imp;
      }
    }
  }
  .loginBtn {
    box-sizing: border-box;
    position: relative;
    /* width: 13em;  - apply for fixed size */
    padding: 0 15px 0 46px;
    border: none;
    text-align: left;
    line-height: 34px;
    white-space: nowrap;
    border-radius: 0.2em;
    font-size: 11px;
    color: $white;
    float: left;
    font-weight: 500;
    min-width: 170px;
  }
  .loginBtn:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 34px;
    height: 100%;
  }
  .loginBtn:focus {
    outline: none;
  }
  .loginBtn:active {
    box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
  }

  /* Facebook */
  .social-btn {
    background: none;
    border: 0;
    padding: 0;
    font: inherit;
    cursor: pointer;
  }

  .loginBtn--facebook {
    background-color: #4c69ba;
    background-image: linear-gradient(#4c69ba, #4c69ba);
    text-shadow: 0 -1px 0 #354c8c;
  }
  .loginBtn--facebook:before {
    border-right: #364e92 1px solid;
    background: url("../../assets/images/onboarding/icon_facebook.png") 6px 6px
      no-repeat;
  }
  .loginBtn--facebook:hover,
  .loginBtn--facebook:focus {
    background-color: #4c69ba;
    background-image: linear-gradient(#4c69ba, #4c69ba);
  }

  /* LinkedIn */
  .loginBtn--linkedin {
    background-color: #0077b5;
    background-image: linear-gradient(#0077b5, #0077b5);
    /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
    text-shadow: 0 -1px 0 #354c8c;
  }
  .loginBtn--linkedin:before {
    border-right: #364e92 1px solid;
    background: url("../../assets/images/onboarding/icon_linkedin.png") 6px 6px
      no-repeat;
  }
  .loginBtn:active {
    box-shadow: unset;
  }
  .loginBtn--linkedin:hover,
  .loginBtn--linkedin:focus {
    background-color: #0077b5;
    background-image: linear-gradient(#0077b5, #0077b5);
  }
  @include breakpoint(screen320) {
    .onboard-logo {
      height: 24px;
    }
    .js_slide {
      h2 {
        font-size: 16px !important;
      }
    }
  }
  @media (max-width: 380px) {
    .js_sel_item {
      width: 100%;
      display: block !important;
      .react-datepicker-wrapper {
        display: block !important;
      }
    }
  }
  @include breakpoint(screen480) {
  }
}

.found-skill {
  color: #8f82f5;
}

.draft-opp {
  color: #8f82f5 !important;
  cursor: pointer;
}

.small-modal-phillip-searcher {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background: #8f82f5;
  border-radius: 6px;
  position: fixed;
  top: 60px;
  right: 20px;
  z-index: 99;
  padding: 10px;
  @media (max-width: 767.98px) {
    right: 6px;
  }
}

.rotate-small-modal {
  animation: rotation 1.2s infinite linear;
}

@keyframes rotation {
  from {
      transform: scale(1);
  }
  to {
      transform: scale(1.1);
  }
}