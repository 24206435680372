@import "../../../../../assets/sass/importFiles";
.opportunity-list-component {
  .listLabel {
    margin: 0;
    font-family: $RubikRegular;
    font-size: 14px;
    display: inline;
  }
  .SelectedItem {
    font-weight: 500;
  }
  .dateItem {
    padding-left: 12px;
    font-size: 10px;
    font-family: $RubikRegular;
  }
}
