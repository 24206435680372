@import '../../assets/sass/importFiles';

.carouselItem {
  display: flex;
  justify-content: center;
  align-items: center;
}
.carouselWrapper {
  div{
    position: static !important;
  }

 
  button {
    // background: #8f82f5 ;
    @media (max-width:560px) {
      min-width: 25px !important;
      min-height: 25px !important;
      &::before{
        font-size: 15px ;
      }      
    }

  }
}
