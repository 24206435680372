@import "../../assets/sass/importFiles";
.network-page {
  .bullet-list-wrapper {
    .list-item {
      display: flex !important;
    }
  }
  .network-save-form {
    margin: 10px 10px 0px 10px;
    width: 100%;
  }
  @media (max-width: 330px) {
    .heading {
      font-size: 12px;
    }
    .invAccept {
      font-size: 13px;
      padding: 5px 1px;
      line-height: 14px;
    }
  }
}
