@import "../../../assets/sass/importFiles";
$lightPurpleBackground: rgba(241, 237, 255, 0.5);

.searcher-draft-opportunity-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: calc(100vh - 48px);
  background-color: rgba(241, 237, 255, 0.5);
  .opportunity-column1 {
    background-color: #fff;
    border-radius: 8px;
    height: 100%;
    overflow: auto;
  }
  .page-content {
    height: 100%;
  }
  .views-cnt {
    flex: 1 1;
    display: flex;
    align-content: flex-start;
    flex-flow: row wrap;
    overflow-x: auto;
    padding: 0;
    background: transparent;
  }

  @media (max-width: 767.98px) {
    padding: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .shortlist-resume-component {
    .closeBtn {
      margin-top: -13px;
    }
  }
  .alignStart {
    align-items: flex-start;
  }
  .round-search {
    width: 100%;
  }
  .column-head {
    .search-list-wrapper {
      flex: 1;
      position: relative;
    }
    .list-wrapper {
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 1;
      left: 0;
      background: $white;
      box-shadow: lighten($color: $black, $amount: 50) 0px 2px 6px 0;
    }
  }
  .shortlists-header {
    font-weight: 500;
    padding: 10px;
    display: block;
    font-size: 16px;
    line-height: 1;
    color: $purple2;
  }
}

.new-design-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: calc(100vh - 48px);
  background-color: $lightPurpleBackground;
  // new design style
  .tabs-new {
    background-color: #fff;
    flex: 1;
    border-radius: 8px;
  }
  .create-btn {
    background: #8e81f5;
    border: 1px solid #8e81f5;
    outline: none;
    color: #fff;
    padding: 4px 10px;
    border-radius: 8px;
  }

  .active-btn {
    color: #6b61b8;
    background-color: #dcd8fc;
    font-weight: 600;
  }
}

.opportunity-create-form-new {
  height: 100%;
  @media (max-width: 767.98px) {
    height: calc(100% - 50px);
  }
  .menu-dots-mobile {
    display: none;

    @media (max-width: 767.98px) {
      display: block;
    }
  }
}
