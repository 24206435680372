.new-user-detail-component {
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #000;
  .details {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  @media (max-width: 767.98px) {
    padding: 10px 10px;
    margin-left: 0px;
  }
  .inner-user {
    background: #fff;
    border-radius: 12px;
    padding: 12px;
    overflow: auto;
    @media (max-width: 767.98px) {
      padding: 10px;
    }
    .image-user {
      text-align: center;
      margin-bottom: 10px;
      img {
        border-radius: 50%;
        width: 150px;
        height: 150px;
        @media (max-width: 767.98px) {
          width: 150px;
          height: 150px;
        }
      }
    }
    .user-title {
      text-align: center;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 27px;
      color: #413c3b;
      margin-top: 6px;
      @media (max-width: 767.98px) {
        font-size: 14px;
        line-height: unset;
      }
    }
    .user-detail {
      margin: 0;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 27px;
      color: #413c3b;
      @media (max-width: 767.98px) {
        font-size: 14px;
        line-height: unset;
      }
    }
  }
}
