@import "../../assets/sass/importFiles";

.newResume {
  padding: 30px;
  height: 100%;
  overflow: scroll;

  @media (max-width: 450px) {
    padding: 0px;
  }
  .skillsInfo {
    background: #f7f6fe;
    border-radius: 100%;
    width: max-content;
    margin: auto;
    width: 60px;
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg:not(:root) {
      width: 35px !important;
      height: 35px !important;
    }
  }
  .resumeDetails {
    padding: 30px;
    background-color: white;
    border-radius: 12px;
    display: subgrid;
    @media (max-width: 1024px) {
      border-radius: 0;
    }
    .resumeHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      @media (max-width: 1024px) {
        display: block;
      }
      h1 {
        margin: 0;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 125%;
        color: #413c3b;
        @media (max-width: 1068px) {
          margin-bottom: 24px;
        }
      }
      .desktopFilters {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        @media (max-width: 768px) {
          display: none;
        }

        p {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          color: #413c3b;
          margin: 0;
          margin-right: 13px;
        }
        .availableFilter {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          margin-right: 70px;
        }
        .noticeFilter {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          margin-right: 70px;
        }
        .busyFilter {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;

          .inputDate {
            border: none;
            background-color: #f7f6fe;
            padding: 12px 16px;
            border-radius: 12px;
          }
        }
      }

      .mobileFilters {
        display: none;
        @media (max-width: 1024px) {
          display: block;
        }
        .filterDrop {
          width: 100%;
          background-color: rgba(243, 241, 253, 0.7);
        }
        .mobileInputDate {
          border: none;
          float: right;
          margin: 10px 0px;
        }
        .visible {
          display: block;
        }
        .hidden {
          display: none;
        }
      }
    }
    .carouselBorder {
      border-top: 0.1px solid #8d83aa;
      width: 100%;
      opacity: 0.3;
      position: absolute;
      bottom: 17px;
      z-index: 0;
      @media (max-width: 1024px) {
        display: none;
      }
    }
    .carouselParent {
      position: relative;
    }
    .mCarousel {
      display: none;
      padding-top: 30px;
      @media (max-width: 1024px) {
        display: block;
      }
      .skillsInfo {
        background: #f7f6fe;
        border-radius: 100%;
        width: max-content;
        padding: 15px;
        cursor: pointer;
      }
      .focused {
        background-color: #8f82f5;
        font-weight: bold;
      }
      .carouselItemText {
        font-family: "Poppins";
        font-style: normal;
        //font-weight: 600;
        font-size: 14px;
        line-height: 30px;
        text-align: center;
        color: #413c3b;
        margin: 0;
        // margin-top: 14px;
        width: 100%;
        cursor: pointer;
      }
      button {
        background-color: #7c6df0;
      }
    }
    .carousel {
      display: flex;
      // min-height: 50px;
      // min-width: 665;
      justify-content: space-between;
      align-items: flex-start;
      // padding: 0px 50px;
      // margin-top: 30px;
      padding-top: 30px;
      overflow-x: scroll;
      // border-bottom: 1px solid #8d83aa;
      @media (max-width: 1024px) {
        display: none;
      }
      .carouselItem {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .focused {
          background-color: #8f82f5;
          font-weight: bold;
        }
        .carouselItemText {
          font-family: "Poppins";
          font-style: normal;

          font-size: 17px;
          line-height: 30px;
          text-align: center;
          color: #413c3b;
          margin: 0;
          margin-top: 14px;
          width: max-content;
        }
      }
      .marginRight {
        // margin-right: 95px;
      }
      .focusedItem {
        padding-bottom: 20px;
        border-bottom: 3px solid #8f82f5;
        z-index: 1;
        font-weight: 600;
      }
    }
  }
  input[type="date"]::-webkit-clear-button{
    display: none;
    -webkit-appearance: none;
    margin: 0;
  }
}


.inputDateResumeEditMobile {
  border: none;
  background-color: #f7f6fe;
  padding: 12px 16px;
  border-radius: 12px;
}