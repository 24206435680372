.education {
  margin-top: 30px;
  // @media (max-width: 768px) {
  //   padding: 20px 0px;
  // }
  .educationHead {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 125%;
    color: #413c3b;
    // margin: 30px 0px 20px 0px;
  }
  .addLocation {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // margin-bottom: 10px;

    div {
      background: #f7f6fe;
      border-radius: 8px;
      width: 650px;
      margin-right: 16px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #413c3b;
      opacity: 0.7;
      padding: 17px;

      @media (max-width: 768px) {
        width: 100%;
        height: 48px;
        padding: 13px;
        margin-right: 10px;
      }
    }
  }

  .addEducationButton {
    background: #8f82f5;
    border-radius: 8px;
    padding: 9px 17px;
    color: white;
    border: none;
    font-size: 20px;
    margin-left: 10px;
    cursor: pointer;

    @media (max-width: 768px) {
      padding: 8px 16px;
    }
  }

  .educationSelectMain {
    align-items: center;
    margin-bottom: 16px;
    @media (max-width: 672px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}

.educationDetail {
  width: 100%;
  p {
    margin: 0;
  }
  .detail {
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    border: none;
    outline: none;
    background: #f7f6fe;
    border-radius: 8px;
    padding: 10px 15px;
    height: 50px;
    color: #413c3b;
    font-size: 12px;
    font-weight: 400;
    &::placeholder {
      color: #413c3b;
      opacity: 0.7;
    }
    img {
      margin-right: 10px;
    }
    p {
      width: 92%;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
  }
}
.dropDownExperienceContent {
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: #8d8787;
    &:hover {
      p {
        color: #8f82f5;
      }
      img,
      svg {
        filter: invert(54%) sepia(9%) saturate(3205%) hue-rotate(206deg)
          brightness(103%) contrast(92%);
      }
    }
    img {
      margin-left: 20px;
      filter: opacity(0.6);
    }
    svg {
      width: 20px;
      filter: opacity(0.6);
    }
  }
  p {
    margin: 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-right: 20px;
  }
}
