@import "../../assets/sass/importFiles";

.new-search-resume-main {
  padding: 15px 0px 0px 0px;
  width: 100%;
  height: 100%;
  overflow: auto;
  justify-content: space-between;
  @media (max-width: 767.98px) {
    padding: 0px 0px;
  }
  .search-filter-right {
    padding-left: 10px;
    @media (max-width: 767.98px) {
      padding-left: 0px;
    }
  }
  .mobile-filter {
    height: 48px;
    // padding-left: 4px;
    // padding-right: 4px;
    // position: sticky;
    // position: -webkit-sticky;
    // background: #fff;
    // z-index: 55;
    // top: 0px;
  }
  .title-main-resumes {
    align-items: center;
  }
  .ant-collapse-borderless {
    background: #fff;
  }
  .title-filter {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 36px;
    color: #3e345d;
    margin: 0;
    @media (max-width: 767.98px) {
      font-size: 18px;
    }
  }
  .container-collapse {
    margin-top: 12px;
    margin-bottom: 12px;
    .inValid {
      border: 1px solid #f54949 !important;
    }
    textarea {
      resize: none;
    }
    @media (max-width: 767.98px) {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
  .new-collapse-main {
    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;
    p {
      margin: 0;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 27px;
      color: #413c3b;
      @media (max-width: 767.98px) {
        font-size: 14px;
        line-height: unset;
      }
    }
  }
  .row-main {
    height: 100%;
    @media (max-width: 767.98px) {
      height: unset;
    }
  }
  .column-secend {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    @media (max-width: 767.98px) {
      height: calc(100% - 48px);
      padding-left: 0px;
    }
  }
  .list-item-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: #22294c;
    padding-right: 10px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    @media (max-width: 767.98px) {
      font-size: 14px;
      line-height: unset;
    }
  }
  .button-top {
    background: #8f82f5;
    border: none;
    border-radius: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    outline: none;
    padding: 8px;
    @media (max-width: 767.98px) {
      font-size: 12px;
      padding: 6px;
    }
  }

  .dropdown-menus {
    text-align: end;
    @media (max-width: 767.98px) {
      text-align: end;
    }
  }

  .location- {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #413c3b;
    margin: 0;
    opacity: 0.7;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    @media (max-width: 767.98px) {
      font-size: 14px;
      line-height: unset;
    }
  }
  .row-role {
    display: flex;
    .role-btn {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 10px;
    }
    .btn-more {
      background: #f7f6fe;
      border: none;
      border-radius: 8px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      padding: 8px;
      font-size: 14px;
      line-height: 125%;
      text-align: center;
      color: #8f82f5;
      white-space: pre;
      @media (max-width: 767.98px) {
        font-size: 12px;
        line-height: unset;
      }
    }
  }
  .btn-action {
    background: #f7f6fe;
    border: none;
    border-radius: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    padding: 8px;
    font-size: 14px;
    line-height: 125%;
    text-align: center;
    color: #8f82f5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (max-width: 767.98px) {
      font-size: 12px;
      line-height: unset;
    }
  }
  .ant-select-multiple {
    .ant-select-selection-item {
      background: #f7f6fe;
    }
  }
  .ant-select-multiple .ant-select-selection-item-remove > .anticon {
    vertical-align: middle;
  }

  .width-100 {
    width: 100%;
  }
  .invite-button-for-mobile {
    display: none;
    @media (max-width: 767.98px) {
      display: block;
    }
  }
  .invite-button-for-desktop {
    display: block;
    @media (max-width: 767.98px) {
      display: none;
    }
  }
}
.footer-button- {
  position: sticky;
  position: -webkit-sticky;
  background: #fff;
  z-index: 55;
  bottom: 0px;
  border-top: 1px solid #f4f2fe;
  padding: 12px;
  @media (max-width: 767.98px) {
    padding: 12px;
    .ant-space {
      width: 100%;
    }
    .ant-space-item {
      width: 100%;
    }
  }
}
.button-footer-opportunity {
  background: #8f82f5;
  border: 1px solid #8f82f5;
  border-radius: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  color: #ffffff;
  outline: none;
  padding: 8px 12px;
  @media (max-width: 767.98px) {
    font-size: 12px;
    line-height: unset;
  }
}

.button-footer-delete {
  background: #ffffff;
  border-radius: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  color: #ff6363;
  outline: none;
  border: 1px solid #ff6363;
  padding: 8px 12px;
  @media (max-width: 767.98px) {
    font-size: 12px;
    line-height: unset;
  }
}

.button-last-footer {
  background: #ffffff;
  border-radius: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  color: #8f82f5;
  outline: none;
  border: 1px solid #8f82f5;
  padding: 8px 12px;
  @media (max-width: 767.98px) {
    font-size: 12px;
    line-height: unset;
  }
}

.new-detail-modal {
  .ant-drawer-content-wrapper {
    right: 0px;
    top: 0px;
    bottom: 20px;
    height: calc(100% - 0px);
    .ant-drawer-content {
      border-radius: 8px;
    }
  }
  .resume-detail-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #000;
  }
  .detail-content-main {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    gap: 12px;
    .flex- {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .detail-content-inner {
      border: 1px solid #e1e1e1;
      border-radius: 8px;
      padding: 12px;
      width: 100%;
      .role-title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 27px;
        color: #000000a6;
        margin: 0px 0px 6px 0px;
        @media (max-width: 767.98px) {
          font-size: 12px;
        }
      }
      .skill-main {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .skill-content {
          font-family: "Poppins";
          font-weight: 600;
          font-size: 16px;
          color: #000;
          opacity: 0.7;
          margin: 0;
          flex: 1;
          padding-right: 5px;
          @media (max-width: 767.98px) {
            font-size: 12px;
          }
        }
        .btn-role {
          background: #fbfbfb;
          border-radius: 8px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          border: 1px solid #eeeeee;
          padding: 6px 10px;
          color: #8e81f5;
          text-align: center;
          flex: 0.5 1;
          margin: 0;
          height: -webkit-fit-content;
          height: -moz-fit-content;
          height: fit-content;
          @media (max-width: 767.98px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}

// shortList page css
.shortlist-lsit-section {
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
}

.shortlist-modal {
  .create-button {
    border-radius: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    outline: none;
    border: none;
    padding: 4px 10px;
  }
  .background {
    background: #8f82f5;
    color: #fff;
    border: 1px solid #8f82f5;
  }
  .border {
    border: 1px solid #8f82f5;
  }
}
.dropdown-shortlists {
  .ant-dropdown-menu-title-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: flex;
    align-items: center;
    .span- {
      margin-right: 4px;
    }
  }
  .ant-dropdown-menu-item {
    opacity: 0.8;
    color: #413c3b;
    &:hover {
      font-weight: 600;
    }
  }
}

@media (max-width: 767.98px) {
  .shortlist-lsit-section {
    // height: calc(100% - 50px);
    padding-left: 0px;
  }
  .new-detail-modal {
    .ant-drawer-content-wrapper {
      width: 90% !important;
    }
    .skill-content {
      font-size: 12px !important;
    }
    p,
    span {
      font-size: 12px !important;
    }
  }
}

// short list page style
.short-list-new {
  width: 100%;
  .short-list-item {
    border: 1px solid #9783cf40;
    padding: 8px;
    color: #9783cf;
    border-radius: 6px;
    width: 100%;
    .listLabel {
      width: 100%;
      padding-right: 5px;
    }

    .listLabel:hover {
      font-weight: 600;
    }
  }
  .background-shortlist {
    border: 1px solid #8e81f5;
    background: #f4f2fe;
    color: #8e81f5;
    .listLabel {
      font-weight: 600;
    }
  }
  .ant-space-item {
    width: 100%;
  }
  .anticon-close-circle:hover {
    color: rgba(255, 0, 0, 0.542);
  }
}

.bg-color-for-opportunity {
  background-color: #f7f6fe !important;
  border-radius: 5px;
  .ant-select-selector {
    background-color: #f7f6fe !important;
  }
}

// .dropdown-csutom {
//   .cross {
//     display: none;
//   }
// }
// .dropdown-csutom:hover {
//   .cross {
//     display: block !important;
//   }
// }
// .ant-select-selection-search {
//   .cross {
//     display: none !important;
//   }
// }
