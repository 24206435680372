@import "../../assets/sass/importFiles";

.bullet-list-wrapper {
  .bullet-list-ul {
    list-style: none;
    margin: 0;
    // padding: 10px 0;
  }
  .list-item {
    display: flex;
    align-items: center;
    color: $purple4;
    font-size: 12px;
    padding: 7px 36px 7px 20px;
    cursor: pointer;
    position: relative;
    &:before {
      @extend %fa;
      content: $fa-circle;
      font-size: 8px;
      vertical-align: top;
      margin: -1px 5px 0 0;
      color: $purple4;
    }
    &:nth-child(even) {
      background: $offwhite4;
    }
    &:hover {
      //font-weight: 500;
    }
    &.selected-item {
      background: $offwhite2;
      font-weight: 500;
    }

    &.activeItem {
      color: $yellow;
      &:before {
        color: $yellow;
      }
    }
  }
}
