@import "../../../assets/sass/importFiles";
.nav-button {
  background: $darkBlue;
  width: 100%;
  padding: 8px 10px 8px 8px;
  color: $purple;
  font-size: 15px;
  line-height: 1.2;
  border: none;
  text-align: left;
  align-items: center;
  display: flex;
  flex-flow: row;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  min-width: 120px;

  &:focus {
    outline: none !important;
  }

  &:hover,
  &.activeBtn {
    color: $green;
    i {
      background: $green;
    }
  }
}
