@import "../../../../assets/sass/importFiles";
.google-login-wrap {
  position: relative;
  z-index: 999;
  left: unset;
  display: flex;
  flex-flow: column;
  padding: 10px 5px 0px;
  border-radius: 10px;
  background: transparent;
  .google-login-button-wrapper {
    position: relative;
    @include transition(background 0.9s, color 0.8s);
    padding: 5px;
    background: #f9f7ff;
    border-radius: 15px;
    z-index: 500;
    align-self: center;
    width: 100%;
    align-items: center;
    text-align: center;
    box-shadow: 1px 1px #888888;
    margin: 10px;
    button {
      box-shadow: unset !important;
    }
    * {
      background: #f9f7ff !important;
    }
  }
  @include breakpoint(screen767) {
    margin: 0 auto;
  }
  .close-btn {
    display: none;
  }
  &.signup {
    background: #f9f7ff !important;
    .close-btn {
      display: inline-block;
    }
  }

  .Select-menu-outer {
    position: absolute;
    z-index: 9999999;
  }

  @media screen and (max-height: 700px) {
    .RegisterForm,
    .TermForm {
      height: 200px;
      overflow: unset;
      &.small {
        height: unset !important;
        .LoginForm, .RegisterForm{
          height: unset !important;
        }
      }
    }
  }

}
