@import "../../../../../assets/sass/importFiles";
.Collaboration-form-editor-searcher {
  width: 100%;
  position: relative;
  padding: 10px;
  .company-form {
    width: 100%;
  }

  .rc-slider {
    margin-right: 30px;
  }
  .select-hourlyRate {
    margin-left: 0px;
  }
}
