@import "../../assets/sass/importFiles";
.panel {
  flex: 1;
  display: flex;
  flex-flow: column;
  .panel-heading {
    display: flex;
    .button-wrapper {
      align-self: center;
    }
  }
  .panel-body {
    background: $white;
    // margin-top: 10px;
    border-radius: 3px;
    padding: 10px 10px 0;
    flex: 1;
    @media (max-width:330px) {
      padding-right: 0px;
    }
  }
  &.theme-light {
    .heading {
      border-bottom: 1px solid $ViewsCnt;
      background: $white;
    }
    .panel-body {
      margin-top: 0;
    }
  }
}
