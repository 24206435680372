@import "../../../../assets/sass/importFiles";
@import "../../variables.scss";
.collaborations-view {
  flex: 1;
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  > * {
    flex: 1 1 300px;
  }
  .SendButton {
    max-width: 97%;
  }
  @include breakpoint(screen767) {
    flex-flow: column;
    .page-column {
      min-height: 350px;
      height: auto;
    }
    .page-column.page-column-collapse {
      height: auto;
      min-height: 0;
    }
  }
  .snapshot-collaboration-detail,
  .expense-detail,
  .time-expense-detail {
    padding-top: 10px;
  }
  .col-1 {
    @include breakpoint(screen767) {
      min-height: 250px;
      height: auto;
      &.page-column.page-column-collapse {
        min-height: auto;
      }
    }

    .select-input {
      margin: 5px 0px 0px 0px;
      width: 100%;
      &.hidden {
        display: none;
      }
      .Select-control {
        background: $purple4 !important;
        color: $white !important;
        height: 45px;
        font-size: 15px;
        text-align: center;
        box-shadow: 1px 2px #8888888a;
        border-radius: 5px;
        // border: 2px solid #f6aa3a !important;
      }
      .Select-menu-outer {
        background: transparent;
      }
      .Select-value-label {
        line-height: 45px;
        color: $white !important;
      }
      .Select-arrow-zone:hover > .Select-arrow {
        border-top-color: $white !important;
      }
      .Select-arrow {
        border-color: $white transparent transparent;
      }
      .Select-option {
        padding: 10px 6px;
        font-size: 13px;
        color: #ffffff;
        text-align: center;
        background: #9783cf;
        margin-bottom: 2px;
        border-radius: 5px;
        border: 2px solid transparent !important;
        border-bottom: none;
        margin-top: 2px;
        margin: 2px;
      }
    }
  }
  .col-2 {
    padding-top: 10px;
    .collaboration-heading {
      display: flex;
      width: 100%;
      background: #9783cf;
      height: 45px;
      margin: 5px 0px 0px;
      border-radius: 5px;
      color: $white;
      .roundImg {
        margin-top: 4.5px;
      }
      border-block-end: 1px solid #9783cf;
    }
    .start-end {
      .button-wrapper {
        max-width: 40px;
        .help-icon {
          right: 0;
        }
      }
      .add-btn {
        width: 30px;
        border-radius: 50%;
        background: #04caa7;
        outline: none;
        padding: 0;
        height: 30px;
        margin-bottom: 20px;
        border: none;
        align-self: center;
        margin-top: 5px;
        margin-right: 5px;
        align-self: center;
        margin-left: 5px;
        &.disabled-class {
          pointer-events: none;
          cursor: default;
          opacity: 0.5;
          border: 1px solid #9783cf;
          background: #dfd9f7;
          &:before {
            color: #9783cf;
          }
        }
        &:before {
          @extend %fa;
          font-size: 13px;
          content: $fa-pencil;
          color: $white;
          vertical-align: middle;
          @include rotate(-5deg);
          margin: 0px 0px 2px 4px;
          box-sizing: border-box;
        }
        @include breakpoint(screen767) {
          height: 20px;
          width: 20px;
          &:before {
            margin: -4px 1px 0px 1.5px;
            font-size: 15px;
          }
        }
      }
    }
  }
  .new-share {
    display: flex;
    margin-bottom: 5px;
    color: #8d84b6;
    @extend .md-heading;
    margin-top: 5px;
    input {
      flex: 1 1;
      margin-right: 20px;
      border-radius: 5px;
      border: 1px solid;
      padding: 2px 10px 5px 5px;
      height: 25px;
      margin-top: 3px;
      margin-left: 5px;
      background: #f1eeff;
      border: transparent;
      &:focus {
        outline: none;
      }
    }
    .add-btn {
      position: relative;
      width: 23px;
      border-radius: 50%;
      background: #04caa7;
      outline: none;
      height: 23px;
      border: none;
      align-self: center;
      &:before {
        @extend %fi;
        color: $white;
        line-height: 2;
        content: $fi-plus;
        @include border-radius(50%);
        box-sizing: border-box;
        display: inline-block;
        font-size: 10px;
        margin-top: 0.5px;
        font-weight: lighter;
        box-sizing: border-box;
        display: flex;
        align-self: center;
        justify-content: center;
      }
    }
  }
  .attach-list-item {
    display: flex;
    justify-content: space-between;
    padding: 0px 5px 0px 5px;
    margin-bottom: 0px;
    color: $font-color-light_grey_purple;
    width: 100%;
    button {
      position: relative;
      align-self: flex-end;
      margin-left: 10px;
      margin-right: -6px;
      margin-top: 10px;
      a {
        color: white;
        font-size: 12px;
        text-decoration: none;
      }
    }
    label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
      padding-top: 10px;
      &:first-of-type {
        margin-right: 10px;
      }
    }
  }
  .snapshot-collaboration-detail,
  .time-expense-detail {
    .Select-menu-outer {
      z-index: 2;
    }
    .start-end {
      .react-datepicker-wrapper {
        &:before {
          @extend %fi;
          color: $font-color-light_grey_purple;
          line-height: 1;
          content: $fi-calendar2;
          font-size: 20px;
          background: none;
          line-height: 1;
          @include border-radius(50%);
          box-sizing: border-box;
          display: inline-block;
          margin-top: -11px;
        }
      }
      > div:first-of-type {
        margin-right: 0px !important;
      }
      > span {
        margin: 15px 5px 0px 10px;
        color: $font-color-light_grey_purple;
      }
    }
  }
  @media (max-width: 767px) {
    .col-1 {
      min-height: 300px;
      &.page-column-collapse {
        min-height: auto;
        margin-top: 50px;
      }
    }
    .col-2,
    .col-3,
    .col-4 {
      min-height: 650px !important;
      overflow: hidden;
      max-height: 500px !important;
      .column-body {
        overflow: unset;
      }
      &.page-column-collapse {
        min-height: auto !important;
      }
    }
    .col-3,
    .col-4 {
      min-height: 560px !important;
      &.page-column-collapse {
        min-height: auto !important;
      }
    }
  }

  .invRequest {
    display: flex;
    margin-top: 10px;
    padding-right: 0px;
    justify-content: center;
  }
  .review-form {
    color: #8d82ab;
  }
  .textarea-field {
    background: #f5f2ff;
    color: #8d82ab;
  }
}
