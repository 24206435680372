@import "../../assets/sass/importFiles";
@import "../../assets/sass/variables";
@import "../../assets/sass/mixins";
$lightPurpleBackground: rgba(241, 237, 255, 0.5);

.company-page-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: calc(100vh - 48px);
  background-color: $lightPurpleBackground;
  @media (max-width: 767.98px) {
    padding: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .views-cnt {
    padding: 0 !important;
    background-color: transparent !important;
  }
  .alignStart {
    align-items: flex-start;
  }
  @media (max-width: 330px) {
    .heading {
      font-size: 12px;
    }
  }
}
