@import "../../../../assets/sass/importFiles";

.landing-search-component {
  @include border-radius(4px);
  position: relative;
  float: left;
  padding: 0 50px 0px 10px;
  width: 300px;
  min-width: 300px;
  margin: 28px 5px 0px 0;
  height: 33px;
  background: #2b2342;

  @include breakpoint(screen1023) {
    max-width: 40%;
  }
  // @include breakpoint(screen767) {
  //   display: none;
  // }
  @include breakpoint(screen640) {
    max-width: 100%;
    width: auto;
    margin: 20px 20px 0;
    display: block;
    float: none;
  }
  .Select-control {
    background: transparent $imp;
    height: 33px;
  }
  .Select-placeholder {
    color: $white;
    line-height: 33px;
    padding-left: 0;
  }
  .select-input {
    margin-bottom: 0;
  }
  .Select-input {
    padding-left: 0;
  }
  .Select-menu-outer {
    left: -10px;
    right: -50px;
    width: auto;
  }
  .Select-arrow-zone {
    display: none;
    
  }
  .Select-value {
    line-height: 33px $imp;
    padding-left: 0px $imp;
  }
  .Select-value-label {
    color: $white $imp;
  }
  .Select-input > input {
    color: $white;
    padding: 9px 0;
  }
  .SearchInput {
    width: 100%;
    background: none;
    height: 100%;
    line-height: 1;
    color: #fff;
    border: none;
    outline: none;
    font-size: 15px;

    @include input-placeholder {
      color: $white;
    }
  }

  .SearchIcon {
    // @include spriteIcon(search);
    background: url("../../../../assets/images/landing-Icons/search.png")
      no-repeat;
    width: 20px;
    height: 21px;
    position: absolute;
    right: 10px;
    top: 5px;
    &:after {
      @extend %fa;
      // content: $search;
      font-size: 25px;
      color: #fff;
      position: absolute;
      top: 3px;
      right: 5px;
      display: none;
    }
  }
}
