.fnt-s-12 {
  font-size: 12px;
}
.fnt-s-14 {
  font-size: 14px;
}
.fnt-s-16 {
  font-size: 16px;
}
.fnt-s-20 {
  font-size: 20px;
}

.border-r-25 {
  border-radius: 25px;
}
