@import "../../../../assets/sass/importFiles";
.resume-suggestor {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .upload-div {
    margin: 20px;
    font-size: 20px;
  }
  .js_upload_btn {
    position: relative;
  }
  h3 {
    margin: 0px;
  }
  .lookup-head {
    margin-bottom: 20px;
  }
}
