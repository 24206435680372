@import "../../../../assets/sass/importFiles";

.btnContainer {
  justify-content: center;
  display: flex;
  align-items: center;
  .timerBtns {
    width: 100%;
    max-width: 300px;
    border: none;
    display: block;
    background: #04caa7;
    padding: 12px;
    margin: 20px 10px;
    border-radius: 3px;
    font-size: 16px;
    font-family: "rubik", Arial, sans-sarif;
    color: #fff;
    line-height: 1;
    transition: all 0.3s;
  }
  .autoPadding{
    margin: 20px auto 0 !important;
  }

  .btn-red {
    background: #ff0000;
  }
  .btn-gre {
    background: #04caa7;
  }
}
.timerDisplayValues{
  margin: auto;
  width: 100%;
  background: #9783cf;
  text-align: center;
  span{
    font-size: 30px;
  }
}
