@import "../../../../assets/sass/importFiles";
@import "../../variables.scss";
.pools-view {
    flex: 1;
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    >*{
        flex: 1 1 250px;
    }
    .column-head{
      
        min-height:50px
    }
    .attach-list-item{
      display: flex;
      justify-content: space-between;
      padding: 0px 5px 0px 5px;
      margin-bottom: 0px;
      color: $font-color-light_grey_purple;
      width: 100%;
      button{
        position: relative;
        align-self: flex-end;
        margin-left: 10px;
        margin-right: -6px;
        margin-top: 10px;
        a{
          color: white;
          font-size: 12px;
          text-decoration: none;
        }
      }
      label{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
        padding-top: 10px;
      &:first-of-type{
        margin-right: 10px;
      }
      }
    }
    .new-share{
      display: flex;
      margin-bottom: 5px;
      color: #8d84b6;
      @extend .md-heading;
      margin-top: 5px;
      input{
          flex: 1 1;
          margin-right: 20px;
          border-radius: 5px;
          border: 1px solid;
          padding: 2px 10px 5px 5px;
          height: 25px;
          margin-top: 3px;
          margin-left: 5px;
          background: #f1eeff;
          border: transparent;
        &:focus{
            outline: none;
        }
      }
      .add-btn {
        position: relative;
        width: 23px;
        border-radius: 50%;
        background: #04caa7;
        outline: none;
        height: 23px;
        border: none;
        align-self: center;
        &:before {
          @extend %fi;
          color: $white;
          line-height: 2;
          content: $fi-plus;
          @include border-radius(50%);
          box-sizing: border-box;
          display: inline-block;
          font-size: 10px;
          margin-top: 0.5px;
          font-weight: lighter;
          box-sizing: border-box;
          display: flex;
          align-self: center;
          justify-content: center;
        }
      }
    }
    label.pool-label {
        white-space: nowrap;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-top: 8.5px;
        font-size: 15px !important;
        font-weight: 600 !important;
        margin-bottom: 8.5px;
    }
    @include breakpoint(screen767) {
        flex-flow: column;
        .page-column{
          min-height: 350px;
          height: auto;
        }
        .page-column.page-column-collapse {
            height: auto;
            min-height: 0;
        }
      }
    .column-body{
        overflow: auto;
    }
    .col-1 {
        .pools-list-wrapper{
            display: flex;
            height: 100%;
            flex-flow: column;
            .pools-list{
                flex: 1;
                overflow: auto;
            }
            .action-buttons-wrapper{
                justify-content: flex-end;
            }
        }

        @include breakpoint(screen767) {
            min-height: 250px;
            height: auto;
            &.page-column.page-column-collapse {
                min-height: auto;
            }
        }

        .select-input {
            margin: 5px 0px 0px 0px;
            width: 100%;
            .Select-control {
                background: $purple4 !important;
                color: $white !important;
                height: 50px;
                font-size: 15px;
                text-align: center;
                box-shadow: 1px 2px #8888888a;
                border-radius: 5px;
                // border: 2px solid #f6aa3a !important;
            }
            .Select-menu-outer {
                background: transparent;
            }
            .Select-value-label {
                line-height: 50px;
                color: $white !important;
            }
            .Select-arrow-zone:hover > .Select-arrow {
                border-top-color: $white !important;
            }
            .Select-arrow {
                border-color: $white transparent transparent;
            }
            .Select-option {
                padding: 15px 6px;
                font-size: 13px;
                color: $white;
                text-align: center;
                background: #9783CF;
                margin-bottom: 2px;
                border-radius: 5px;
                border:2px solid transparent !important;
                border-bottom: none;
                margin-top: 2px;
            }
        }
    }
    .col-2{
        .collaboration-heading {
            display: flex;
            width: 100%;
            background: #9783CF;
            height: 50px;
            margin: 5px 0px 0px;
            border-radius: 5px;
            color: $white;
        }
        .pool-head{
            padding-top: 10px;
            // margin-top: 10px;
            border-radius: 5px;
            background: $back-color-pale_purple_dark;
            .select-input{
                margin-top: 0px
            }
        }
    }
    .time-expense-detail{
        padding-top: 10px;
        height: 100%;
        overflow: auto;
        .Select-menu-outer {
            z-index: 2;
        }
        .start-end{
            .react-datepicker-wrapper {
                &:before {
                  @extend %fi;
                  color: $font-color-light_grey_purple;
                  line-height: 1;
                  content: $fi-calendar2;
                  font-size: 20px;
                  background:none;
                  line-height: 1;
                  @include border-radius(50%);
                  box-sizing: border-box;
                  display: inline-block;
                  margin-top: -11px;
                }
              }
            >div:first-of-type{
                margin-right: 0px !important;
            }
            >span{
                margin: 15px 5px 0px 10px;
                color: $font-color-light_grey_purple;
            }
        }
      }
      @media (max-width: 767px) {
        .col-1 {
          min-height: 300px;
          &.page-column-collapse {
            min-height: auto;
          }
        }
        .col-2,.col-3,.col-4{
          min-height: 600px !important;
          overflow: hidden;
          max-height: 500px !important;
          .column-body{
            overflow: unset;
          }
          &.page-column-collapse {
            min-height: auto !important;
          }
        }
        .col-3{
          min-height: 500px !important;
          &.page-column-collapse {
            min-height: auto !important;
          }
        }
        .col-4{
            min-height: 400px !important;
            &.page-column-collapse {
              min-height: auto !important;
            }
          }
        .col-5{
            min-height: 100% !important;
            &.page-column-collapse {
              min-height: auto !important;
            }
          }
      } 
}