@import "../../assets/sass/importFiles";
.draggable {
  margin-bottom: 10px;
  padding: 7px 37px 7px 10px;
  background: $darkBlue;
  position: relative;
  border-radius: 15px;
  cursor: pointer;
  transition: color 0.3s;
  display: inline-block;
  margin-right: 2%;
  font-size: 13px;
  vertical-align: top;
  color: $offwhite;

  &:before {
    @extend %fa;
    content: $fa-plus;
    font-size: 11px;
    position: absolute;
    right: 4px;
    top: 50%;
    margin-top: -12px;
    padding-top: 6px;
    bottom: 0;
    width: 22px;
    text-align: center;
    background: $offwhite2;
    border-radius: 50%;
    height: 22px;
    box-sizing: border-box;
    cursor: pointer;
    color: $darkBlue;
  }
  &:hover {
    color: $offwhite2;
  }
}
