.New-create-collobration-searcher {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .bg-color-input {
    background-color: #f7f6fe !important;
    border-radius: 5px;
  }
  .container-collapse {
    .inValid {
      border: 1px solid #f54949 !important;
    }
    textarea {
      resize: none;
    }
    @media (max-width: 767.98px) {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  .new-collapse-main {
    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;
    p {
      margin: 0;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 27px;
      color: #413c3b;
      @media (max-width: 767.98px) {
        font-size: 14px;
        line-height: unset;
      }
    }
  }
  .btn-footer {
    display: flex;
    margin: 15px 0px 0px 0px;
    justify-content: center;
    width: 100%;
    .btn-send {
      background: #8f82f5;
      border: 1px solid #8f82f5;
      width: 25%;
      border-radius: 8px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
      outline: none;
      padding: 8px;
    }
  }
  .ipro-mobile{
    color:#6C63FF;
    text-decoration: underline;
    text-align: right;
  }
}
