@import "../../../assets/sass/importFiles";
.create-collaboration-searcher-page {
  .alignStart {
    align-items: flex-start;
  }
  .input-text{
    margin-right: 15px;
  }
  @media (max-width:330px) {
    .form-row {
      display: flex;
      flex-direction: column;
      color: $purple2;
     
    }
  }
  @media (max-width:330px) {
    .heading{
      font-size: 12px;
    }
   }
}
