@import "../../../assets/sass/importFiles";


  .socialMedia {
    background: $green;
    padding: 7px;
    color: $white;
    text-align: center;
    font-size: 13px;
  }
  .socialCnt {
    text-align: center;
    padding: 5px 0;
  }
  .socialBtn {
    border: none;
    margin: 0 2%;
    background: $purple;
    padding: 2px;
    width: 40px;
    height: 40px;
    @include border-radius(50%);
    margin-bottom: 10px;
  }

  .facebookBtn {
    background-image: url("../../../assets/images/Icons/facebook.png");
    background-repeat: no-repeat;
    background-position: center center;
    &.activeBtn {
      background-color: #3b5997;
    }
  }

  .twitterBtn {
    background-image: url("../../../assets/images/Icons/twitter.png");
    background-repeat: no-repeat;
    background-position: center center;
    &.activeBtn {
      background-color: #00abf0;
    }
  }

  .googleplusBtn {
    background-image: url("../../../assets/images/Icons/google.png");
    background-repeat: no-repeat;
    background-position: center center;
    &.activeBtn {
      background-color: #dd4c39;
    }
  }

  .instagramBtn {
    background-image: url("../../../assets/images/Icons/instagram.png");
    background-repeat: no-repeat;
    background-position: center center;
    &.activeBtn {
      background-color: #78554c;
    }
  }

  .linkedinBtn {
    background-image: url("../../../assets/images/Icons/linkedin.png");
    background-repeat: no-repeat;
    background-position: center center;
    &.activeBtn {
      background-color: #007bb6;
    }
  }

  .pinterestBtn {
    background-image: url("../../../assets/images/Icons/pinterest.png");
    background-repeat: no-repeat;
    background-position: center center;
    &.activeBtn {
      background-color: #e3161d;
    }
  }

  .driblleBtn {
    background-image: url("../../../assets/images/Icons/driblle.png");
    background-repeat: no-repeat;
    background-position: center center;
    &.activeBtn {
      background-color: #e86181;
    }
  }

  .behanceBtn {
    background-image: url("../../../assets/images/Icons/behance.png");
    background-repeat: no-repeat;
    background-position: center center;
    &.activeBtn {
      background-color: #007bb6;
    }
  }

  .roundeImg {
    height: 105px;
    max-width: 100%;
  }

  .dashItemImg {
    width: 104px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 104px;
    position: relative;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    background: $offwhite2;
  }
  .no-dp {
    .roundeImg {
      max-width: 40px;
    }
  }
  .input-upload-file {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    padding: 50px;
  }
  .companyBigIcon {
    padding: 10px 0 20px;
    position: relative;
    .closeBtn {
      right: 50%;
      z-index: 1;
      top: 10px;
      margin: 0px -50px 0 0;
    }
  }
