@import "../../../assets/sass/importFiles";
.company-detail-component {
  padding: 0 10px;
  width: 100%;
  .company-image-wrap {
    display: table;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    background: $offwhite2;
    width: 105px;
    height: 105px;
  }
  .roundImg {
    max-width: 100%;
  }
  .emptyRoundImag {
    height: 105px;
    max-width: 40px;
  }
  .company-name {
    padding: 10px 0 0;
    display: block;
  }
}
