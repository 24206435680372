@import "../../../../assets/sass/importFiles";
@import "../../variables.scss";
  .input-field {
    border: 1.5px solid $font-color-light_grey_purple;
    border-radius: 4px;
    margin: 0px 5px 15px;
    background: $back-color-pale_purple_dark;
    color: $font-color-light_grey_purple;
    @extend .md-heading;
    padding: 2px 2px;
    &.inValid {
      border: 1px solid $red;
    }
    .Select-control {
      margin-left: 0px;
    }
    h6 {
      color: $font-color-light_grey_purple;
      width: fit-content;
      margin-top: -10px;
      margin-left: 10px;
      padding-left: 5px;
      padding-right: 5px;
      background: $back-color-pale_purple_dark;
      margin-block-end: 0.4em; 
      font-weight: 100;   
      font-size: inherit;
    }
    > div {
      display: flex;
      flex-direction: column;
      input[type=file]{
        margin: 0px 5px 5px;
      }
    }
    .react-datepicker__time-container .react-datepicker__time{
        width: 100px;
        .react-datepicker__time-box{
          width: 100px;
        }
      }
      .react-datepicker__header--time{
        width: 100px;
      }
      
    .input-text{
        background: $back-color-pale_purple_dark;
        color: $font-color-light_grey_purple;
        margin-bottom: 5px !important;
        padding-left: 5px;
        @extend .md-heading;
      }
      .textarea-field{
        background: $back-color-pale_purple_dark;
        color: $font-color-light_grey_purple;
        padding-left: 5px;
        @extend .md-heading;
      }
      .select-input .Select-control {
        background: $back-color-pale_purple_dark !important;
        color: $font-color-light_grey_purple !important;
        margin-bottom: 0px !important;
        padding-left: 5px;
        @extend .md-heading;
      }
      .select-input .Select-value-label {
        color: $font-color-light_grey_purple !important;
        @extend .md-heading;
      }
      .select-input{
        margin-bottom: 5px !important;
        padding-left: 0px !important;
      }
      .Select-placeholder{
        padding-left: 5px;
      }
      .select-input .Select-control .Select-value{
        line-height: 30px !important;
        padding-left: 5px !important;
        padding-right: 0px !important;
      }
      .Select-placeholder{
        @extend .md-heading;
      }
      .datepicker-input { 
        background: $back-color-pale_purple_dark !important;
        color: $font-color-light_grey_purple !important;
        padding: 6px 5px;
        @extend .md-heading;
      }
      .datepicker-wrapper {
        margin-bottom: 5px;
      }
      .Select-arrow-zone{
        padding-top: 5px;
      }
    .no-label {
        input {
          padding-top: 10px;
        }
      }
  }
  