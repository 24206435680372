.new-rating-view-component {
  // background: #f3f1fd;
  border-radius: 12px;
  // padding: 16px 16px;
  // margin-left: 20px;
  height: 100%;
  @media (max-width: 767.98px) {
    padding: 10px 10px;
    margin-left: 0px;
  }
  .inner-user {
    background: #fff;
    border-radius: 12px;
    padding: 16px;
     @media (max-width: 767.98px) {
      padding: 10px;
    }

    .user-title {
      text-align: center;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 27px;
      color: #413c3b;
      margin-top: 6px;
      @media (max-width: 767.98px) {
        font-size: 14px;
        line-height: unset;
      }
    }
    p {
      margin: 16px 0px 0px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 27px;
      color: #413c3b;
      @media (max-width: 767.98px) {
        font-size: 14px;
        line-height: unset;
      }
    }
  }
  textarea {
    resize: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-top: 6px;
  }
  .star-ratings {
    margin-top: 6px;
  }
  .btn-footer {
    display: flex;
    margin: 15px 0px 0px 0px;
    justify-content: center;
    width: 100%;
    .btn-delete {
      background: #8f82f5;
      border: 1px solid #8f82f5;
      width: 25%;
      border-radius: 8px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
      outline: none;
      padding: 8px;
    }
  }
}
