@import "../../../../assets/sass/importFiles";
.landing-footer {
  @extend %clearfix;
  margin-top: 10px;
  background: $darkBlue;
  position: relative;
  width: 100%;
  z-index: 20;
  height: auto;
  display: flex;
  justify-content: center;
  flex-flow: column;
  color: $white;
  .bgUpdate & {
    background: #fafafa;
  }
  .loginScreen {
    .bgUpdate & {
      display: none;
    }
  }
  .otherScreen {
    display: none;
    .bgUpdate & {
      display: block;
    }
  }
  @include breakpoint(screen767) {
    // position: relative;
  } //MobileLandscape
  &:before {
    content: "";
  }
  .BottomBar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: url("../../../../assets/images/topbar.png");
    height: 10px;
    background-size: contain;
    img {
      display: none;
      max-width: 100%;
      float: left;
    }
  }

  .footerMenuContainer {
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-self: flex-start;
    h1.footerMenuHeading {
      font-size: 15px;
      margin: 5px 0px;
    }
    a {
      color: $white;
      text-decoration: none;
    }
    span {
      font-size: 13px;
    }
    .footer-item {
      align-self: flex-start;
    }
  }

  .Logo {
    align-self: center;
    // margin-top: 15px; // background: #fff;
    // border-bottom: 7px solid #00C9A7;
    // border-left: 7px solid #00C9A7;
    width: 150px;
    // padding: 15px 20px 0 0;
    @include breakpoint(screen1023) {
      width: 175px;
      max-width: 45%;
    }
    img {
      max-width: 100%;
      margin-top: 20px;
      margin-bottom: -10px;
    }
  }
  .rights {
    margin: 20px 0px 15px;
    align-self: center;
  }
  @include breakpoint(screen767) {
    // position: relative;
    .footerMenuContainer {
      flex-flow: column;
      margin: 0 10px;
      .footer-item {
        margin: 10px 0 0;
      }
    }
    height: auto;
    .rights {
      padding-bottom: 10px;
    }
  }
}
