@import "../../../assets/sass/importFiles";
.present-list {
  .close_btn_purple{
    padding-top: 0px !important;
  }
  .list-item {
    display: flex;
    padding: 1rem;
    align-items: center;
  }
  .dashItemImg {
    width: 52px;
    height: 52px;
    @include border-radius(50%); // border: darken($offwhite2, 10) 1px solid;
    overflow: hidden;
    background: $ViewsCnt;
  }
  .roundImg {
    width: 52px;
    height: 52px;
  }
  .CompNameCnt {
    padding: 0 0 0 20px;
    flex: 1;
  }
  .listLabel {
    font-family: $RubikRegular;
    font-weight: 500;
    font-size: 14px;
  }
  .CompAddress {
    font-weight: normal;
  }
  .no-dp {
    display: flex;
    justify-content: center;
    align-items: center;
    .roundImg {
      width: 25px;
      height: 25px;
    }
  }
}
