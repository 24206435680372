@import "../../assets/sass/importFiles";
.page-wrapper.cms-page {
  display: block;
  overflow: unset;
  .views-cnt {
    display: block;
    padding-right: 0;
    padding-bottom: 0;
    overflow: unset;
  }
  .landing-header{
    position: relative;
  }
  section{
    padding: 50px;
    h1{
      font-family: "rubik",Arial,sans-sarif;
      font-weight: 400;
      color: #3e335e;
      font-size: 40px;
      margin: 50px 0 30px;
    }
    p{
      font-family: "rubik",Arial,sans-sarif;
      font-weight: 400;
      color: #3e335e;
      font-size: 15px;
      margin: 20px 0 0;
    }
  }
}
