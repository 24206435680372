@import "../../../../assets/sass/importFiles";
.collaboration-detail-component {
  display: flex;
  width: 100%;
  .invRequest {
    justify-content: center;
    .searcherClose {
      margin: 0 5% !important;
    }
    .button-wrapper {
      width: 100%;
      max-width: 300px;
      font-size: 16px;
      margin: 0 10px;
      font-family: "rubik", Arial, sans-sarif;
      color: #ffffff;
      line-height: 1;
      transition: 0.3s all;
    }
  }
  .invLabelInterest {
    display: inline-block;
  }
  .MsgWrapper {
    width: 100%;
  }
  .labelName {
    min-width: 100px;
  }
}
