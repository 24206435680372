@import "../../../../assets/sass/importFiles";
@import "../../variables.scss";
.reports-view {
  flex: 1;
  display: flex;
  min-height: 100%;
  overflow: unset !important;
  @include breakpoint(screen767) {
    flex-flow: column;
  }
  .col-1 {
    .select-input {
      margin: 5px 0px 0px 0px;
      width: 100%;
      &.hidden{
        display: none !important;
      }
      .Select-control {
        background: $purple4 !important;
        color: $white !important;
        height: 46px;
        font-size: 15px;
        text-align: center;
        box-shadow: 1px 2px #8888888a;
        border-radius: 5px;
        border: 2px solid #f6aa3a !important;
      }
      .Select-menu-outer {
        background: transparent;
      }
      &.yellow {
        .Select-control {
          border: 2px solid $yellow !important;
        }
      }
      &.red {
        .Select-control {
          border: 2px solid $red !important;
        }
      }
      &.green {
        .Select-control {
          border: 2px solid $green !important;
        }
      }
      .Select-value-label {
        line-height: 40px;
        color: $white !important;
      }
      .Select-arrow-zone:hover > .Select-arrow {
        border-top-color: $white !important;
      }
      .Select-arrow {
        border-color: $white transparent transparent;
      }
      .Select-option {
        padding: 15px 6px;
        font-size: 13px;
        color: $white;
        text-align: center;
        background: #9783cf;
        margin-bottom: 2px;
        border-radius: 5px;
        &:nth-child(1) {
          border: 2px solid $yellow !important;
          border-bottom: none;
          margin-top: 2px;
        }
        &:nth-child(2) {
          border: 2px solid $green !important;
          border-bottom: none;
        }
        &:nth-child(3) {
          border: 2px solid $red !important;
        }
      }
    }
  }
  .timesheet-summary {
    margin-top: 5px;
    border-radius: 5px;
    background: $back-color-light_purple;
    color: $white;
    min-height: 45px;
  }
  .expense-detail {
    padding-top: 10px;
  }
  .timelog-list-container {
    display: flex;
    flex-flow: column;
    height: 100%;
  }
  .timelog-list-wrapper,
  .tictell-list-wrapper {
    display: flex;
    flex-flow: column;
    height: 100%;
    min-height: 100%;
    .tictell-list,
    .timelog-list {
      flex: 1;
      overflow: auto;
      height: 100%;
    }
  }
  .attachments-list {
    margin: 0px 5px 5px;
  }
  .react-datepicker-wrapper {
    &:before {
      @extend %fi;
      color: $font-color-light_grey_purple;
      line-height: 1;
      content: $fi-calendar2;
      font-size: 20px;
      background: none;
      line-height: 1;
      @include border-radius(50%);
      box-sizing: border-box;
      display: inline-block;
      margin-top: -13px;
    }
  }
  .timelog-detail {
    padding-top: 10px;
    .start-end {
      .react-datepicker-wrapper {
        &:before {
          @extend %fi;
          color: $font-color-light_grey_purple;
          line-height: 1;
          content: $fi-Timepicker;
          font-size: 20px;
          background: none;
          line-height: 1;
          @include border-radius(50%);
          box-sizing: border-box;
          display: inline-block;
          margin-top: -10px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .col-1,
    .col-2,.col-3 {
      min-height: 300px;
      &.page-column-collapse {
        min-height: auto;
      }
    }
  }
  @supports (-webkit-appearance: none){
    @media not all and (min-resolution: 0.001dpcm){
        height: 90% !important;
        .col-1 {
          max-height: 400px;
          height: 100%;
          .column-head{
            
            min-height: 45px;
          }
          .tictell-list{
            margin-top: 5px;
            height: 100%;
          } 
        }
        .col-3,.col-4{
          min-height: 500px !important;
          &.page-column-collapse {
            min-height: auto !important;
          }
        }
        .col-1,.col-2,.col-3,.col-4,.col-5{
          height: 100%;
          min-height: 100%;
          flex-basis: 33%;
          &.page-column-collapse {
            flex-basis: auto;
          }
          @include breakpoint(screen767) {
              max-height: 50px;
              height: 50px;
              &.column-collapsed {
                  min-height: auto;
                  max-height: 50px;
              }
          }
        }
        
        input[disabled], textarea[disabled],
        select[disabled='disabled']{
            color: #000001 !important;
        }
    }
  }
}
