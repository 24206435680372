@import "../../../assets/sass/importFiles";
.user-detail-component {
  background-color: white;
  padding: 0 10px;
  width: 100%;
  .button-wrapper {
    width: 100%;
    max-width: 300px;
    margin-right: 5px;
    font-size: 16px;
    font-family: "rubik", Arial, sans-sarif;
    color: #ffffff;
    line-height: 1;
    transition: 0.3s all;
  }
  .presentaion-image-wrap {
    display: table;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    background: $offwhite2;
    width: 105px;
    height: 105px;
  }
  .roundImg {
    max-width: 100%;
  }
  .emptyRoundImag {
    height: 105px;
    max-width: 40px;
  }
  .presentaion-name {
    padding: 10px 0 0;
    display: block;
  }
  .socialCnt {
    text-align: center;
    padding: 5px 0;
  }
  .socialBtn {
    border: none;
    margin: 0 2%;
    background: $purple;
    padding: 2px;
    width: 40px;
    height: 40px;
    @include border-radius(50%);
    margin-bottom: 10px;
  }
  .facebookBtn {
    background-image: url("../../../assets/images/Icons/facebook.png");
    background-repeat: no-repeat;
    background-position: center center;
    &.activeBtn {
      background-color: #3b5997;
    }
  }

  .twitterBtn {
    background-image: url("../../../assets/images/Icons/twitter.png");
    background-repeat: no-repeat;
    background-position: center center;
    &.activeBtn {
      background-color: #00abf0;
    }
  }

  .googleplusBtn {
    background-image: url("../../../assets/images/Icons/google.png");
    background-repeat: no-repeat;
    background-position: center center;
    &.activeBtn {
      background-color: #dd4c39;
    }
  }

  .instagramBtn {
    background-image: url("../../../assets/images/Icons/instagram.png");
    background-repeat: no-repeat;
    background-position: center center;
    &.activeBtn {
      background-color: #78554c;
    }
  }

  .linkedinBtn {
    background-image: url("../../../assets/images/Icons/linkedin.png");
    background-repeat: no-repeat;
    background-position: center center;
    &.activeBtn {
      background-color: #007bb6;
    }
  }

  .pinterestBtn {
    background-image: url("../../../assets/images/Icons/pinterest.png");
    background-repeat: no-repeat;
    background-position: center center;
    &.activeBtn {
      background-color: #e3161d;
    }
  }

  .driblleBtn {
    background-image: url("../../../assets/images/Icons/driblle.png");
    background-repeat: no-repeat;
    background-position: center center;
    &.activeBtn {
      background-color: #e86181;
    }
  }

  .behanceBtn {
    background-image: url("../../../assets/images/Icons/behance.png");
    background-repeat: no-repeat;
    background-position: center center;
    &.activeBtn {
      background-color: #007bb6;
    }
  }
}
