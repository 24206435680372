@import "../../../../assets/sass/importFiles";
.phillip-dashboard-searcher {
  background: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: unset;
  flex: 1 1;
  height: 100%;
  color: #3e335e;
  .main-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    color: #3e335e;
    flex-flow: row;
    justify-content: space-between;
    .right-div {
      flex: 1 1;
      align-self: center;
      .js_textarea_wrap {
        margin-top: 10px;
        .js_textarea {
          background: #f1ecff;
          border: 0;
          width: 100%;
          margin: 0px 50px 50px;
          border-radius: 3px;
          height: 250px;
          font-size: 13px;
          padding: 8px 10px;
          resize: none;
          display: block;
          &:focus {
            outline: none;
          }
        }
      }
    }
    .left-div {
      flex: 1 1;
      align-self: center;
      display: flex;
      padding: 30px 60px;
      .first-view {
        display: flex;
        flex: 1;
      }
      .sliderImageWrap {
        flex: 0.3;
        min-width: 100px;
        img {
          max-height: 300px;
        }
      }
      .resume-upload-row {
        text-align: center;
        display: flex;
        flex-flow: column;
        align-self: flex-start;
        align-self: center;
      }
      .button-wrapper {
        align-self: center;
        display: flex;
        justify-content: center;
      }

      .continue-button {
        color: white;
        background: #05caa8;
        border: transparent;
        border-radius: 5px;
        align-self: center;
        margin-bottom: 5px;
        padding: 5px 15px;
        margin-top: 10px;
        // color: $white;
        // padding: 5px 10px;
        // max-width: 100%;
        // background-color: $green;
        // border: none;
        // cursor: pointer;
        // border-radius: 2px;
        // font: normal 13px/16px $RubikRegular;
        // max-width: 150px;
        // align-self: center;
        // margin: 10px;
        // padding: 10px;
      }
      .settings-container > div {
        font-size: 12px;
        margin-bottom: 10px;
      }
      .heading-div {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        margin-right: 80px;
        margin-bottom: 10px;
        .profileIcon,
        .skillIcon,
        .educationIcon {
          border-radius: 20%;
          margin-right: 10px;
          &:before {
            line-height: 2;
          }
        }
        label {
          align-self: center;
        }
      }
    }
    .draggable {
      padding: 7px 10px 7px 10px;
      &:before {
        content: none;
      }
    }
  }
  @include breakpoint(screen991) {
    .main-div {
      flex-flow: column;
      padding: 30px 10px;
      .right-div {
        min-width: 90%;
        textarea {
          margin: 0 !important;
        }
      }
      .left-div {
        width: 100%;
        .top-salaries {
          margin-right: 0px;
        }
        .mixed-chart {
          width: 100%;
        }
      }
    }
  }
  @include breakpoint(screen767) {
    .mixed-chart {
      > div {
        &:first-of-type {
          border-radius: 10px 10px 0px 0px;
        }
        &:nth-of-type(2) {
          border-radius: 0px 0px 10px 10px;
        }
      }
    }
  }
}

@import "../../../../assets/sass/importFiles";
@import "../../variables.scss";
.reports-view-new-search {
  flex: 1;
  display: flex;
  min-height: 100%;
  overflow: unset !important;
  @include breakpoint(screen767) {
    flex-flow: column;
  }
  .col-1 {
    .select-input {
      margin: 5px 0px 0px 0px;
      width: 100%;
      &.hidden {
        display: none !important;
      }
      .Select-control {
        background: $purple4 !important;
        color: $white !important;
        height: 46px;
        font-size: 15px;
        text-align: center;
        box-shadow: 1px 2px #8888888a;
        border-radius: 5px;
        border: 2px solid #f6aa3a !important;
      }
      .Select-menu-outer {
        background: transparent;
      }
      &.yellow {
        .Select-control {
          border: 2px solid $yellow !important;
        }
      }
      &.red {
        .Select-control {
          border: 2px solid $red !important;
        }
      }
      &.green {
        .Select-control {
          border: 2px solid $green !important;
        }
      }
      .Select-value-label {
        line-height: 40px;
        color: $white !important;
      }
      .Select-arrow-zone:hover > .Select-arrow {
        border-top-color: $white !important;
      }
      .Select-arrow {
        border-color: $white transparent transparent;
      }
      .Select-option {
        padding: 15px 6px;
        font-size: 13px;
        color: $white;
        text-align: center;
        background: #9783cf;
        margin-bottom: 2px;
        border-radius: 5px;
        &:nth-child(1) {
          border: 2px solid $yellow !important;
          border-bottom: none;
          margin-top: 2px;
        }
        &:nth-child(2) {
          border: 2px solid $green !important;
          border-bottom: none;
        }
        &:nth-child(3) {
          border: 2px solid $red !important;
        }
      }
    }
    .phil_textarea_wrap {
      padding: 10px;
      textarea {
        width: 100%;
        resize: none;
        border-radius: 10px;
        padding: 10px;
        background: transparent;
        outline: none;
        border: 1px solid #9783cf;
        color: #9783cf;
      }
    }
    .button-wrapper {
      text-align: center;
      .continue-button {
        color: white;
        background: #05caa8;
        border: transparent;
        border-radius: 5px;
        align-self: center;
        margin-bottom: 5px;
        padding: 5px 15px;
        margin-top: 10px;
      }
    }
  }
  .right-div {
    flex: 10 1;
    align-self: center;
    padding: 20px;
    h6 {
      font-size: 15px;
      text-align: center;
      margin: 0 auto;
      font-family: "rubik", Arial, sans-sarif;
      font-weight: normal;
      color: #3e335e;
    }
    .button-wrapper {
      align-self: center;
      display: flex;
      justify-content: center;
    }

    .continue-button {
      color: white;
      background: #05caa8;
      border: transparent;
      border-radius: 5px;
      align-self: center;
      padding: 5px 15px;
      margin-top: 10px;
      // color: $white;
      // padding: 5px 10px;
      // max-width: 100%;
      // background-color: $green;
      // border: none;
      // cursor: pointer;
      // border-radius: 2px;
      // font: normal 13px/16px $RubikRegular;
      // max-width: 150px;
      // align-self: center;
      // margin: 0 50% 2%;
      // min-width: 105px;
      // padding: 10px;
    }
    .answerLabel {
      padding: 0px;
    }
    .phil_textarea_wrap {
      .phil_textarea {
        background: #f1ecff;
        border: 1px solid #9783cf;
        width: 100%;
        border-radius: 3px;
        font-size: 13px;
        padding: 8px 10px;
        resize: none;
        display: block;
        margin: 10px 0;
        &:focus {
          outline: none;
        }
      }
    }
  }
  .left-div {
    flex: 1 1;
    align-self: center;
    display: flex;
    padding: 10px;
    width: 100%;
    .first-div {
      width: 100%;
    }
    .first-view {
      display: flex;
      flex: 1;
    }
    .sliderImageWrap {
      flex: 0.3;
      min-width: 100px;
      img {
        max-height: 300px;
      }
    }
    .resume-upload-row {
      text-align: center;
      display: flex;
      flex-flow: column;
      align-self: flex-start;
      align-self: center;
    }
    .button-wrapper {
      align-self: center;
      display: flex;
      justify-content: center;
    }

    .continue-button {
      color: white;
      background: #05caa8;
      border: transparent;
      border-radius: 5px;
      align-self: center;
      margin-bottom: 5px;
      padding: 5px 15px;
      margin-top: 10px;
      // color: $white;
      // padding: 5px 10px;
      // max-width: 100%;
      // background-color: $green;
      // border: none;
      // cursor: pointer;
      // border-radius: 2px;
      // font: normal 13px/16px $RubikRegular;
      // max-width: 150px;
      // align-self: center;
      // margin: 10px;
      // padding: 10px;
    }
    .settings-container > div {
      font-size: 12px;
      margin-bottom: 10px;
      color: #9783cf;
    }
    .heading-div {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      margin-right: 80px;
      margin-bottom: 10px;
      .profileIcon,
      .skillIcon,
      .educationIcon {
        border-radius: 20%;
        margin-right: 10px;
        &:before {
          line-height: 2;
        }
      }
      label {
        align-self: center;
        color: #9783cf;
        font-weight: 600;
      }
    }
  }
  .draggable {
    color: #8d82ab !important;
    font-weight: 800;
    background: #fff !important;
    &:before {
      transform: rotate(45deg) !important;
      color: #8d82ab;
      right: 8px;
    }
  }
  .timesheet-summary {
    margin-top: 5px;
    border-radius: 5px;
    background: $back-color-light_purple;
    color: $white;
    min-height: 45px;
  }
  .expense-detail {
    padding-top: 10px;
  }
  .timelog-list-container {
    display: flex;
    flex-flow: column;
    height: 100%;
  }
  .timelog-list-wrapper,
  .tictell-list-wrapper {
    display: flex;
    flex-flow: column;
    height: 100%;
    min-height: 100%;
    .tictell-list,
    .timelog-list {
      flex: 1;
      overflow: auto;
      height: 100%;
    }
  }
  .attachments-list {
    margin: 0px 5px 5px;
  }
  .react-datepicker-wrapper {
    &:before {
      @extend %fi;
      color: $font-color-light_grey_purple;
      line-height: 1;
      content: $fi-calendar2;
      font-size: 20px;
      background: none;
      line-height: 1;
      @include border-radius(50%);
      box-sizing: border-box;
      display: inline-block;
      margin-top: -13px;
    }
  }
  .timelog-detail {
    padding-top: 10px;
    .start-end {
      .react-datepicker-wrapper {
        &:before {
          @extend %fi;
          color: $font-color-light_grey_purple;
          line-height: 1;
          content: $fi-Timepicker;
          font-size: 20px;
          background: none;
          line-height: 1;
          @include border-radius(50%);
          box-sizing: border-box;
          display: inline-block;
          margin-top: -10px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .col-1,
    .col-2,
    .col-3 {
      min-height: 300px;
      &.page-column-collapse {
        min-height: auto;
      }
    }
  }
  @supports (-webkit-appearance: none) {
    @media not all and (min-resolution: 0.001dpcm) {
      height: 90% !important;
      .col-1 {
        max-height: 400px;
        height: 100%;
        .column-head {
          min-height: 45px;
        }
        .tictell-list {
          margin-top: 5px;
          height: 100%;
        }
      }
      .col-3,
      .col-4 {
        min-height: 500px !important;
        &.page-column-collapse {
          min-height: auto !important;
        }
      }
      .col-1,
      .col-2,
      .col-3,
      .col-4,
      .col-5 {
        height: 100%;
        min-height: 100%;
        flex-basis: 33%;
        &.page-column-collapse {
          flex-basis: auto;
        }
        @include breakpoint(screen767) {
          max-height: 50px;
          height: 50px;
          &.column-collapsed {
            min-height: auto;
            max-height: 50px;
          }
        }
      }

      input[disabled],
      textarea[disabled],
      select[disabled="disabled"] {
        color: #000001 !important;
      }
    }
  }
}
