.resume-list-component-opportunity {
  flex: 1;
  overflow: auto;
  padding:12px;
  background: #fff;
  .infinite-scroll-component__outerdiv {
    height: 100%;
  }
  .column-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #e4e4e4;
    // @media (max-width: 767.98px) {
    //   display: none;
    // }
  }
}

@media (max-width: 767.98px) {
  .star-icons {
    justify-content: end;
  }
}
