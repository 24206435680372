@import "../../../../../assets/sass/importFiles";
.opportunity-detail-component {
  display: flex;
  width: 100%;
  .invRequest{
    .button-wrapper {
      width: 100%;
      margin-right: 5px;
      font-size: 16px;
      font-family: "rubik", Arial, sans-sarif;
      color: #ffffff;
      line-height: 1;
      transition: 0.3s all;
    }
  }
  .invLabelInterest {
    display: inline-block;
  }
  .MsgWrapper {
    width: 100%;
  }
  .invScore {
    display: block;
  }
}
