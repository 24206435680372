@import "../../../assets/sass/importFiles";
.app-view-container {
  width: calc(100% - 230px) !important ;
}
.select-location{
  .Select-value{
    line-height: 32px !important;
  }
}
.resume-edit-component {
  .title-bar {
    display: flex;
  }
  .draggable-btn {
    border: none $imp;
    height: 28px;
    background: $darkBlue $imp;
    width: 28px;
    @include border-radius(3px);
    padding: 0;
    cursor: hand;
    @include transition(0.3s height);
    opacity: 0.5;
    &:before {
      @extend %fi;
      font-size: 20px;
      content: $fi-eye1;
      vertical-align: middle;
      color: $offwhite;
    }
    &.active {
      opacity: 1;
      &:before {
        content: $fi-eye1;
      }
    }
    &.candidate {
      &:before {
        content: $fi-help;
      }
    }
  }
  .back-btn {
    border: none $imp;
    background: $green $imp;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    @include border-radius(3px);
    padding: 0;
    z-index: 1;
    overflow: visible;
    &:before {
      margin-top: -1px;
      @extend %fi;
      font-size: 18px;
      content: $fi-back;
      vertical-align: middle;
      color: darken($green, 20);
    }
  }
  .job-availability-bar {
    // margin-top: 10px;
    display: flex;
    flex-flow: row wrap;
    .help-icon {
      top: 2px;
      right: 2px;
    }
  }
  .column-body {
    background: none;
  }
  .button-flex {
    flex: 1;
    margin-right: 10px;
    @include breakpoint(screen991) {
      flex: 1;
      margin-bottom: 10px;
    }
    @include breakpoint(screen640) {
      width: 100%;
      flex: none;
      margin-right: 0;
    }
    &:nth-child(2) {
      @include breakpoint(screen991) {
        margin-right: 0;
      }
    }

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
      @include breakpoint(screen991) {
        flex: none;
        width: 100%;
      }
    }
  }
  .availibility-btn {
    border: none $imp;
    padding: 0;
    height: 40px;
    border-radius: 3px;
    background: $lightPurple2 $imp;
    width: 100%;
    color: $white;
    flex: 1;
    &.active {
      background: $green $imp;
    }
  }
  .notice-btn {
    @extend .availibility-btn;
    &.active {
      background: $yellow $imp;
    }
  }
  .date-btn {
    @extend .availibility-btn;
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .datepicker-input {
      color: $purple2;
      background: inherit;
    }
    &.active {
      background: $red $imp;
    }
    .react-datepicker-wrapper {
      background: $offwhite;
      border-radius: 4px;
      margin-left: 15px;
    }
  }
  .sub-heading {
    color: $purple2;
    margin-bottom: 10px;
  }
  .add-correletion-text p {
    color: $purple2;
    font-size: 12px;
    margin-bottom: 6px;
    line-height: 1.5;
  }
  .Modal {
    width: 780px;
    max-width: 100%;
    height: 560px;
    overflow: auto;
    background: $darkBlue;
    padding: 20px 20px;
    color: $white;
    border-radius: 5px;
    align-content: center;
  }
  .modal-header {
    > div {
      width: 100%;
      background: $red;
    }
  }

  .other-acheivement-component,
  .professional-experience-component {
    .list-group-wrapper {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .experienceCheckbox {
    padding: 6px 0px;
    display: flex;
    cursor: pointer;
  }
  .experience-to-label {
    height: 67%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .to-column {
    max-width: max-content;
  }
  .is-working {
    min-width: min-content;
  }
  .AddBtn {
    margin-left: 0px;
  }
  .SaveCloudBtn {
    margin-left: 0px;
  }
  .experienceCheckboxLabel {
    white-space: nowrap;
  }
 
}
