@import "../../../../assets/sass/importFiles";
.manageflows-view {
  flex: 1;
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  > * {
    flex: 1 1 250px;
  }
  .flow-detail {
    margin-top: 10px;
  }
  .button-wrapper {
    align-self: center;
  }
  .flow-container {
    label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 220px;
    }
  }
  @include breakpoint(screen767) {
    flex-flow: column;
    .page-column {
      min-height: 350px;
      height: auto;
    }
    .page-column.page-column-collapse {
      height: auto;
      min-height: 0;
    }
  }
  .snapshot-collaboration-detail,
  .expense-detail,
  .time-expense-detail {
    padding-top: 10px;
  }
  .col-1 {
    @include breakpoint(screen767) {
      min-height: 250px;
      height: auto;
      &.page-column.page-column-collapse {
        min-height: auto;
      }
      margin-top: 60px;
    }

    .select-input {
      margin: 5px 0px 0px 0px;
      width: 100%;
      .Select-control {
        background: $purple4 !important;
        color: $white !important;
        height: 45px;
        font-size: 15px;
        text-align: center;
        box-shadow: 1px 2px #8888888a;
        border-radius: 5px;
        // border: 2px solid #f6aa3a !important;
      }
      .Select-value {
        width: 92%;
      }
      .Select-menu-outer {
        background: transparent;
      }
      .Select-value-label {
        line-height: 45px;
        color: $white !important;
      }
      .Select-arrow-zone:hover > .Select-arrow {
        border-top-color: $white !important;
      }
      .Select-arrow {
        border-color: $white transparent transparent;
      }
      .Select-option {
        padding: 10px 6px;
        font-size: 13px;
        color: #ffffff;
        text-align: center;
        background: #9783cf;
        margin-bottom: 2px;
        border-radius: 5px;
        border: 2px solid transparent !important;
        border-bottom: none;
        margin-top: 2px;
        margin: 2px;
      }
    }
  }
  .list-icon {
    display: inline-block;
    border: none;
    min-width: 24px;
    min-height: 24px;
    font-size: 0;
    margin-right: 0px;
    align-self: center;
    margin-top: 5px;
    &:after {
      @extend %fi;
      content: $fi-share-icon;
      font-size: 20px;
      color: #8d82ab;
      margin: auto;
      width: 24px;
      height: 24px;
      background-size: 20px;
    }
  }
  .flow-icon {
    @extend .list-icon;
    &:after {
      content: $fi-request;
    }
  }
  .flowgroup-icon {
    @extend .list-icon;
    &:after {
      content: fi-Collaboration;
    }
  }
}
