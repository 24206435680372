@import "../../assets/sass/importFiles";
.round-circle-list {
  display: table-cell;
  height: 100%;
  vertical-align: top;
  width: 100%;
  .round-circle-item {
    display: inline-block;
    background: $offwhite3;
    border-radius: 20px;
    padding: 5px 5px 5px 10px;
    margin: 5px;
    table-layout: auto !important;
  }
  .item-inner-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    vertical-align: top;
  }
  .form-label {
    min-width: 60px;
    margin: 7px 9px 7px 0;
    vertical-align: text-top;
    display: flex;
    align-items: center;
    &::before {
      @extend %fa;
      content: $fa-circle;
      font-size: 8px;
      vertical-align: top;
      margin: 0px 5px 0 0;
      color: $purple4;
    }
  }
  .closeBtn {
    background: $offwhite2 $imp;
    position: relative;
    right: 2px;
    margin-top: 1px;
    top: 0;
    margin: 4px 0px 4px 10px;
    &:hover {
      background: $red $imp;
    }
  }
}
