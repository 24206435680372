.new-collaboration-detail-component {
  .collaboration-detail {
    padding: 16px;
    color: #000000;
  }
  .workplace-detail {
    background: #fafafa;
    border-radius: 8px;
    padding: 16px;
  }
  .details {
    display: flex;
    gap: 40px;
  }
  .flex-1{
    flex: 1;
  }
  .ipro-details {
    background: #fff;
    height: 100%;
    @media (max-width: 767.98px) {
      padding: 0px 0px;
    }
    .image-user {
      text-align: center;
      margin-bottom: 10px;
      img {
        border-radius: 50%;
        object-fit: none;
        width: 200px;
        height: 200px;
        @media (max-width: 767.98px) {
          width: 150px;
          height: 150px;
        }
      }
    }
    .user-title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 27px;
      margin-top: 6px;
      @media (max-width: 767.98px) {
        font-size: 14px;
        line-height: unset;
      }
    }
    .user-detail {
      margin: 0;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 27px;
      color: #413c3b;
      @media (max-width: 767.98px) {
        font-size: 14px;
        line-height: unset;
      }
    }
    .btn-footer {
      display: flex;
      margin: 15px 0px 15px 0px;
      justify-content: center;
      width: 100%;
      .btn-delete {
        background: #8f82f5;
        border: 1px solid #8f82f5;
        width: 40%;
        border-radius: 8px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        outline: none;
        padding: 8px;
      }
    }
    .btn-footer-ipro {
      display: flex;
      margin: 15px 0px 15px 0px;
      justify-content: center;
      width: 100%;
      .btn-delete {
        width: 40%;
        border-radius: 8px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        outline: none;
        padding: 8px;
      }
    }
    .clr-purple {
      background: #8f82f5;
      border: 1px solid #8f82f5;
    }
    .clr-red {
      background-color: #f54949;
      border: 1px solid #f54949;
    }
  }
}
