@import "../../../../assets/sass/importFiles";
@import "../../variables.scss";
  .tictell-list-item-container{
    display: flex;
    color: $font-color-light_grey_purple;
    @extend .lg-heading;
    padding: 2px 5px 2px 5px;
    cursor: pointer;
    position: relative;
    background: $back-color-pale_purple_dark;
    height: 47px;
    max-height: 47px;
    .duration-container {
        display: flex;
        flex-direction: column;
        font-size: 8px;
        margin: 0px 5px 0px 0px;
        font-weight: 200;
        text-align: center;
        align-self: center;
        label{
            white-space: nowrap;
        }
    }
    .dashItemImg {
      width: 35px;
      height: 35px;
      margin: 0px !important;
      margin-right: 5px !important;
      .roundImg {
        width: 33px;
        height: 33px;
        max-height: 33px;
        max-width: 33px;
        border-radius: 50%;
        border: 1px solid;
        margin-top: 1.5px;
      }
      .no-dp{
        .roundImg {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid;
          margin-top: 1.5px;
        }
      }
    }
    .name-container{
      display: flex;
      flex-flow: column;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      flex: 1;
      >div{
        display: flex;
        flex: 1;
        
        label{
         
          @extend .lg-heading;
          @media (max-width:330px) {
            font-size: 10px ;
        
          }
        }
        label:first-child{
          flex: 1;
         
        }
      }
      >label:last-child{
        
        @extend .md-heading;
       
      }
      
    }
    .list-checkbox{
      align-self: center;
      margin-right: 5px;
      margin-left: 5px;
      input[type=checkbox]
      {
        /* Double-sized Checkboxes */
        -ms-transform: scale(2); /* IE */
        -moz-transform: scale(2); /* FF */
        -webkit-transform: scale(2); /* Safari and Chrome */
        -o-transform: scale(2); /* Opera */
        transform: scale(1.5);
        padding: 10px;
        margin-right: 5px;
      }
    }
    .currency-label{
      align-self: center;
      margin: 1px 10px 0px;
      @media (max-width:330px) {
        font-size: 12px;
      }
    }
    .amount-time{
      display: flex;
      flex-flow: column;
      text-align: center;
      label:last-child{
        &:not(:first-child){
          @extend .md-heading;
          
        
        }
      }
      label:first-child{
        @extend .lg-heading;
      }
      label:only-child{
        @extend .lg-heading;
      }
    }
    .closeBtn{
      position: relative;
      margin-left: 10px;
      margin-right: -5px;
      margin-top: -10px;
    }
  &:hover {
    font-weight: 700;
  }
  border-block-end: 1px solid $white;
  .attachment-icon {
    display: inline-block;
    border: none;
    min-width: 20px;
    min-height: 20px;
    font-size: 0;
    margin-left: 5px;
    align-self: center;
    &:after {
      @extend %fi;
      font-size: 13px;
      content: $fi-Attachement;
      color: $font-color-light_grey_purple;
      margin: auto;
      width: 20px;
      height: 20px;
      background-size: 20px;
      padding-top: 3px;
    }
  }
  .no-attachment-icon {
    display: inline-block;
    border: none;
    min-width: 26px;
    min-height: 26px;
    font-size: 0;
    margin-right: 5px;
    align-self: flex-end;
    &:before {
      display: inline-block;
      content: "";
      color: $black;
      margin: auto;
      width: 24px;
      height: 24px;
      background-size: 20px;
    }
  }
  .shared-icon {
    display: inline-block;
    border: none;
    min-width: 24px;
    min-height: 24px;
    font-size: 0;
    margin-left: 5px;
    margin-right: 0px;
    align-self: center;
    margin-top: 5px;
    &:after {
    @extend %fi;
    content: $fi-share-icon;
    font-size: 20px;
    color: #8D82AB;
    margin: auto;
    width: 24px;
    height: 24px;
    background-size: 20px;
    }
  }
  .comment-icon {
    display: inline-block;
    border: none;
    min-width: 24px;
    min-height: 24px;
    font-size: 0;
    margin-left: auto;
    margin-right: 0px;
    align-self: center;
    margin-top: 5px;
    padding-left: 10px; 
    &:after {
    @extend %fi;
    content: $fi-comments;
    font-size: 20px;
    color: #8D82AB;
    margin: auto;
    width: 24px;
    height: 24px;
    background-size: 20px;
    }
  }
  .clone-icon {
    display: inline-block;
    border: none;
    min-width: 24px;
    min-height: 24px;
    font-size: 0;
    margin-left: 5px;
    margin-right: 0px;
    align-self: center;
    margin-top: 5px;
    background: none;
    &:after {
    @extend %fi;
    content: $fi-documents;
    font-size: 20px;
    color: #8D82AB;
    margin: auto;
    width: 24px;
    height: 24px;
    background-size: 20px;
    }
  }
  &.selected-item {
    background: $purple4;
    .attachment-icon,.shared-icon,.edit-icon,.clone-icon,.comment-icon,.flow-icon,.flowgroup-icon {
      &:after {
        color: $white;
      }
    }
  }
  &.activeItem {
    color: $white;
    background: $purple4;
    &:before {
    color: $white;
    }
    .timesheet-icon,.shared-icon {
        &:after {
        color: $white;
    }
    }
    .reorderBtn{
      color: $purple4;
    }
    .expensesheet-icon {
    &:after {
        color: $white;
      }
    }
    .timesheet-icon{
      &:before {
        background: url("../../../../assets/images/apps-icons/timesheet-light.png") no-repeat 98%
        center;
        @extend .common-background-img;
      }
    }
    .expensesheet-icon{
      &:before {
        background: url("../../../../assets/images/apps-icons/expenses-light.png") no-repeat 98%
        center;
        @extend .common-background-img;
      }
    }
  }
//   .timesheet-icon {
//     display: inline-block;
//     border: none;
//     min-width: 20px;
//     min-height: 20px;
//     font-size: 0;
//     align-self: center;
//     &:after {
//     @extend %fi;
//     font-size: 20px;
//     content: $fi-Expenses;
//     color: #8D82AB;
//     margin: auto;
//     width: 20px;
//     height: 20px;
//     background-size: 20px;
//     }
// }
// .expensesheet-icon {
//     display: inline-block;
//     border: none;
//     min-width: 24px;
//     min-height: 24px;
//     font-size: 0;
//     align-self: center;
//     &:after {
//     @extend %fi;
//     content: $fi-Expenses;
//     font-size: 20px;
//     color: #8D82AB;
//     margin: auto;
//     width: 20px;
//     height: 20px;
//     background-size: 20px;
//     }
//   }
.common-nav-icon{
  text-align: center;
  margin: 5px 5px 5px -10px;
  display: flex;
  align-items: center;
  margin-right: 0px;
  background-color: transparent;
}
.common-background-img{
  background-size: contain !important;
  content: "";
  min-height: 100%;
  width: 20px;
  height: 20px;
  margin-left: 15px;
}
.timesheet-icon{
  @extend .common-nav-icon;
  &:before {
    background: url("../../../../assets/images/apps-icons/timesheet-dark.png") no-repeat 98%
    center;
    @extend .common-background-img;
  }
}
.expensesheet-icon{
  @extend .common-nav-icon;
  &:before {
    background: url("../../../../assets/images/apps-icons/expenses-dark.png") no-repeat 98%
    center;
    @extend .common-background-img;
    width: 25px;
  }
}
  &.status-1 {
    border:2px solid $yellow !important;
  }
  &.status-2 {
      border:2px solid $green !important;
  }
  &.status-3 {
      border:2px solid $red !important;
  }
}