@import "../../assets/sass/importFiles";
.emptySkeletonResult {
  font-family: "rubik", Arial, sans-sarif;
  font-size: 15px;
  color: $purple3;
  display: block;
  text-align: center;
  width: 100%;
  margin: 14% auto;
  line-height: 1.2;
  padding: 150px 10px 0;
  background: url(../../assets/images/skeleton.png) no-repeat top center;
  @include breakpoint(screen767) {
    margin: 15px auto;
    align-self: center;
  }
  .button-main {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    justify-content: center;
    button {
      border-radius: 8px;
      border: 1px solid #8e81f5;
      outline: none;
      padding: 8px 14px;
      font-size: 14px;
      color: #fff;
      background-color: #8e81f5;
    }
  }
}
