@import "../../../../assets/sass/importFiles";
.lead-page .mobile-apps {
  .mobileAppsSCPanel {
    color: #3e335e;
    display: flex;
    flex-flow: column;
    >button{
      align-self: center;
    }
    .articleList {
      @extend %clearfix;
      display: flex;
      flex-flow: column;
    }

    .articleItem {
      cursor:pointer;
      display: flex;
      flex-flow: row;
      padding: 20px;
      border: 1px solid #d8d8d8;
      margin-bottom: 10px;

      @include breakpoint(screen1023) {
        padding: 30px 5px;
      }
      @include breakpoint(screen767) {
        width: 100%;
        flex-flow: column;
      }

      >div {
        display: flex;
        flex-flow: column;
        margin-top: 10px;
        h5 {
          font-weight: 100;
          color: #d85726;
          align-self: flex-start !important;
          padding: 0 !important;
          margin: 0 !important;
        }
        h1 {
          font-weight: 100;
          color: #d85726;
          text-align: start !important;
          margin: 10px 0px;
        }
      }
    }

    .articleImg {
      width: 300px;
      margin-right: 20px;
      @include breakpoint(screen767) {
        width: 100%;
        align-self: center;
        margin: 0;
      }
      &.tictell {
        padding: 10px;
        border-radius: 20px;
      }
    }
    .footer{
      margin: 10px 0px;
      display: flex;
      align-items: flex-start;
    }
    span{
      align-self: center;
      color: #d85726;
    }
    .time-icon {
      display: inline-block;
      border: none;
      min-width: 24px;
      min-height: 24px;
      font-size: 0;
      margin-left:10px;
      &:after {
        @extend %fi;
        content: $fi-alarm;
        font-size: 20px;
        color: #d85726;
        margin: auto;
        width: 24px;
        height: 24px;
        background-size: 20px;
      }
    }
    .comment-icon {
      display: inline-block;
      border: none;
      min-width: 24px;
      min-height: 24px;
      font-size: 0;
      margin-left:10px;
      &:after {
        @extend %fi;
        content: $fi-comments;
        font-size: 20px;
        color: #d85726;
        margin: auto;
        width: 24px;
        height: 24px;
        background-size: 20px;
      }
    }
    .user-icon {
      display: inline-block;
      border: none;
      min-width: 24px;
      min-height: 24px;
      font-size: 0;
      &:after {
        @extend %fi;
        content: $fi-user;
        font-size: 20px;
        color: #d85726;
        margin: auto;
        width: 24px;
        height: 24px;
        background-size: 20px;
      }
    }
  }
  .seeMore{
    font-family: "rubik", Arial, sans-sarif;
    background: #04caa7;
    color: #ffffff;
    font-size: 14px;
    padding: 10px;
    display: inline-block;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    outline: none;
    margin-right: 10px;
    transition: 0.3s all;
    text-align: center;
    margin: 20px;
  }
}
