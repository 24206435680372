@import "../../../../assets/sass/importFiles";
.featureCard {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  padding: 25px 30px;
  border-radius: 20px;
  flex: 1;
  margin-bottom: 20px;
  background-color: white;
  margin: 10px;
  max-height: 225px;
  cursor: pointer;
  .heading {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 120%;
    margin-bottom: 0px;
    text-align: center;
    margin-top: 13px;
  }

  .description {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    /* or 187% */
    text-align: center;
  }

  &.active {
    background-color: $lightPurple;
    .description {
      color: white !important;
    }
    .heading {
      color: white !important;
    }
    .logo {
      background: white;
      border-radius: 100%;
    }
  }

  &.activeHorizontal {
    background-color: white;
  }

  &.featureCardHorizontal {
    flex-direction: row;
    margin-bottom: 0px;
    gap: 5px;
    align-items: flex-start;
    margin-bottom: 5px;
    padding: 15px 10px;
    > div {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .description {
        text-align: left;
        font-size: 12px;
        line-height: 25px;
      }
    }
  }

  @media (max-width: 390px) {
    .heading {
      font-size: 18px;
    }
    .description {
      font-size: 10px;
      line-height: 1.5;
    }
    .logo {
      width: 50px;
      height: 50px;
    }
  }
  @media (max-width: 305px) {
    .heading {
      font-size: 14px !important;
    }
  }
}
