@import "../../../assets/sass/importFiles";
.searcher-draft-opportunity-page {
  .alignStart {
    align-items: flex-start;
  }
 
  .round-search {
    width: 100%;
  }
  .column-head {
    .search-list-wrapper {
      flex: 1;
      position: relative;
    }
    .list-wrapper {
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 1;
      left: 0;
      background: $white;
      box-shadow: lighten($color: $black, $amount: 50) 0px 2px 6px 0;
    }
  }
  .shortlists-header {
    font-weight: 500;
    padding: 10px;
    display: block;
    font-size: 16px;
    line-height: 1;
    color: $purple2;
  }
}

.select-sent {
  .ant-select-item-option-content {
    // white-space: unset;
  }
}