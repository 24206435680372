@import "../../../assets/sass/importFiles";
.network-list-component {
  .listLabel {
    margin: 0;
    font-family: $RubikRegular;
    font-size: 14px;
    display: inline;
  }

  .list-item {
    display: flex;
    padding: 15px 36px 15px 15px;
    align-items: center;
  }
  .dashItemImg {
    width: 52px;
    height: 52px;
    @include border-radius(50%); // border: darken($offwhite2, 10) 1px solid;
    overflow: hidden;
    background: $ViewsCnt;
  }

  .dateItem {
    padding-left: 12px;
    font-size: 10px;
    font-family: $RubikRegular;
  } 

  .CompAddress {
    font-weight: normal;
  }

  .CompNameCnt {
    padding: 0 0 0 20px;
    flex: 1;
  }

  .roundImg {
    width: 52px;
    height: 52px;
  }

  .no-dp {
    display: flex;
    justify-content: center;
    align-items: center;
    .roundImg {
      width: 25px;
      height: 25px;
    }
  }
}
