.ant-modal-close-icon {
  position: relative;
  top: -15px;
  right: -11px;
}

.resumeTitle {
  font-size: 18px !important;
  font-family: "Poppins", sans-serif !important;
  word-break: break-all;
  margin-bottom: 0.3rem !important;
  font-weight: 800;
}

.userDetailItem {
  font-size: 14px !important;
  font-family: Poppins !important;
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  img {
    margin-right: 0.5rem;
  }
  span {
    margin: 0 !important;
    line-height: 1;
    color: #000;
  }
}

.personalDetails {
  display: flex;
}

.title {
  font-size: 18px;
  color: #413c3b;
  line-height: 20px;
  font-weight: 600;
  font-family: "Poppins";
  text-transform: capitalize;
}

.basicDetails {
  span {
    color: #413c3b;
    font-size: 14px;
    margin-left: 18px;
  }
}
.resumeButtons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (max-width: 686px) {
  .resumeButtons{
    display: none;
  }
  .title {
    font-size: 20px;
  }
}

@media (max-width: 560px) {
  .userDetailItem {
    font-size: 14px !important;
  }
  .userInfo {
    margin-top: 1rem;
  }
  .personalDetails {
    flex-direction: column;
  }
}

@media (max-width: 462px) {
  .userAvatar {
    width: 75.29px !important;
    height: 75.29px !important;
  }
  .userInfo {
    margin-left: 5px !important;
    .userDetailItem {
      span {
        font-size: 11px;
      }
    }
  }
}

@media (max-width: 330px) {
  .userAvatar {
    width: 55.29px !important;
    height: 55.29px !important;
  }
  .resumeTitle {
    font-size: medium !important;
  }
  .userDetailItem {
    font-size: small !important;
  }
  .title {
    font-size: 18px !important;
  }

  .heading {
    font-size: 14px !important;
  }
}
