@import "../../assets/sass/importFiles";
.disabled {
  opacity: 0.4;
}
.page-wrapper {
  display: flex;
  flex: 1;
  flex-flow: row;
  background: $ViewsCnt;
  height: 100vh;
  overflow: hidden;
  .page-content {
    width: 100%;
    display: flex;
    flex-flow: column;
    flex: 1;
  }
  .views-cnt {
    flex: 1;
    display: flex;
    align-content: flex-start;
    flex-flow: row wrap;
    overflow-x: auto;
    padding: 0px 10px;
    background: #fff;
  }

  #loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1100;
    background: #000;
    opacity: 0.6;
  }
  #loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #3498db;

    -webkit-animation: spin 2s linear infinite !important; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 2s linear infinite !important; /* Chrome, Firefox 16+, IE 10+, Opera */
  }

  #loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #e74c3c;

    -webkit-animation: spin 3s linear infinite !important; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 3s linear infinite !important; /* Chrome, Firefox 16+, IE 10+, Opera */
  }

  #loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #f9c922;

    -webkit-animation: spin 1.5s linear infinite !important; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 1.5s linear infinite !important; /* Chrome, Firefox 16+, IE 10+, Opera */
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(0deg); /* IE 9 */
      transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
    }
    100% {
      -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(360deg); /* IE 9 */
      transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(0deg); /* IE 9 */
      transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
    }
    100% {
      -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(360deg); /* IE 9 */
      transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
    }
  }
}
.page-wrapper.lead-page {
  display: block !important;
  overflow: unset !important;
  .views-cnt {
    display: block !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    overflow: unset !important;
  }
}
