//Font Sizes
$font-size-x-lg: 25px;
$font-size-lg: 15px;
$font-size-md: 12px;
$font-size-sm: 10px;

//Colors
$font-color-light_grey_purple: #8d82ab;

$back-color-light_purple: #9783cf;
$back-color-pale_purple_light: #f1edff;
$back-color-pale_purple_dark: #dfd9f7;

.x-lg-heading {
  font-size: $font-size-x-lg;
  font-weight: 800;
}

.lg-heading {
  font-size: $font-size-lg;
  font-weight: 600;
}

.md-heading {
  font-size: $font-size-md;
  font-weight: 300;
}
