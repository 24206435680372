@import "../../../assets/sass/importFiles";
.userNotification {
  // padding: 10px;

  // .listLi {
  //   border-bottom: 1px solid #ccc;
  //   padding: 5px 35px 5px 10px;
  //   position: relative;
  // }

  .notyList {
    width: 100%;
    .list-item {
      cursor: pointer;
      &:before {
        @extend %fa;
        content: "";

        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
        background: url("../../../assets/images/form/checkbox.png") top left
          no-repeat;
        background-position: -15px 0px;
        vertical-align: top;
        width: 15px;
        height: 15px;
      }
    }
    .notiActive {
      &:before {
        background: url("../../../assets/images/form/checkbox.png") top left
          no-repeat;
        background-position: -15px -15px;
      }
    }
  }
}
