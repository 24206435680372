.location {
  margin-top: 30px;
  // @media (max-width: 768px) {
  //   margin-top: 20px;
  // }
  .locationHead {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 125%;
    color: #413c3b;
    // margin: 30px 0px 20px 0px;
  }
  .addLocation {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // margin-bottom: 10px;

    div {
      background: #f7f6fe;
      border-radius: 8px;
      width: 650px;
      margin-right: 16px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #413c3b;
      opacity: 0.7;
      padding: 17px;

      @media (max-width: 768px) {
        width: 100%;
        height: 48px;
        padding: 13px;
        margin-right: 10px;
      }
    }
  }

  .addLocationButton {
    background: #8f82f5;
    border-radius: 8px;
    padding: 9px 17px;
    margin-left: 10px;
    color: white;
    border: none;
    font-size: 20px;
    cursor: pointer;

    @media (max-width: 768px) {
      padding: 8px 16px;
    }
  }
  .locationOptions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // margin: 10px 0px 10px 0px;
    // @media (max-width: 768px) {
    //   margin-top: 0px;
    // }
  }
  .icon {
    cursor: pointer;
    display: block;

    // @media (max-width: 600px) {
    //   display: none;
    // }
  }
  .textBox {
    padding: 10px;
    width: auto;
    background: #f7f6fe;
    border-radius: 8px;
    padding: 10px;
    height: 50px;
    width: 100%;
    // max-width: 650px;
    display: flex;
    align-items: center;
    margin-right: 16px;
    @media (max-width: 600px) {
      max-width: 100%;
    }
  }
}
