@import "../../assets/sass/importFiles";
.LoginPage {
  background: $darkBlue;
  height: 100vh;
  display: flex;
  flex-flow: column;
  line-height: 1.4;
}

.page-wrapper.lead-page {
  display: block;
  overflow: unset;
  .ige-dialog {
    flex-direction: column;
    .ige-no {
      background: $red;
    }
    .ige-dismiss {
      background: $lightBlue;
    }
  }
  .views-cnt {
    display: block;
    padding-right: 0;
    padding-bottom: 0;
    overflow: unset;
  }
  .joinWrap {
    margin-bottom: 20px;
  }
  .tabList {
    @extend %clearfix;
  }
  .proHeading {
    font-size: 40px;
    text-align: center;
    margin: 10px 0 30px;
    font-family: $RubikRegular;
    font-weight: normal;
    color: $darkBlue;
    margin-top: 50px;
  }
  h6 {
    font-size: 15px;
    text-align: center;
    margin: 20px 0 0px;
    font-family: $RubikRegular;
    font-weight: normal;
    color: $darkBlue;
  }
  .mainContent {
    padding: 0 6% !important;
  }

  .LoginWrap {
    width: 400px;
    position: fixed;
    z-index: 1100;
    top: 90px;
    left: 30px;
    overflow: hidden;
    left: 50%;
    transform: translate(-50%, 0);
    @include breakpoint(screen767) {
      margin: 0 auto;
    }
    @include breakpoint(screen640) {
      width: 90%;
      left: 1%;
      left: 50%;
      -webkit-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
      .LoginForm,
      .TermForm {
        overflow: auto;
        max-height: 98%;
        .login {
          padding: 30px 10px;
        }
      }
    }
    @media screen and (max-height: 700px) {
      .RegisterForm,
      .TermForm {
        height: 500px;
        overflow: auto;
        &.small {
          height: unset !important;
          .LoginForm, .RegisterForm{
            height: unset !important;
          }
        }
      }
    }
  }
  .main {
    flex: 1;
    display: flex;
    flex-flow: column;
    background: $darkBlue;
  }
  .TermForm {
    border-radius: 4px;
    background: $white;
    width: 400;
    position: relative;
    h1 {
      color: $darkBlue;
    }
    .Term {
      overflow-y: scroll;
      height: 400px;
      margin-left: 10px;
    }
    .close-btn {
      right: 2px;
    }
  }
  .RegisterForm {
    .signup-agreement {
      a {
        color: $lightBlue;
      }
    }
    .select-input {
      .Select-control {
        height: 35px;
      }
      .Select-placeholder {
        line-height: 35px;
        text-align: center;
      }
      .Select-value {
        text-align: center;
      }
    }
    .signup-captcha {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .bgUpdate {
    background: #fafafa;
    height: auto;
  }
  .landing-overlay {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    background: rgba($darkBlue, 0.7);
    z-index: 50;
  }

  .tictell-section {
    background: $darkBlue;
    * {
      color: $white !important;
    }
  }
}
