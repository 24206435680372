.new-design-modal {
  .ant-modal-body {
    padding: 0px;
  }
  .header-modal {
    border-bottom: 1px solid #2f29292f;
    padding: 14px 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .close-icon {
    width: 20px;
  }
  .form {
    padding: 20px;
  }
  h5 {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
  }
  .paragraph {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 0;
    padding: 0px 18px;
    color: #000;
  }
  .images-icon {
    text-align: center;
  }
  .ant-form-item {
    margin-bottom: 14px;
  }
  .ant-space {
    width: 100%;
  }
  .ant-space-item {
    width: 100%;
  }
  .ant-picker {
    width: 100%;
  }
}
