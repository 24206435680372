@import "../../../../assets/sass/importFiles";
.resume-grid {
  margin-top: 10px;
  .grid-row {
    display: flex;
    margin-bottom: 10px;
    flex-flow: row wrap;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .grid-column {
    flex: 1;
    display: flex;
    margin-right: 10px;
    .close_btn_purple {
      margin-top: 4px;
    }
    @include breakpoint(screen767) {
      width: 100%;
      margin-right: 0;
      flex: none;
      margin-bottom: 10px;
    }
    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
  .select-experience-item {
    
    display: block;
    width: 100%;
  }
  .select-education-item {
    @extend .select-experience-item;
    margin-right: 10px;
  }
  .experience-row {
    display: flex;
    flex: 1;
    margin-right: 10px;
    @include breakpoint(screen1023) {
      flex: none;
      width: 100%;
      margin-right: 0;
    }
    @include breakpoint(screen767) {
      flex: 1;
      width: auto;
      margin-right: 10px;
    }
    @include breakpoint(screen640) {
      flex: none;
      width: 100%;
      margin-right: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    @media (max-width:330px) {
      flex-direction: column;
    }
  }
  .education-row {
    @extend .experience-row;
  }
  .row-flex-2 {
    flex: 2;
  }
}
