@import "../../../../assets/sass/importFiles";
.saved-search-page {
  .form-editor {
    padding: 10px;
  }


  .form-label {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .name-container {
    align-self: center;
  }
  .button-wrapper {
    align-self: center;
  }
  .clone-icon {
    margin-top: 0px;
    background: none;
    min-width: 20px;
    min-height: 20px;
  }
  .form-editor {
    padding: 10px !important;
  }
  @media (max-width: 767px) {
    .col-1 {
      min-height: 300px;
      &.page-column-collapse {
        min-height: auto;
        margin-top: 50px;
      }
    }
    .col-2,.col-3,.col-4{
      min-height: 650px !important;
      overflow: hidden;
      max-height: 500px !important;
      .column-body{
        overflow: unset;
      }
      &.page-column-collapse {
        min-height: auto !important;
      }
    }
    .col-3,.col-4{
      min-height: 400px !important;
      &.page-column-collapse {
        min-height: auto !important;
      }
    }
  }
}
