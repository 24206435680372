@import "../../../../assets/sass/importFiles";

.headsup-opportunity-page {
  @include breakpoint(screen991) {
    // .page-column{
    //   flex: auto !important;
    //   width: 100%;
    // }
  }
  
  .list-wrapper{
    .list-item{
      .selected-item {
        background: #dfd9f7;
        font-weight: 500;
        color:#ffffff
      }
    }
  }
.headsup-shortlists{
  .tictell-list-item-container{
    height: auto;
    max-height: none;
    label.pool-label {
      white-space: normal;
    }
  }
}
  .tictell-list-item-container{
     .closeBtn {
      position: absolute;
     }
    label.pool-label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-top: 8.5px;
      font-size: 15px !important;
      font-weight: 600 !important;
      margin-bottom: 8.5px;
  }}
  .alignStart {
    align-items: flex-start;
  }
  .column-head {
    .search-list-wrapper {
      flex: 1;
      position: relative;
    }
    .list-wrapper {
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 1;
      left: 0;
      background: $white;
      box-shadow: lighten($color: $black, $amount: 50) 0px 2px 6px 0;
    }
  }
  .shortlists-header {
    font-weight: 500;
    padding: 10px;
    display: block;
    font-size: 16px;
    line-height: 1;
    color: $purple2;
  }
  @media (max-width: 767px) {
    .col-1 {
      min-height: 300px;
      margin-top: 50px;
      &.page-column-collapse {
        min-height: auto;
        margin-top: 50px;
      }
    }
    .col-2,.col-3,.col-4{
      min-height: 650px !important;
      max-height: 500px !important;
      .column-body{
        overflow: unset;
      }
      &.page-column-collapse {
        min-height: auto !important;
      }
    }
    .col-3,.col-4{
      
      &.page-column-collapse {
        min-height: auto !important;
      }
    }
    .col-4{
      min-height: 400px !important;
    }
  }
}
