@media print {
  @page {
    margin: 20px 0px; /* Adjust margin size as needed */
  }
  .printPadding {
    padding: 0px 50px;
  }
  .logoPrint {
    display: block !important;
  }
  .widthNOne {
    min-width: 0 !important;
    margin-left: 7px !important;
    margin-right: 7px !important;
  }
}

.resumePopUp {
  width: 70%;
  height: calc(100% - 70px);
  overflow: auto;
  .form {
    padding: 0;
  }
  .ant-modal-content {
    height: 100%;
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
  .resume-print-main {
    height: 100%;
    .column-first {
      height: 100%;
      padding: 14px;
      overflow: auto;
      .main-profile {
        height: 100%;
      }
    }
    .verticalDivider{
      width: 2px;
      height: 5px;
      border-radius: 63%;
      border-left: 5px solid #8f82f5;
    }
    .column-second {
      border-left: 1px solid #0000000d;
      padding: 14px;
      height: 100%;
      overflow: auto;
    }
  }
  .ant-divider-horizontal {
    margin: 14px 0px !important;
  }
  p {
    margin: 0;
    color: #000;
    font-size: 16px;
  }
  .legends {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-right: 10px;
  }
}

@media (max-width: 769px) {
  .resume-print-main {
    height: unset !important;
    .column-first {
      height: unset !important;
      .main-profile {
        height: unset !important;
      }
    }
  }
}


// /* Keyframes for sliding up and down */
// @keyframes slideUp {
//   from {
//     transform: translateY(100%);
//     opacity: 0;
//   }
//   to {
//     transform: translateY(0);
//     opacity: 1;
//   }
// }

// @keyframes slideDown {
//   from {
//     transform: translateY(0);
//     opacity: 1;
//   }
//   to {
//     transform: translateY(100%);
//     opacity: 0;
//   }
// }

// /* Apply sliding animation when modal opens */
// .custom-slide-modal .ant-modal-content {
//   transform: translateY(100%);
//   animation: slideUp 0.4s ease-out forwards;
// }

// /* Apply sliding down animation when modal closes */
// .custom-slide-modal .ant-modal-content.ant-modal-closing {
//   animation: slideDown 0.4s ease-out forwards;
// }
