.new-company-detail-component {
  height: 100%;
  overflow: auto;
  .inner-company {
    background: #fff;
    border-radius: 12px;
    padding: 16px;
    height: 100%;
    overflow: auto;
    @media (max-width: 767.98px) {
      padding: 12px;
    }
    .image-user {
      text-align: center;
      margin-bottom: 10px;
      img {
        border-radius: 50%;
        object-fit: none;
        width: 170px;
        height: 170px;
        @media (max-width: 767.98px) {
          width: 150px;
          height: 150px;
        }
      }
    }
    .user-title {
      text-align: center;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 27px;
      color: #413c3b;
      margin-top: 6px;
      @media (max-width: 767.98px) {
        font-size: 14px;
        line-height: unset;
      }
    }
    .user-detail {
      margin: 0;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 27px;
      color: #413c3b;
      @media (max-width: 767.98px) {
        font-size: 14px;
        line-height: unset;
      }
    }
  }
  a{
    color: #8E81F5;
  }
}

.clr-gray {
  color: #7d7d7d;
}

.clr-black {
  color: #000;
}
