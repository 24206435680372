@import "../../../../assets/sass/importFiles";

.tictellWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 100px 0px 50px 0px;
  min-height: 100vh;
  background-color: white;
  text-align: center;

  .wrapper {
    width: 40%;

    @media (max-width: 768px) {
      width: 85%;
    }
  }
  .logo {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    img {
      width: 100px;
      height: 100px;
    }
  }
  h1 {
    font-size: 26px;
    font-weight: bold;
    padding-bottom: 30px;
  }
  .errorBadge {
    width: max-content;
    margin: 0px auto;
    padding: 3px 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: rgb(211, 31, 31);
    color: white;
  }
  .inputPass {
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .forget {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    margin-top: 8px;
    align-items: center;
    a {
      color: #398ecd;
      font-weight: 500;
    }

    label {
      span {
        &:first-child > span {
          border: 1px solid #8e9092;
          border-radius: 3px;
        }
      }
    }
    .rememberMeTxt {
      color: #8d93a3;
      font-size: 12px;
    }
  }
  .infoBadge {
    width: 100%;
    margin: 0px auto;
    padding: 7px 10px;
    margin-top: 10px;
    border-radius: 10px;
    background-color: #6b57a6;
    color: white;

    a {
      color: white;
      text-decoration: underline;
      margin: 0px 4px;
    }
  }
  button {
    width: 100%;
    background-color: #398ecd;
    border: none;
    padding: 10px 0px;
    color: white;
    border-radius: 12px;
  }
  .powered {
    padding-top: 50px;
    padding-bottom: 16px;
  }
  .footer {
    img {
      height: 30px;
      width: auto;
    }
  }
}

.customInput {
  .input {
    border: 1.5px solid rgba(142, 144, 146, 0.4);
    border-radius: 12px;
    color: #8e9092;
    &:hover {
      border: 1.5px solid rgba(142, 144, 146, 0.4);
    }
  }
  .inValid {
    border: 1.5px solid red;
    &:hover {
      border: 1.5px solid red;
    }
  }
  svg {
    width: 24px;
    height: 24px;
    color: #5a5c5d;
  }
}
