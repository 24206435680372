@import "../../../../assets/sass/importFiles";
.lead-page .about-page {
  h6,
  h5,
  .proHeading {
    color: $white !important;
  }
  .aboutPanel {
    .tabList {
      display: flex;
      justify-content: center;
      flex-flow: row wrap;
    }
    .tabItem {
      float: left;
      width: 30%;
      padding: 30px 30px;
      text-align: center;
      color: $white;
      line-height: 1;
      min-width: 300px;
      @include breakpoint(screen1023) {
        padding: 30px 5px;
      }
      @include breakpoint(screen767) {
        width: 100%;
      }
    }
    h1,
    h6,
    .proHeading {
      color: white $imp;
    }

    .tabImg {
      max-width: 70%;
      display: block;
      margin: 0 auto;
      border-radius: 50%;
      &.purple {
        border: 3px solid $purple;
      }
      &.orange {
        border: 3px solid $orange;
      }
      &.green {
        border: 3px solid $green;
      }
      @include breakpoint(screen767) {
        width: 150px;
        height: 150px;
      }
    }
  }
}
