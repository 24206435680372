@import "../../../../assets/sass/importFiles";

.slides-component {
  @extend %clearfix;
  width: 96%;
  margin: 0 auto;
  max-width: 1400px;
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 100px;
  @include breakpoint(tablet) {
    width: 96%;
  } //tablet
  .MainView {
    @extend %clearfix;
    display: flex;
    align-items: center;
    width: 100%;
  }
  .slide {
    background: none;
  }
  .slideshow {
    max-width: 50%;
    width: 100%;
    position: relative;
    list-style: none;
    font-family: $RubikMedium;
    overflow: hidden;
    @include breakpoint(screen767) {
      max-width: 100%;
    }

    h1 {
      color: $white;
      font-size: 50px;
      margin: 0;
    }
  }

  .sliderImg {
    max-width: 100%;
    max-height: 100%;
  }

  .text1 {
    text-align: right;
    align-self: center;
    padding-right: 50px;
    @include breakpoint(screen767) {
      text-align: center;
    }
  }

  .join-wrap {
    display: flex;
    justify-content: space-around;
  }

  .SliderText {
    font-family: $RubikRegular;
    font-size: 18px;
    margin-top: 80px;
    color: #fff;
    font-size: 22px;
  }

  .joinBtn {
    font-family: $RubikRegular;
    color: #c1bad6;
    font-size: 14px;
    padding: 12px 65px 12px 20px;
    display: inline-block;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    position: relative;
    outline: none;
    @include transition(0.3s all);
    background: #fff;
    &:hover {
      background: darken(#fff, 10);
    }
    &:before {
      background: #c1bad6;
      content: "";
      width: 1px;
      top: 10px;
      bottom: 10px;
      right: 50px;
      position: absolute;
    }
    &:after {
      width: 27px;
      height: 27px;
      content: "";
      position: absolute;
      right: 12px;
      top: 8px;
      background: url("../../../../assets/images/join.svg") no-repeat;
    }
  }

  .blogBtn {
    font-family: $RubikRegular;
    color: #c1bad6;
    font-size: 14px;
    padding: 12px 65px 12px 20px;
    display: inline-block;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    position: relative;
    outline: none;
    @include transition(0.3s all);
    background: #fff;
    margin-right: 10px;
    &:hover {
      background: darken(#fff, 10);
    }
    &:before {
      background: #c1bad6;
      content: "";
      width: 1px;
      top: 10px;
      bottom: 10px;
      right: 50px;
      position: absolute;
    }
    &:after {
      width: 27px;
      height: 27px;
      content: "";
      position: absolute;
      right: 15px;
      top: 14px;
      background: url("../../../../assets/images/blogger5.png") no-repeat;
    }
    a {
      text-decoration: none;
      color: #c1bad6;
    }
  }

  .sliderImageWrap {
    width: 35%;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
    display: flex;
    align-items: flex-end;
    @include breakpoint(screen767) {
      display: none;
    }
  }
  @include breakpoint(screen640) {
    margin-top: 110px;
  }
}
