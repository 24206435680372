@import "../../assets/sass/importFiles";

.product-menus{
    flex: 1;
    max-width: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include breakpoint(screen767) {
      flex: 1 1;
      max-width:100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      max-height: 50px;
    }
    .nav-button {
        background: transparent;
        width: 100%;
        padding: 8px 10px 8px 8px;
        color: $purple;
        font-size: 15px;
        line-height: 1.2;
        border: none;
        text-align: left;
        align-items: center;
        display: flex;
        flex-flow: row;
        position: relative;
        text-decoration: none;
        white-space: nowrap;
        min-width: 50px;
        max-width: 60px;
        align-self: center;
        &:focus {
          outline: none !important;
        }
        .help-icon {
          background: #94d954;
          height: 15px;
          width: 15px;
          text-align: center;
          border-radius: 50%;
          position: absolute;
          top: 50px;
          right: 5px;
          z-index: 10;
        }
        &:hover,
        &.activeBtn {
          color: #8D77CC;
          i {
            background: #8D77CC;
            &:before{
              color: white;
            }
          }
        }
        @media (max-width:330px){
          min-width: 39px;
        }
      }
}
