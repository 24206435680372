@import "../../assets/sass/importFiles";
.modal-popup {
  font-size: 15px;
  .modal-title {
  }
  .modal-body {
    padding: 20px 0 0;
  }
  .modal-footer {
  }
}
.modal-overlay {
  background: rgba($black, 0.5);
}
.suggestedModal {
  min-width: 800px;
  border-radius: 4px;
  @media (max-width: 768px) {
    min-width: auto;
  }
  .close-button {
    position: absolute;
    right: 3px;
    top: 0;
    width: 16px;
    height: 16px;
    color: #8f82f5;
  }
}
