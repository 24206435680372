@import "../../../assets/sass/importFiles";
.company-form-editor {
  display: flex;
  width: 100%;
  position: relative;
  overflow: auto;
  height: 100%;
  .company-form {
    width: 100%;
  }
  .upload-btn-img{
    color: #8E81F5;
    font-weight:500
  }

  p,
  label {
    color: $black;
  }
  .socialMedia {
    background: $green;
    padding: 7px;
    color: $white;
    text-align: center;
    font-size: 13px;
  }
  .socialCnt {
    text-align: center;
    padding: 5px 0;
  }
  .socialBtn {
    border: none;
    margin: 0 2%;
    background: $purple;
    padding: 2px;
    width: 40px;
    height: 40px;
    @include border-radius(50%);
    margin-bottom: 10px;
  }

  .behanceBtn {
    background-image: url("../../../assets/images/Icons/behance.png");
    background-repeat: no-repeat;
    background-position: center center;
    &.activeBtn {
      background-color: #007bb6;
    }
  }

  .roundeImg {
    max-width: 100%;
    height: 105px;
  }

  .dashItemImg {
    width: 104px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 104px;
    position: relative;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    background: #f4f2fe;
  }
  .no-dp {
    .roundeImg {
      max-width: 40px;
    }
  }
  .input-upload-file {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    padding: 50px;
  }
  .companyBigIcon {
    padding: 10px 0 20px;
    position: relative;
    .closeBtn {
      right: 50%;
      z-index: 1;
      top: 10px;
      margin: 0px -50px 0 0;
    }
  }

  .companyCheckbox {
    padding: 6px 0px;
  }

  .companyCheckboxLabel {
    font-size: 14px;
    cursor: pointer;
  }
  @media (max-width: 330px) {
    .form-row {
      flex-direction: column;
    }
  }
  .inValid{
    border: 1px solid red;
  }
}
