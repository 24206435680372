@import "../../../../../assets/sass/importFiles";

.shortlist-resume-detail-component {
  .AvailableTitle,
  .ResumeHeadingCnt,
  .AvailableList {
    @extend %clearfix;
    position: relative;
  }
  .AvailableList {
    border-radius: 4px 4px 0 0;
    margin-bottom: 10px;
    overflow: hidden;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .AvailableTitle {
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 10px;
    display: flex;
  }
  .ResumeListWrap {
    background: $ViewsCnt;
    font-size: 15px;
  }
  .ResumeList {
    line-height: 1;
    color: $purple4;
    font-family: $RubikRegular;
    background: $white;
  }
  .AvailableHeading {
    display: block;
    text-align: center;
    padding: 13px 0 12px 0;
    background: $green;
    color: $white;
    flex: 1;
  }
  .markRed {
    background: $red;
  }
  .markYellow {
    background: $yellow;
  }
  .markGreen {
    background: $green;
  }
  .NotAvailable {
    background: $red;
  }
  .ResumeHeading {
    display: block;
    text-align: center;
    padding: 15px 40px 15px 50px;
    background: $offwhite2;
    color: $purple2;
    position: relative;
  }
  .DownArrow {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -15px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    padding-top: 5px;
    text-align: center;
    &:after {
      @extend %fa;
      content: $fa-angle-left;
      color: $purple5;
      font-size: 20px;
      box-sizing: border-box;
      display: inline-block;
      @include rotate(270deg);
    }
    &.UpArrow {
      &:after {
        @include transform(rotate(90deg));
      }
    }
  }
  .listCollapse {
    .ResumeList {
      display: none;
    }
    .DownArrow {
      &:after {
        @include transform(rotate(90deg));
      }
    }
  }
  .ResumeItem {
    font-size: 13px;
    @extend %clearfix;

    &:nth-child(even) {
      //background-color: $offwhite4;
    }
  }
  .SkillNumber {
    display: table-cell;
    width: 50px;
    min-width: 50px;
    padding: 10px 0;
    // vertical-align: middle;
    color: $purple4;
    text-align: center;
    border-right: 1px solid #ccc;
  }
  .SkillName {
    display: table-cell;
    padding: 10px 10px;
    width: 100%;
  }
  .YearsCnt {
    display: table-cell;
    min-width: 90px;
    padding: 15px 10px 0 0;
    text-align: right;
  }
  .SkillYears {
  }
  .Experience {
    display: table-cell;
    padding: 15px 10px;
    text-transform: uppercase;
    font-weight: 500;
  }
  .LocLanCnt {
    .ResumeHeading {
      &:before {
        content: none;
      }
    }
    .ResumeList {
    }
    .ResumeItem {
      padding: 10px 10px;
    }
  }
  .LocName {
    position: relative;
    padding: 10px;
    &:before {
      content: $fi-location;
      @extend %fi;
      color: $white;
      width: 23px;
      height: 23px;
      display: inline-block;
      box-sizing: border-box;
      vertical-align: baseline;
      text-align: center;
      padding: 4px 0 0;
      margin-right: 12px;
      border-radius: 50%;
      background: $offwhite2;
      font-size: 13px;
    }
  }
  .LanName {
    position: relative;
    padding: 10px 10px;
    &:before {
      content: $fi-language;
      @extend %fi;
      color: $white;
      width: 23px;
      height: 23px;
      display: inline-block;
      box-sizing: border-box;
      vertical-align: baseline;
      text-align: center;
      padding: 6px 0 0;
      border-radius: 50%;
      margin-right: 12px;
      background: $offwhite2;
      font-size: 12px;
    }
  }
  .LevelCount {
    float: right;

    position: relative;
  }
  .LvlNum {
  }
  .ProfExpCnt {
    .ResumeHeading {
      &:before {
        content: none;
      }
    }

    .ResumeList {
      .ResumeHeadingCnt {
        min-height: 37px;
        position: relative;
        background-color: $offwhite4;
      }
      .DownArrow {
        &:after {
          color: $offwhite4;
        }
      }
    }
  }
  .CompanyName {
    padding: 5px;
    text-align: center;
  }
  .CompanyDate {
    padding: 5px;
    text-align: center;
  }

  .shortlist {
    width: 40px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    &:after {
      content: "";
      display: inline-block;
      @include spriteIcon(shortlist3, $imp);
    }

    &.Active {
      &:after {
        @include spriteIcon(shortlist2, $imp);
      }
    }
  }

  .lblHeading {
    font-size: 15px;
    font-family: $RubikRegular;
    font-weight: 500;
    display: block;
    line-height: 1.2;
    color: $offwhite2;
    padding: 15px 0 0;
    .activeSearch & {
      color: $purple2;
    }
  }
  .collapseableCnt {
    position: absolute;
    right: 0;
    width: 40px;
    top: 0;
    z-index: 1;
    @include breakpoint(screen767) {
      width: 100%;
    }
    > div {
      height: 100%;
      display: block;
      > div {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
        @include breakpoint(screen767) {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
          flex-flow: row wrap;
        }
      }
    }
  }
  .CollapseHeading {
    display: none;
    background: $offwhite2;
    @include border-radius(4px);
    color: $purple4;
    text-align: center;
    padding: 10px 0 0 0;
    font-size: 20px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 10px 0 0;
    @include breakpoint(screen767) {
      min-height: 0;
      width: auto;
      margin: 0 10px 0 0;
      padding-top: 0;
    }
    > div {
      position: relative;
      @include transform(rotateZ(180deg));
      @include writing-mode(vertical-rl);
      width: 40px $imp;
      @include breakpoint(screen767) {
        @include transform(none);
        @include writing-mode(horizontal-tb);
        width: 100% $imp;
      }
      > div {
        vertical-align: middle;
      }
    }
  }
  .CollapseCol {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    width: 40px;
    z-index: 1;
    @include breakpoint(screen767) {
      width: 100%;
      padding: 10px 0 0;
    }
    > div {
      height: 100%;
      > div {
        > div,
        > a {
          display: none;
        }
        .CollapseHeading {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
        }
      }
    }
    &.col-2,
    &.col-3 {
      width: 50px;
      padding: 0 0 0 10px;
      @include breakpoint(screen767) {
        width: 100%;
        padding: 10px 0 0 0;
      }
    }
    .collapseableCnt {
      display: block;
      position: relative;
      height: 100%;
    }
  }
  .mr--50 {
    margin-left: -50px;
  }
  .pl-50 {
    padding-left: 50px $imp;
  }
  .pr-50 {
    padding-right: 50px $imp;
  }

  .info {
    background: $darkBlue;
    padding-top: 3px;
    display: inline-block;
    font-family: sans-serif;
    width: 25px;
    color: white;
    font-weight: 500;
    font-size: 15px;
    height: 25px;
    top: 3px;
    text-align: center;
    @include border-radius(50%);
    position: absolute;
    right: 35px;
    cursor: pointer;
  }
  .savedLabel {
    font-weight: 500;
    font-size: 15px;
  }
  .menuBtn,
  .x-nbr .menuBtn {
    border: none;
    position: absolute;
    right: 7px;
    bottom: 5px;
    width: 60px;
    @include border-radius(50%);
    height: 60px;
    background: $green $imp;
    padding: 0 15px;
    overflow: visible;
    &:hover,
    &.x-btn-pressed.x-btn-focus,
    &.active {
    }
    .x-btn-icon-el {
      @include spriteIcon(globe1, $imp);
      margin: auto;
    }
    .x-btn-button {
      &:before {
        height: 20px;
        @extend %fa;
        content: $fa-arrows;
        width: 20px;
        color: $white;
        position: absolute;
        display: none;
        right: -2px;
        font-size: 10px;
        border-radius: 50%;
        bottom: 2px;
        @include box-shadow(0 0 3px $darkBlue);
        padding-top: 4px;
        box-sizing: border-box;
        background-color: darken($green, 10) $imp;
      }
    }

    &:hover,
    &.x-btn-over {
      .x-btn-button {
        &:before,
        &:after {
          display: block;
        }
      }
    }
    .x-btn-inner {
      display: block;
    }
  }
  .subMenuBtn {
    border: none;
    overflow: visible;
    .x-menu-body {
      background: none;
    }
    .x-menu-body,
    .x-box-inner {
      overflow: visible;
    }
  }
  .resumeProfile {
    padding: 10px 10px;
    background: $white;
    @extend %clearfix;
  }
  .ExpName {
    float: right;
  }
  .DropDown {
    .x-form-trigger-default {
      // background-image: url("../images/ExtIcons/trigger-rtl.png");
    }
  }
  .TotalSkill {
    float: left;
    width: 50px;
    background: $purple2;
    color: $white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
