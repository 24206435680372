@import "../../../assets/sass/importFiles";
.userSettings {
  padding: 10px;
  position: relative;
  &.center-content {
    height: 100%;
    display: flex;
  }
  .textarea-field {
    resize: none;
  }
  .list-group-wrapper {
    width: 100%;
  }
  .form-label {
    min-width: 120px;
  }
  .profile-form {
    width: 100%;
  }

  .input-upload-file {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    padding: 50px;
  }
  .profileBigIcon {
    padding: 10px 0 20px;
    position: relative;
    .closeBtn {
      right: 50%;
      z-index: 1;
      top: 10px;
      margin: 0px -50px 0 0;
    }
  }

  .roundeImg {
    max-width: 100%;
    max-height: 105px;
  }

  .dashItemImg {
    width: 104px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 104px;
    position: relative;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    background: $offwhite2;
  }
  .no-dp {
    .roundeImg {
      max-width: 40px;
    }
  }

  .input-text-disabled {
    width: 100%;
    border: transparent;
    border-radius: 3px;
    outline: none;
    padding: 6px 10px;
    height: 28px;
    font-size: 13px;
    background: #f5f2ff;
    font-family: "rubik", Arial, sans-sarif;
    font-weight: normal;
    color: #9783cf;
    margin-bottom: 10px;
    min-width: 0;
    background: #dddddd;
  }
}
