@import "../../../assets/sass/importFiles";
.message-sent {
  .listLabel {
    margin: 0;
    font-family: $RubikRegular;
    font-size: 14px;
  }
  .SelectedItem {
    font-weight: 500;
  }
}
