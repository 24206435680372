@import "../../../assets/sass/importFiles";
.feedback-list-component {
  .listLabel {
    margin: 0;
    font-family: $RubikRegular;
    font-size: 14px;
    display: inline;
  }

  .dateItem {
    padding-left: 12px;
    font-size: 10px;
    font-family: $RubikRegular;
  }
}
