.empty-card-main {
  margin: 20px 0px;
  .empty-message {
    background: rgba(243, 241, 253, 0.7);
    border-radius: 8px;
    .ant-card-body {
      @media (max-width: 768px) {
        padding: 16px;
      }
    }
    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      color: #161616;
      opacity: 0.7;
      margin: 0;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
}
