@import "../../../../assets/sass/importFiles";
.create-message {
  position: relative;
  .main-form {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  @media (max-width: 768px) {
    padding: 0px;
  }
  .bg-color-for-opportunity{
    border-radius: 8px!important;
  }
  .textarea-field {
    resize: none;
    margin-bottom: 0 !important;
    border-radius: 8px !important;
    padding: 10px !important;
  }
  .collapse-title {
    margin-bottom: 4px;
  }
  .align-right {
    text-align: end;
  }
}
