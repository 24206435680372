@import "../../assets/sass/importFiles";
.cookie-overlay{
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.7);
  z-index: 100;
}
.cookie-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 7px;
  opacity: 1;
  background: $orange;
  text-align: center;
  z-index: 101;
  width: 100%;
  table-layout: fixed;
  @extend %clearfix;
  &::before {
    display: table;
  }
  .cookie {
    width: 100%;
    table-layout: fixed;
  }
  label {
    padding-top: 8px;
    display: inline-block;
    color: $white;
    font-size: 13px;
    line-height: 17px;
    font-family: "Rubik", sans-serif;
  }
  .cookie-button {
    float: right;
    border-radius: 4px;
    background: $green;
    border: none;
    box-shadow: none;
    padding: 7px 15px;
    color: $white;
    max-width: 100%;
  }
}

