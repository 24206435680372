@import "../../assets/sass/importFiles";
.page-header {
  background: $white;
  background-image: url(../../assets/images/bar2.png);
  background-size: 100% 4px;
  height: 46px;
  position: relative;
  background-repeat: no-repeat;
  padding-bottom: 14px;

  .ToggleMenu {
    border: none;
    background: url("../../assets/images/Icons/menu.png") no-repeat 0 0;
    width: 18px;
    margin: 18px 10px 0;
    height: 14px;
    float: left;
  }

  .registerBotton {
    width: 100%;
    max-width: 100px;
    border: none;
    background: #04caa7;
    font-size: 16px;
    font-family: rubik, Arial, sans-sarif;
    color: #fff;
    line-height: 1;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  .user-name {
    float: left;
    height: 24px;
    margin: 14px 0 0 0;
    color: $purple4;
    font-family: $RubikRegular;
    font-size: 12px;
    font-weight: 500;
    line-height: 2;
    padding: 0 5px;
    // @include breakpoint(screen640) {
    //   display: none;
    // }
  }

  @media (max-width: 370px) {
    .user-name {
      display: none;
    }
  }

  .helpBtn {
    float: left;
    background: $ViewsCnt $imp;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    margin: 13px 0 0 12px;
    cursor: pointer;
    font-size: 0;
    &:before {
      @extend %fi;
      content: $fi-helpIcon;
      font-size: 13px;
      color: $purple2;
      align-items: center;
      display: inline-block;
      vertical-align: top;
      margin-top: 5px;
      margin-left: 9px;
    }
    &.helpActive {
      background: $lightGreen $imp;
      &:before {
        color: $white;
      }
    }
  }
  .header-button {
    float: right;
    .help-icon {
      top: 4px;
      right: -11px;
    }
  }

  .setting-button {
    background: $ViewsCnt $imp;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    float: right;
    border: none;
    margin: 13px 0 0 8px;
    border: 1px solid #DCD8FC;
    cursor: pointer;

    padding: 0;
    font-size: 0;
    &:before {
      line-height: 24px;
      margin-top: -2px;
      @extend %fi;
      content: $fi-cog;
      font-size: 15px;
      color: $purple2;
      align-items: center;
      display: inline-block;
      vertical-align: top;
    }
  }
  .logout-button {
    background: $ViewsCnt $imp;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    float: right;
    border: none;
    margin: 13px 0px 0 12px;
    cursor: pointer;
    padding: 0;
    font-size: 0;
    &:before {
      line-height: 24px;
      @extend %fi;
      content: $fi-log-out;
      font-size: 16px;
      color: $purple2;
      align-items: center;
      display: inline-block;
      vertical-align: top;
    }
  }
  .blog-button {
    background: $ViewsCnt $imp;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    float: right;
    border: none;
    display: flex;
    border: 1px solid #DCD8FC;
    justify-content: center;
    margin: 13px 0px 0 12px;
    cursor: pointer;
    padding: 4px;
    // &:after {
    //   width: 18px;
    //   height:18px;
    //   content: "";
    //   position: absolute;
    //   right: -7px;
    //   top: 4px;
    //   background: url("../../assets/images/message1.png") no-repeat;
    // }
    // a {
    //   text-decoration: none;
    //   color: #c1bad6;
    // }
  }
  .header-logo-container {
    display: table-cell;
    height: 100%;
    vertical-align: top;
    float: right;
    margin: 11px 12px 0px 12px;
    .header-logo {
      width: 109px;
      @include breakpoint(screen640) {
        width: 60px;
        margin-top: 6px;
      }
    }
  }
}
//HeaderCnt
@media (max-width: 390px) {
  .page-header {
    .ToggleMenu {
      width: 18px;
      margin: 18px 7px 0;
    }
    .helpBtn {
      width: 20px;
      height: 20px;
      margin: 13px 0 0 8px;
      &:before {
        font-size: 11px;
      }
    }
    .setting-button {
      width: 20px;
      height: 20px;
      margin: 13px 0 0 8px;
      &:before {
        font-size: 12px;
      }
    }
    .logout-button {
      width: 20px;
      height: 20px;
      margin: 13px 0 0 8px;
      &:before {
        font-size: 12px;
      }
    }
    .blog-button {
      width: 20px;
      height: 20px;
      margin: 13px 0 0 8px;
      &:after {
        width: 25px;
        height: 25px;
      }
    }
  }
}

.setting-dropdown{
  min-width: 100px!important;
  top: 46px!important;
  .ant-dropdown-menu{
    border-radius: 8px!important;
  }
}
.clr-red-logout{
  color: #ff6363;
}
.clr-black{
  color:#000
}