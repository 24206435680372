@import "../../assets/sass/importFiles";
.drag-pdf-component {
  display: flex;
  flex-flow: row;
  padding: 10px 10px;
  align-items: stretch;
  position: relative;
  .closeBtn {
    top: 10px;
    margin-top: 0;
    right: 10px;
    z-index: 1;
  }
  .drag-pdf-box {
    flex: 1;
    display: flex;
    flex-flow: column;
    justify-content: center;
    background: lighten($gray, 50%);
    height: 200px;
    position: relative;
    border: $gray2 5px solid;
    align-items: center;
    text-align: center;
    color: $gray2;
    font-family: $RubikRegular;
    font-weight: 500;
    &:before {
      @extend %fa;
      content: $fa-file-pdf-o;
      line-height: 1;
      font-size: 78px;
      color: $gray2;
      margin-bottom: 10px;
      display: block;
    }
    p {
      margin: 0;
    }

    &.active {
      .closeBtn {
        top: 10px;
        margin-top: -90px;
        right: 40px;
        display: block;
        position: absolute;
      }
      &:before {
        display: none;
      }
    }
  }

  .droped-image {
    max-width: 100%;
    max-height: 190px;
  }

  .demo-droppable {
    flex: 2;
    margin-left: 10px;
    display: flex;
    align-items: center;
    @include border-radius(4px);
    border: 3px dashed $ViewsCnt;
    font-size: 15px;
    color: $purple2;
    line-height: 1.2;
    cursor: pointer;
    text-align: center;
    font-family: $RubikRegular;
    overflow: hidden;
    &.drag-accepted {
      border: 3px dashed $green;
    }
    p {
      margin: auto;
    }
  }

  .pdfFormatText {
    font-family: rubik, Arial, sans-sarif;
    font-size: 15px;
    color: $purple2;
    display: block;
    text-align: center;
    line-height: 1.2;
    margin: 3% auto 0;
    padding: 10px;
  }
}
