@import "../../../../assets/sass/importFiles";
@import "../../variables.scss";
.dashboard-view {
  min-height: 100%;
  display: flex;
  flex-flow: row;
  .page-column {
    max-width: 350px;
  }
  .column-1 {
    max-width: 330px;
  }
  @include breakpoint(screen767) {
    flex-flow: column;
    .page-column {
      max-width: 100%;
    }
    .column-2 {
      margin-top: 20px;
    }
  }
  .widgetIcon {
    min-width: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    margin-right: 0px;
    background-color: transparent;
    &:before {
      font-family: "icomoon" !important;
      content: "";
      display: block;
      margin: 0 3px;
      font-size: 25px;
      padding-top: 3px;
      color: $white; // content: $fi-profile;
    }
  }
  .shortlistIcon {
    @extend .widgetIcon;
    &:before {
      @extend %fi;
      content: $fi-Shortlist;
    }
  }
  .savedSearchIcon {
    @extend .widgetIcon;
    &:before {
      @extend %fi;
      content: $fi-Saved-search;
    }
  }
  .opportunityIcon {
    @extend .widgetIcon;
    &:before {
      @extend %fi;
      content: $fi-Opportunity-arrow;
    }
  }
  .widget-content {
    display: flex;
    min-height: 120px;
    color: $font-color-light_grey_purple;
    > div {
      display: flex;
      flex-flow: column;
      min-width: 69px;
      justify-content: start;
      flex: 1;
      align-self: center;
      label {
        text-align: center;
      }
      label:first-child {
        @extend .x-lg-heading;
      }
      label:last-child {
        @extend .lg-heading;
      }
    }
  }
  .collab-widget-content {
    min-height: 120px;
    color: $font-color-light_grey_purple;
    padding-top: 10px;
    > div {
      display: flex;
      justify-content: space-around;
      label:first-child {
        @extend .x-lg-heading;
        max-width: 30px;
        min-width: 30px;
        margin: 0px 10px 0px 5px;
        text-align: center;
      }
      label:last-child {
        @extend .lg-heading;
        flex: 1;
        margin-left: 10px;
        align-self: center;
      }
    }
  }
}
