@import "../../assets/sass/importFiles";

.navigation {
  background: $darkBlue;
  overflow: auto;
  overflow-x: hidden;
  min-width: 230px;
  height: 100vh;
  @include breakpoint(screen1240) {
    width: 48px;
    min-width: 48px;
    z-index: 100;
    box-shadow: 0 0 7px 1px $darkBlue;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
  }

  &.nav-shrink-container {
    width: 48px;
    min-width: 48px;
    flex: none;
    overflow: hidden;
    @include breakpoint(screen1240) {
      min-width: 200px;
      width: 200px;
      overflow: auto;
    }
  }

  .nav-help-icon-container {
    position: relative;
    &.nav-help-icon-shrink-container {
      position: unset;
    }
    .help-icon {
      top: 2px;
      left: 5px;
      right: 0;
    }
  }
  .leftNavIcon {
    @include border-radius(50%);
    min-width: 30px;
    text-align: center;
    height: 30px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    background-color: $purple;
    &:before {
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      display: inline-block;
      margin: 0 auto;
      // @extend %fi;
      font-size: 18px;
      color: $darkBlue; // content: $fi-profile;
    }
  }
  .sub-nav-wrapper {
    padding-left: 30px;
  }

  .arrowCls {
    &:after {
      // @include spriteIcon(down, $imp);
      background: url("../../assets/images/Icons/down.png") no-repeat center
        center;
      width: 8px;
      height: 5px;
      content: "";
      position: absolute;
      right: 15px;
      top: 50%;
      margin-top: -2px;
    }
  }

  .DashboardBtn {
    color: $white $imp;
    background: $green $imp;
    .helpMsg {
      vertical-align: text-bottom;
    }
  }

  .DashboardCompanyBtn {
    background: $green;
  }

  .DashboardFreelanceBtn {
    @extend .DashboardCompanyBtn;
    background: $blue;
    color: $white $imp;
    .dashboardIcon {
      &:before {
        content: $fi-iPro;
        color: $blue;
      }
    }
  }

  .dashboardIcon {
    @extend .leftNavIcon;
    background-color: $white $imp;
    &:before {
      content: $fi-Searcher;
    }
  }

  .searchIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Search;
    }
  }

  .savedSearchIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Saved-search;
    }
  }
  .networkIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Saved-search;
    }
  }

  .shortlistIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Shortlist;
    }
  }
  .messageIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Message;
    }
  }
  .embarkIcon {
    @extend .leftNavIcon;
    background-color: $purple;
    &:before {
      content: $fi-Embark;
    }
  }
  .philipIcon {
    @extend .leftNavIcon;
    background-color: $purple;
    &:before {
      content: $fi-Phillip;
    }
  }
  .createMessageIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Message-plus;
    }
  }

  .inboxMessageIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Message-plus;
    }
  }

  .sentMessageIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Message-arrow;
    }
  }

  .createOpportunityIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Opportunity-plus;
    }
  }

  .sendOpportunityIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Opportunity-arrow;
    }
  }

  .draftOpportunityIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Opportunity-clock;
    }
  }
  .acceptedOpportunityIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Opportunity-check;
    }
  }
  .declinedOpportunityIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Opportunity-minus;
    }
  }
  .collaborationSearcherSentIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Collaboration-arrow;
    }
  }
  .collaborationSearcherCreateIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Collaboration-plus;
    }
  }
  .collaborationSearcherPendingIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Collaboration-clock;
    }
  }
  .collaborationSearcherAcceptedIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Collaboration-check;
    }
  }
  .collaborationSearcherDeclinedIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Collaboration-cross;
    }
  }
  .collaborationDeclineIcon{
    @extend .leftNavIcon;

    &:before {
      content: $fi-Collaboration-cross;
    }
  }
  .collaborationSearcherInActiveIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Collaboration-cross;
    }
  }
  .collaborationNewIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Collaboration-clock;
    }
  }
  .collaborationActiveIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Collaboration-check;
    }
  }
  .collaborationInActiveIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Collaboration-cross;
    }
  }

  .opportunityIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Opportunity;
    }
  }

  .collaborationIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Collaboration;
    }
  }

  .resumeIcon {
    @extend .leftNavIcon;

    &:before {
      font-family: 'Linearicons-Free' !important;
      font-size: 14px;
      content: $fi-resume;
    }
  }

  .companyIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Workplace;
    }
  }
  .presentIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Presentation;
      margin-top: 4px;
    }
  }

  .statisticsIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-statistics;
    }
  }
  .feedbackIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-feedback;
    }
  }

  .settingIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-About;
    }
  }
  .assortIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Assort;
    }
  }

  .snapshotIcon {
    @extend .leftNavIcon;

    &:before {
      font-size: 22px;
      content: $fi-Snapshot-outline;
    }
  }
  .headsupIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Headsup;
    }
  }
  .tictellIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Tictell;
    }
  }
  .sense-icon {
    @extend .leftNavIcon;

    &:before {
      font-size: 22px;
      content: $fi-Snapshot-outline;
    }
  }

  .nextstep-icon {
    @extend .leftNavIcon;

    &:before {
      font-size: 22px;
      content: $fi-Snapshot-outline;
    }
  }
 
}
.navigation::-webkit-scrollbar {
  width: 6px !important;
}

