@import "../../../../../assets/sass/importFiles";

.new-opportunity-detail-ipro {
  height: 100%;
  overflow: auto;
  .inner-user {
    padding: 16px;
    @media (max-width: 767.98px) {
      padding: 12px;
    }
  }
  .title {
    margin: 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: #413c3b;
    @media (max-width: 767.98px) {
      font-size: 14px;
      line-height: unset;
    }
  }
  .add-btn {
    width: 30px;
    border-radius: 50%;
    background: #04caa7;
    outline: none;
    padding: 0;
    height: 30px;
    margin-bottom: 20px;
    border: none;
    align-self: center;
    margin-top: 5px;
    margin-right: 5px;
    align-self: center;
    margin-left: 5px;
    &.disabled-class {
      pointer-events: none;
      cursor: default;
      opacity: 0.5;
      border: 1px solid #9783cf;
      background: #dfd9f7;
      &:before {
        color: #9783cf;
      }
    }
    &:before {
      @extend %fa;
      font-size: 13px;
      content: $fa-pencil;
      color: $white;
      vertical-align: middle;
      @include rotate(-5deg);
      margin: 5px;
      box-sizing: border-box;
    }
    @include breakpoint(screen767) {
      height: 20px;
      width: 20px;
      &:before {
        margin: -4px 1px 0px 1.5px;
        font-size: 15px;
      }
    }
  }
  .start-end {
    .button-wrapper {
      max-width: 40px;
      .help-icon {
        right: 0;
      }
    }
  }

  .invLabelInterest {
    display: inline-block;
  }

  .accepted {
    color: $green;
  }
  .declined {
    color: $red;
  }
  .noAction {
    color: $yellow;
  }

  .opp-accepted {
    border: none $imp;
    padding: 0;
    height: 40px;
    border-radius: 3px;
    background: $lightPurple2 $imp;
    width: 100%;
    color: $white;
    flex: 1;
    margin-bottom: 12px;
    &.active {
      background: $green $imp;
    }
  }
  .opp-declined {
    @extend .opp-accepted;
    &.active {
      background: $red $imp;
    }
  }
  .opp-pending {
    @extend .opp-accepted;
    &.active {
      background: $yellow $imp;
    }
  }

  //new design
  .banner-invite {
    border: 1px solid #04caa7;
    background: #04caa71a;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 12px;
  }

  .banner-details {
    background-color: #fafafa;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 12px;
  }
  p {
    margin: 0px;
  }
  .clr-label {
    color: #7d7d7d;
  }
  .clr-desc {
    color: #000;
  }
  .skill-tag {
    background-color: #f4f2fe;
    border-radius: 4px;
    border: 1px solid #dcd8fc;
    color: #6b61b8;
    width: fit-content;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    padding: 8px;
  }
  .skills-btn{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
}

.btn-footer-opp {
  display: flex;
  padding: 16px;
  justify-content: flex-end;
  width: 100%;
  gap: 16px;
  border-top: 1px solid rgb(244, 242, 254);
  @media (max-width: 767.98px) {
    padding: 12px;
  }
  .btn-delete {
    border-radius: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    outline: none;
    padding: 6px 14px;
  }
}
.clr-purple {
  background: #8f82f5;
  border: 1px solid #8f82f5;
}
.clr-red {
  background-color: #f54949;
  border: 1px solid #f54949;
}
