@import "../../assets/sass/importFiles";
.app-container {
  display: flex;
  .app-view-container {
    flex: 1;
    display: flex;
    flex-flow: column;
    background: $ViewsCnt;
    height: 100vh;
    @include breakpoint(screen1240) {
      margin-left: 48px;
      width: calc(100% - 48px);
    }
  }
}
