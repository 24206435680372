// This file contains all css classes, those are used on more than one views, or
//
// -----------  COMMON CLASSES STARTS ----------

*,
*:before,
*:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
@media (max-width: 560px) {
  .react-multi-carousel-list {
    .react-multiple-carousel__arrow--right {
      right: calc(0% + -20px) !important;
    }
    .react-multiple-carousel__arrow--left {
      left: calc(0% + -20px) !important;
    }
  }
}

.clearfix:after {
  clear: both;
}

html,
body {
  -ms-overflow-style: scrollbar; //IE scrollbar issue fix
}
body {
  font-family: $RubikRegular;
  font-size: 15px;
  background: #f1edff;
  word-break: break-word;
  width: 100%;
}
#root {
  width: 100%;
}

.react-multiple-carousel__arrow {
  // background: #8f82f5 !important;
  min-width: 30px !important;
  min-height: 30px !important;
  opacity: 0.8 !important;
  // border: 1px solid #8f82f5;
}
.react-multiple-carousel__arrow--right {
  right: calc(0% + 1px) !important;
}
.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px) !important;
}
.preLoader {
  background: $darkBlue;
  background: #3e335e;
  text-align: center;
  height: 100vh;
  padding-top: 100px;
  margin: 0px;
}
a {
  cursor: pointer;
}
.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-center {
  margin: 0 auto;
}

// .float-center{margin:0 auto;}
// .align-left{text-align: left $imp;}
// .align-right{text-align: right $imp;}
.align-center {
  text-align: center;
}

.leftAlign {
  text-align: left $imp;
}

.posRelative {
  position: relative;
}

.hidden {
  display: none $imp;
}

.fullwidth {
  width: 100% $imp;
}

.inlineBlock {
  display: inline-block;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

strong,
b {
  font-family: $RubikRegular;
  font-weight: 500;
}
button {
  position: relative;
  &:focus {
    outline: none;
  }
}
// .desktopOnly{
//  display:block;
//  @include breakpoint(screen1023) { display: none $imp; }
// }//desktopOnly
// .tabletOnly{
//  display:none;
//  @include breakpoint(screen1023) { display: block $imp; }
// }
// Font Css Start
@import url("https://fonts.googleapis.com/css?family=Rubik:400,500,700");
// @font-face {
//     font-family: 'rubikregular';
//     src: url('../fonts/rubik-regular-webfont.woff2') format('woff2'), url('../fonts/rubik-regular-webfont.woff') format('woff'), url('../fonts/Rubik-Regular.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'rubikbold';
//     src: url('../fonts/rubik-bold-webfont.woff2') format('woff2'), url('../fonts/rubik-bold-webfont.woff') format('woff'), url('../fonts/Rubik-Bold.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'rubikmedium';
//     src: url('../fonts/rubik-medium-webfont.woff2') format('woff2'), url('../fonts/rubik-medium-webfont.woff') format('woff'), url('../fonts/Rubik-Medium.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }
// @font-face {
//   font-family: "BebasNeueBold";
//   src: url("../fonts/BebasNeueBold.eot?#iefix") format("embedded-opentype"),
//     url("../fonts/BebasNeueBold.woff") format("woff"),
//     url("../fonts/BebasNeueBold.ttf") format("truetype"),
//     url("../fonts/BebasNeueBold.svg#BebasNeueBold") format("svg");
// }

// @font-face {
//   font-family: "BebasNeueRegular";
//   src: url("../fonts/BebasNeueRegular.eot?#iefix") format("embedded-opentype"),
//     url("../fonts/BebasNeueRegular.woff") format("woff"),
//     url("../fonts/BebasNeueRegular.woff2") format("woff"),
//     url("../fonts/BebasNeueRegular.ttf") format("truetype"),
//     url("../fonts/BebasNeueRegular.svg#BebasNeueRegular") format("svg");
// }

@font-face {
  font-family: "Linearicons-Free";
  src: url("../fonts/Linearicons-Free.eot?");
  src: url("../fonts/Linearicons-Free.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Linearicons-Free.ttf?") format("truetype"),
    url("../fonts/Linearicons-Free.woff?") format("woff"),
    url("../fonts/Linearicons-Free.svg?#Linearicons-Free") format("svg");
  font-weight: normal;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css?family=Rubik:400,500,700");
.fi {
  @extend %fi;
}

// .BebasRegular {
//   font-family: BebasNeueRegular, Arial, sans-sarif;
//   font-weight: normal;
//   font-style: normal; // letter-spacing: 1px;
// }

.fontRegular {
  font-family: sans-serif;
  font-weight: 400;
}

.fontMedium {
  font-family: sans-serif;
  font-weight: 500;
}

.fontBold {
  font-family: sans-serif;
  font-weight: 700;
}

.calibri {
  font-family: Calibri, sans-sarif;
  font-weight: normal;
  font-style: normal;
}

label {
  cursor: inherit;
}
p {
  margin: 0;
}
//Font awesome Icons start
// .fa {
//  display: inline-block;
//  font-family: FontAwesome;
//  font-style: normal;
//  font-weight: normal;
//  line-height: 1;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//  text-decoration: none;
// }
// .fa-user-panel:before{
//  @extend .fa;
//  content:'/0fx';
//  font-size: 20px;
//  color:white;
// }//fa-user:before
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.close_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  .fa-times-circle {
    background-color: #9783cf;
    color: $white;
    font-size: 20px;
    width: 16px;
    border-radius: 50%;
    margin-right: 2px;
  }
  @include transition(0.3s all);
  &:hover,
  &.activeBtn {
    .fa-times-circle {
      background: $white;
      color: $red;
    }
  }
}

.close_btn_purple {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  padding-top: 0.5rem;
  .fa-times-circle {
    color: #9783cf;
    font-size: 20px;
    width: 16px;
    border-radius: 50%;
    margin-right: 2px;
  }
  @include transition(0.3s all);
  &:hover,
  &.activeBtn {
    .fa-times-circle {
      color: $red;
    }
  }
  &.for-list {
    display: inline-block;
    padding: 0;
    right: 0px;
    position: absolute;
    top: 50%;
    margin-top: -8px;
    right: 6px;
  }

  &.for-capsule {
    position: relative;
    display: inline-block;
    right: 2px;
    margin-top: 1px;
    top: 9px;
    margin: 4px 0px 4px 10px;
    padding: 0;
  }
}

// -----------  COMMON CLASSES ENDS ----------
$ViewsWidth: calc-percent(835, 1025);
// .ViewsContainer{
// float:left;
// width: $ViewsWidth;
// @include breakpoint(screen1023) {
// width: 100%;
// }
// }
.LRCnt {
  // background: $white; // @include box-shadow(rgba(0,
  // 0,
  // 0,
  // .14) 1px 2px 2px);
  width: 85%;
  float: right;
  @include breakpoint(screen1240) {
    float: left;
    width: 100%;
    padding-left: 50px;
  }
}
// .ViewName{
// font-size: 21px;
// }
// .PushCnt{
// display: inline-block;
// width: 100%;
// }
// FORM LABEL AND FIELD
// .generalLabel{
// color:$gray;
// padding:11px 10px;
// font-size: 15px;
// border-right:white 10px solid;
// }
// .generalField{
// //border:$LightGrey 1px solid;
// height:36px $imp;
// padding:0 5px;
// font-size: 15px;
// color:$gray;
// }
.x-component {
  color: $purple;
}
// .Notification {
//   @extend .noselect;
// }
.notifClose {
  position: absolute;
  top: 10px;
  right: 16px;
}
.ViewsCnt {
  background-color: $ViewsCnt;
}
.StatsMonths {
  float: left;
  // font-size: 16px;
}
.StatsItem {
  @extend .clearfix;
  border-bottom: 1px solid #ccc;
  margin-top: 10px;
  line-height: 1.2;
  font-size: 14px;
  position: relative;
  padding-right: 40px;
}
.upStats {
  position: absolute;
  top: 0;
  right: 0;
  color: $green;
  // font-size: 18px;
  &:after {
    @extend .fa;
    content: $up;
    font-size: inherit;
    margin-left: 5px;
  }
}
.downStats {
  position: absolute;
  top: 0;
  right: 0;
  color: $red;
  // font-size: 18px;
  &:after {
    @extend .fa;
    content: $down;
    font-size: inherit;
    margin-left: 5px;
  }
}

.pointer {
  cursor: pointer;
}
// for grid only
.removeCls {
  padding: 5px 5px;
  text-align: center;
  .x-grid-cell-inner {
    display: inline-block;
    border: none;
    background: $red $imp;
    @include border-radius(50%);
    width: 25px;
    height: 25px;
    position: relative;
    padding: 8px 0 0 0;
    cursor: pointer;
    @include transition(0.3s all);
    &:hover,
    &.activeBtn {
      background: darken($red, 10) $imp;
    }
    &:before {
      position: absolute;
      content: "";
      left: 0;
      width: 10px;
      margin: auto;
      right: 0;
      @include spriteIcon(cross, $imp);
    }
  }
  .x-action-col-icon {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
}

///pace js
.pace .pace-progress {
  background: $green;
}
// Form Notifications start
.fa-close {
  cursor: pointer;
  &:before {
    @extend .fa;
    content: $fa-remove;
    font-size: 15px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}
.NotificationBox {
  padding: 22px 0 15px;
  margin: 10px 0;
  min-height: 60px;
  .icon {
    &:before {
      @extend .fa;
      line-height: 1;
      font-size: 40px;
      color: white;
      width: 60px;
      height: 42px;
      position: absolute;
      left: 0;
      top: 0;
      padding: 9px 0 0 10px;
    }
  }
  &.success {
    background-color: lighten($success, 15%);
    border: $success 1px solid;
    color: white;
    .fa-close:before {
      color: white;
    }
    .icon:before {
      content: $fa-check;
    }
  }
  &.information {
    background-color: lighten($info, 17%);
    border: $info 1px solid;
    color: white;
    .fa-close:before {
      color: white;
    }
    .icon:before {
      content: $fa-question;
    }
  }
  &.warning {
    background-color: lighten($warning, 20%);
    border: $warning 1px solid;
    color: white;
    .fa-close:before {
      color: white;
    }
    .icon:before {
      content: $fa-warning;
    }
  }
  &.error {
    background-color: lighten($error, 10%);
    border: $error 1px solid;
    color: white;
    .fa-close:before {
      color: white;
    }
    .icon:before {
      content: $fa-times;
    }
  }
  ul {
    margin: 0 30px 0 70px;
    position: relative;
  }
}
// Form Notifications ends
input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
textarea {
  @include input-placeholder {
    color: $purple3;
  }
}
.noPadding {
  padding: 0 $imp;
}

.mScrollr {
  overflow: auto;
}
.flex-wrap {
  > div {
    > div {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
    }
  }
}
.flex-col-1 {
  flex: 1;
}
.flex-col-2 {
  flex: 2;
}
.flex-col-3 {
  flex: 3;
}
.flex {
  display: flex;
}
// .noty_buttons {
//   background: none $imp;
//   border: none $imp;
//   .btn {
//     color: $white;
//     min-width: 70px;
//     background: $green;
//     border: none;
//     padding: 6px 7px;
//     border-radius: 3px;
//   }
// }
// .noty_type_information {
//   background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAoCAYAAAAPOoFWAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAPZJREFUeNq81tsOgjAMANB2ov7/7ypaN7IlIwi9rGuT8QSc9EIDAsAznxvY4pXPKr05RUE5MEVB+TyWfCEl9LZApYopCmo9C4FKSMtYoI8Bwv79aQJU4l6hXXCZrQbokJEksxHo9KMOgc6w1atHXM8K9DVC7FQnJ0i8iK3QooGgbnyKgMDygBWyYFZoqx4qS27KqLZJjA1D0jK6QJcYEQEiWv9PGkTsbqxQ8oT+ZtZB6AkdsJnQDnMoHXHLGKOgDYuCWmYhEERCI5gaamW0bnHdA3k2ltlIN+2qKRyCND0bhqSYCyTB3CAOc4WusBEIpkeBuPgJMAAX8Hs1NfqHRgAAAABJRU5ErkJggg==")
//     left top repeat-x scroll;
//   background-color: $blue;
//   display: flex;
//   .noty_message {
//     flex: 1;
//     color: #fff;
//   }
// }

// .noty_type_error {
//   display: flex;
//   .noty_message {
//     background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAoCAYAAAAPOoFWAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAPZJREFUeNq81tsOgjAMANB2ov7/7ypaN7IlIwi9rGuT8QSc9EIDAsAznxvY4pXPKr05RUE5MEVB+TyWfCEl9LZApYopCmo9C4FKSMtYoI8Bwv79aQJU4l6hXXCZrQbokJEksxHo9KMOgc6w1atHXM8K9DVC7FQnJ0i8iK3QooGgbnyKgMDygBWyYFZoqx4qS27KqLZJjA1D0jK6QJcYEQEiWv9PGkTsbqxQ8oT+ZtZB6AkdsJnQDnMoHXHLGKOgDYuCWmYhEERCI5gaamW0bnHdA3k2ltlIN+2qKRyCND0bhqSYCyTB3CAOc4WusBEIpkeBuPgJMAAX8Hs1NfqHRgAAAABJRU5ErkJggg==")
//       left top repeat-x scroll red;
//     flex: 1;
//     padding-top: 12px !important;
//     align-self: center;
//     color: #fff;
//   }
// }
// //TRANSPARENT BACKGROUND ONLY ENDS
// //Disable user selection for text
// .noselect {
//   -webkit-touch-callout: none;
//   -webkit-user-select: none;
//   -khtml-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
// }

////////////////////////////buttons
button,
a {
  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }
}
.AddBtn {
  width: 40px;
  @include border-radius(3px);
  background: $green;
  outline: none;
  padding: 0;
  height: 40px;
  margin-left: 10px;
  border: none;
  &:before {
    @extend %fi;
    font-size: 20px;
    content: $fi-close;
    color: darken($green, 20);
    vertical-align: middle;
    @include rotate(45deg);
    margin: -1px 0px 0 1px;
    box-sizing: border-box;
  }
}
.EditBtn {
  width: 40px;
  @include border-radius(3px);
  background: $green;
  outline: none;
  padding: 0;
  height: 40px;
  margin-left: 10px;
  border: none;
  &:before {
    @extend %fi;
    font-size: 18px;
    content: $fi-edit1;
    color: darken($green, 20);
    vertical-align: middle;
    margin-top: -1px;
    box-sizing: border-box;
  }
}

.uploadBtn {
  position: absolute;
  top: 84px;
  @include border-radius(50%);
  text-align: center;
  margin-left: 15px;
  left: 51%;
  background: center no-repeat $purple2;
  width: 30px;
  height: 30px;
  padding: 4px 0 0 0px;
  border: none;
  height: 30px;
  z-index: 1;
  width: 30px;
  cursor: pointer;
  overflow: hidden;
  background-image: url("../../assets/images/Icons/upload.png");
}

.collapseBtn {
  height: 40px;
  width: 40px;
  background: $purple2;
  border-radius: 3px;
  border: none;
  margin-left: 5px;
  font-size: 0;
  &:before {
    @extend %fa;
    color: darken($purple2, 20);
    //margin-left: -3px;
    //line-height: 1;
    content: $fa-angle-left;
    font-size: 23px;
    @include border-radius(50%);
    box-sizing: border-box;
    display: inline-block;
    //margin-bottom: 7px;
    @include breakpoint(screen767) {
      @include rotate(-90deg);
    }
  }
  &.expandBtn {
    margin-left: 0;
    @include breakpoint(screen767) {
      order: 1;
    }
    &:before {
      @include rotate(180deg);
      margin-left: 2px;
      margin-top: 3px;
      @include breakpoint(screen767) {
        @include rotate(90deg);
      }
    }
  }
}
.closeBtn {
  display: inline-block;
  border: none;
  background: #f1edff $imp;
  @include border-radius(50%);
  min-width: 20px;
  min-height: 20px;
  margin: 9px 0px 9px 10px;
  cursor: pointer;
  padding: 0;
  @include transition(0.3s all);
  box-shadow: none;
  right: 0px;
  font-size: 0;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  margin-top: -13px;
  right: 6px;
  font-size: 0;
  &:hover,
  &.activeBtn {
    background: $red $imp;
    color: $red $imp;
    &:before {
      color: $white;
    }
  }
  &:before {
    @extend %fi;
    font-size: 15px;
    content: $fi-close;
    color: #9783cf;
    vertical-align: middle;
    margin: 0px 0px 3px 0px;
    box-sizing: border-box;
  }
  .close-icon {
    color: #9783cf;
    font-size: 15px;
    padding-bottom: 1px;
  }
}
.SendBtn {
  border: none;
  background: $green;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  @include border-radius(3px);
  padding: 0;
  position: relative;

  &:before {
    @extend %fi;
    font-size: 20px;
    content: $fi-send;
    line-height: 1.1;
    vertical-align: middle;
    color: darken($green, 20);
  }
}

.SendButton {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  border: none;
  display: block;
  background: $green;
  padding: 12px;
  margin-top: 20px;
  border-radius: 3px;
  font-size: 16px;
  font-family: $RubikRegular; // @extend .bebas500;
  color: $white;
  // color: darken($green, 20);
  line-height: 1;
  transition: 0.3s all;
  &:hover {
    background: darken($green, 5);
  }
}
.ReplyBtn {
  border: none;
  background: $green;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  @include border-radius(3px);
  padding: 0;

  &:before {
    @extend .fi;
    font-size: 18px;
    content: $fi-reply;
    vertical-align: middle;
    color: darken($green, 20);
  }
}

.visitRequestBtn {
  border: none;
  background: $green;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  @include border-radius(3px);
  padding: 0;
  &:before {
    @extend .fi;
    font-size: 13px;
    content: $fi-visit-request;
    vertical-align: middle;
    color: darken($green, 20);
  }
}
.SaveCloudBtn {
  border: none;
  background: $green;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  @include border-radius(3px);
  padding: 0;
  position: relative;

  &:before {
    @extend %fi;
    font-size: 20px;
    content: $fi-saveCloud;
    line-height: 1.1;
    vertical-align: middle;
    color: darken($green, 20);
  }
}
.HeaderCloseBtn {
  border: none;
  background: $green;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  @include border-radius(3px);
  padding: 0;
  position: relative;

  &:before {
    @extend %fi;
    font-size: 20px;
    content: $fi-close;
    line-height: 1.1;
    vertical-align: middle;
    color: darken($green, 20);
  }
}
.topShortlistBtn {
  border: none;
  margin-left: 10px;
  z-index: 1;
  background: $green $imp;
  height: 40px;
  width: 40px;
  @include border-radius(3px);
  overflow: visible;
  padding: 0;
  &:before {
    @extend %Im;
    color: darken($green, 20);
    content: $fi-Shortlist-plus;
    font-size: 20px;
    line-height: 40px;
  }
}
.create-opportunity {
  border: none;
  background: $green;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  @include border-radius(3px);
  padding: 0;
  position: relative;

  &:before {
    @extend %Im;
    font-size: 20px;
    content: $fi-Opportunity-plus;
    // line-height: 1.1;
    // margin-top: -2px;
    vertical-align: middle;
    color: darken($green, 20);
  }
}
.ant-divider-horizontal {
  margin: 4px 0px !important;
}

.leftNavIcon {
  @include border-radius(50%);
  padding: 0;
  height: 27px;
  width: 27px;
  border: none;
  border-radius: 50%;
  background: $purple4;
  text-align: center;
  &:before {
    content: "";
    display: block;
    margin: 0 auto;
    @extend %fi;
    font-size: 13px;
    color: $purple2;
  }
  &.activeBtn {
    background: $purple4;
  }
}
.dropdownIcons {
  padding: 0;
  height: 16px;
  width: 16px;
  border: none;
  margin: 12px 0px 0px 10px;
  &:before {
    content: "";
    display: block;
    margin: 0 auto;
    @extend %fi;
    font-size: 16px;
    color: $purple2;
  }
}

.profileIcon {
  @extend .leftNavIcon;
  background-color: $purple3;
  &:before {
    content: $fi-profile;
    color: $purple5;
  }
}
.profileIconResume {
  @extend .dropdownIcons;
  background-color: $offwhite2;
  &:before {
    content: $fi-profile;
    color: $purple5;
  }
}
.certificationIconResume {
  @extend .dropdownIcons;
  background-color: $offwhite2;
  &:before {
    content: $fi-certification;
    color: $purple5;
  }
}
.experienceIconResume {
  @extend .dropdownIcons;
  background-color: $offwhite2;
  &:before {
    content: $fi-documents;
    color: $purple5;
  }
}
.workshopIconResume {
  @extend .dropdownIcons;
  background-color: $offwhite2;
  &:before {
    content: $fi-linegraph;
    color: $purple5;
  }
}

.skillIconResume {
  @extend .dropdownIcons;

  &:before {
    content: $fi-skill;
    color: $purple5;
    font-size: 16px;
  }
}
.profileIconResume {
  @extend .dropdownIcons;

  &:before {
    content: $fi-profile;
    color: $purple5;
    font-size: 16px;
  }
}

.skillIcon {
  @extend .leftNavIcon;
  background-color: $purple3;

  &:before {
    content: $fi-skill;
    color: $purple5;
  }
}

.keywordIcon {
  @extend .leftNavIcon;
  background-color: $purple3;
  &:before {
    content: $fi-keyIcon;
    color: $purple5;
  }
}
.keywordIconResume {
  @extend .dropdownIcons;
  background-color: $offwhite2;
  &:before {
    content: $fi-keyIcon;
    color: $purple5;
  }
}

.companyIcon {
  @extend .leftNavIcon;
  background-color: $purple3;
  &:before {
    content: $fi-industry;
    color: $purple5;
  }
}
.companyIconResume {
  @extend .dropdownIcons;
  background-color: $offwhite2;
  &:before {
    content: $fi-industry;
    color: $purple5;
  }
}

.educationIcon {
  @extend .leftNavIcon;
  background-color: $purple3;
  &:before {
    content: $fi-certification;
    color: $purple5;
  }
}
.educationIconResume {
  @extend .dropdownIcons;
  background-color: $offwhite2;
  &:before {
    content: $fi-certification;
    color: $purple5;
  }
}

.countryIcon {
  @extend .leftNavIcon;
  background-color: $purple3;
  &:before {
    content: $fi-location;
    color: $purple5;
  }
}
.countryIconResume {
  @extend .dropdownIcons;
  background-color: $offwhite2;
  &:before {
    content: $fi-location;
    color: $purple5;
  }
}

.languageIcon {
  @extend .leftNavIcon;
  background-color: $purple3;

  &:before {
    content: $fi-language;
    color: $purple5;
  }
}
.languageIconResume {
  @extend .dropdownIcons;
  background-color: $offwhite2;

  &:before {
    content: $fi-language;
    color: $purple5;
  }
}

///////////help message

.help-icon {
  background: $lightGreen;
  height: 15px;
  width: 15px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 0;
  &:before {
    content: $fi-helpIcon;
    @extend .fi;
    font-size: 8px;
    display: inline-block;
    vertical-align: top;
    margin-top: 3px;
    color: $white;
  }
}

////////////////////// heading
.heading {
  font-size: 15px;
  color: $purple2;
  display: flex;
  flex: 1;
  display: block;
  background: $offwhite2;
  padding: 8px 0px 5px 10px;
  border-radius: 3px;
}
//////////////////search
.round-search {
  width: 100%;
  background: #f1edff;
  border: 1px solid #9783cf;
  padding: 8px 12px;
  @include border-radius(3px);
  // border: none;
  flex: 1;
  height: 40px;
  line-height: 40px;
  outline: none;
  font-size: 13px;
  @include input-placeholder {
    color: $purple3;
  }
}
//////////checkbox
.input-checkbox[type="checkbox"] {
  -webkit-appearance: button;
  cursor: pointer;
  float: none;
  margin-top: 1px;
  width: 15px;
  height: 15px;
  background: url("../images/form/checkbox.png") top left no-repeat;
  background-position: -15px 0px;
  margin: 0;
  vertical-align: middle;
  margin-right: 5px;
}
.checked[type="checkbox"] {
  -webkit-appearance: button;
  cursor: pointer;
  float: none;
  margin-top: 1px;
  width: 15px;
  height: 15px;
  background: url("../images/form/checkbox.png") top left no-repeat;
  background-position: -15px -15px;
  margin: 0;
  vertical-align: middle;
  margin-right: 5px;
}
/////checkbox end
////////form field css
.input-text {
  width: 100%;
  border: transparent;
  border-radius: 3px;
  outline: none;
  padding: 6px 10px;
  height: 30px;
  font-size: 13px;
  background: $offwhite;
  font-family: $RubikRegular;
  font-weight: normal;
  color: $purple2;
  margin-bottom: 10px;
  min-width: 0;
  &.inValid {
    border: 1px solid $red;
  }
}
.textarea-field {
  min-width: 0;
  width: 100%;
  outline: none;
  font-size: 13px;
  background: $offwhite;
  color: $purple2;
  padding: 6px 10px;
  min-height: 85px;
  border: transparent;
  border-radius: 3px;
  resize: vertical;
  margin-bottom: 10px;
  &.inValid {
    border: 1px solid $red;
  }
}
.NewResumeMultiCarousel {
  .react-multi-carousel-list {
    width: 100%;
    // li {
    //   width: 121px !important;
    // }
  }
}
.newExperienceEditSelect {
  display: flex;
  align-items: center;
  .Select {
    margin: 0px !important;
    max-width: 650px;
    width: 100%;
    background: #f7f6fe !important;
    border-radius: 8px;
    padding: 8px;
    outline: none;
    .Select-value {
      background: #f7f6fe !important;
    }
    .Select-control {
      background: #f7f6fe !important;
      border: none;
      font-size: 12px;
      font-family: Poppins;
      color: #413c3b;
      //padding-left: 10px;
      outline: none;
      @media (max-width: 768px) {
        font-size: 12px;
      }
      .Select-value-label {
        color: #aaa !important;
      }
      .Select-placeholder {
        //background: #f7f6fe;
        outline: none;
      }
      &:hover {
        box-shadow: none;
        outline: none;
      }
    }
  }
}
.EducationEditSelect {
  display: flex;
  align-items: center;
  width: 100%;
  .Select {
    margin: 0px;
    max-width: 100%;
    width: 100%;
    background: #f7f6fe !important;
    border-radius: 8px;
    padding: 10px;
    outline: none;
    word-break: keep-all;
    .Select-control {
      background: #f7f6fe !important;
      border: none;
      font-size: 12px;
      font-family: Poppins;
      color: #413c3b;
      height: unset !important;
      //padding-left: 10px;
      outline: none;
      @media (max-width: 768px) {
        font-size: 12px;
      }
      .Select-value-label {
        color: #413c3b !important;
      }
      .Select-placeholder {
        //background: #f7f6fe;
        outline: none;
      }
      &:hover {
        box-shadow: none;
        outline: none;
      }
    }
  }
}

.newResumeEditSelect,
.newExperienceEditSelect,
.newLocationSelect {
  display: flex;
  align-items: center;
  .Select {
    margin: 10px 16px 10px 0px;
    max-width: 650px;
    width: 100%;
    background: #f7f6fe !important;
    border-radius: 8px;
    padding: 8px;
    outline: none;

    .Select-control {
      background: #f7f6fe !important;
      border: none;
      font-size: 12px;
      font-family: Poppins;
      color: #413c3b;
      //padding-left: 10px;
      outline: none;
      @media (max-width: 768px) {
        font-size: 12px;
      }
      .Select-value-label {
        color: #aaa !important;
      }
      .Select-placeholder {
        //background: #f7f6fe;
        outline: none;
      }
      &:hover {
        box-shadow: none;
        outline: none;
      }
    }
  }
}
.newResumeEduValue {
  .Select {
    .Select-control {
      .Select-value-label {
        color: #413c3b !important;
        font-size: 12px;
      }
      .Select-placeholder {
        color: #413c3b !important;
      }
    }
  }
}
.newResumeEditSelect {
  width: 100%;
  .Select {
    padding: 10px;
    .Select-value {
      //background: #f7f6fe;
    }
  }
}
.newExperienceEditSelect {
  .Select {
    width: 100%;
    padding: 10px;
  }
}
.newLocationSelect {
  .Select {
    padding: 10px;

    .Select-control {
      color: #413c3b;
      background: #f7f6fe;
      .Select-value,
      .Select-input {
        font-size: 16px;
        background: #f7f6fe;
        @media (max-width: 768px) {
          font-size: 12px;
        }

        input {
          font-size: 16px;
          background: #f7f6fe;
          color: #413c3b;
          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
      }
      .Select-placeholder {
        padding-top: 2px;
        font-size: 12px;
        color: #aaa;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
  }
}
.experience-edit-rich-text {
  .rich-text-editor {
    background-color: rgba(243, 241, 253, 0.7);
    .ql-toolbar {
      background-color: #8f82f5;
      border-radius: 8px 8px 0px 0px;
      padding: 10px;
      .ql-stroke {
        stroke: white;
      }
      .ql-picker-label {
        color: white;
      }
    }
  }
}
.select-input {
  margin-bottom: 10px;
  width: 100%;
  &.inValid {
    border: 1px solid $red;
    border-radius: 3px;
  }

  .Select-control {
    background: $offwhite $imp;
    height: 28px;
    box-shadow: none $imp;
    color: $purple2;
    font-size: 13px;
    border: transparent;
    border-radius: 3px;
    .Select-value {
      line-height: 27px;
    }
  }
  .Select-value-label {
    color: $purple2 $imp;
  }
  .Select-input {
    color: $purple2;
    font-size: 13px;
    height: auto;

    > input {
      min-width: 0;
      padding: 6px 0;
      line-height: 1;
    }
  }
  .Select-placeholder {
    line-height: 30px;
    color: $purple3;
    font-size: 12px;
    padding-right: 20px;
  }
  .Select-menu-outer {
    border: none;
    &:before {
      content: "";
      box-shadow: rgb(136, 136, 136) 0px 0px 6px;
      position: absolute;
      top: 2px;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
    }
    .Select-menu {
      background: $white;
    }
  }
  .Select-noresults {
    padding: 6px 10px;
    font-size: 13px;
  }
  .Select-option {
    padding: 5px 6px;
    font-size: 13px;
  }
}
/////////////react tooltip
.tippy-popper {
  .tippy-tooltip {
    padding: 0.4rem 0.4rem;
    font-size: 13px;
    &.light-theme {
      background: $tooltip $imp;
      box-shadow: rgb(136, 136, 136) 0px 0px 6px;
      color: $black;
    }
  }
  .tippy-backdrop {
    background: transparent $imp;
  }
  & .tippy-tooltip.light-theme [x-circle] {
    background-color: $tooltip $imp;
  }
  &[x-placement^="bottom"] .tippy-tooltip.light-theme .tippy-arrow {
    border-bottom: 7px solid $tooltip;
  }
  &[x-placement^="top"] .tippy-tooltip.light-theme .tippy-arrow {
    border-top: 7px solid $tooltip;
  }
  &[x-placement^="left"] .tippy-tooltip.light-theme .tippy-arrow {
    border-left: 7px solid $tooltip;
  }
  &[x-placement^="right"] .tippy-tooltip.light-theme .tippy-arrow {
    border-right: 7px solid $tooltip;
  }
  .tippy-tooltip.dark-theme {
    color: $white;
    background: $purple;
    border-color: $purple8;
    display: inline-table;
    text-align: left;
    .enter {
      background: $purple;
    }
  }
  &[x-placement^="bottom"] .tippy-tooltip.dark-theme .tippy-arrow {
    border-bottom: 7px solid $purple;
  }
  &[x-placement^="top"] .tippy-tooltip.dark-theme .tippy-arrow {
    border-top: 7px solid $purple;
  }
  &[x-placement^="left"] .tippy-tooltip.dark-theme .tippy-arrow {
    border-left: 7px solid $purple;
  }
  &[x-placement^="right"] .tippy-tooltip.dark-theme .tippy-arrow {
    border-right: 7px solid $purple;
  }
}
//form row and label

.form-label {
  min-width: 100px;
  font-size: 12px;
  margin-top: 8px;
  display: flex;
  color: $purple4;
}

.form-row {
  display: flex;
  color: $purple4;
}

//////// detail description

.MsgWrapper {
  font-size: 14px;
  padding: 10px 0;
  align-self: flex-start;
}

.MsgSubject {
  color: $purple2;
  background-color: $offwhite;
  text-align: center;
  padding: 10px;
  width: 100%;
  display: inline-block;
  font-size: 15px;
  font-family: $RubikRegular;
}
.titleLabel {
  font-size: 15px;
  font-family: $RubikRegular;
  color: $purple4;
  padding: 5px 0 10px;
  text-align: center;
  display: block;
  border-bottom: 1px solid $offwhite;
}

.msgItem {
  display: table;
  width: 100%;
  padding: 10px 10px 0;
}
.labelName {
  color: $purple4;
  min-width: 90px;
  display: table-cell;
  font-family: $RubikRegular;
}

.answerLabel {
  color: $purple4;
  width: 100%; // background: $offwhite;
  padding: 6px 10px;
  display: table-cell;
  border-radius: 3px;
}

.MsgDetailWrap {
  margin: 10px 0 0 10px;
  color: $purple4;
  .msgItem {
    padding: 0;
  }
}
.msgElement {
  display: table;
  width: 100%;
  padding: 0 0 10px;
}

.msgName {
  min-width: 100px;
  display: table-cell;
}

.msgNameCapsule {
  padding-top: 15px;
}
.msgNameValue {
  width: 100%;
  padding: 6px 10px;
  display: table-cell;
  border-radius: 3px;
}

.invRequest {
  display: flex;
  margin-top: 10px;
  padding-right: 10px;
  .SendButton {
    &:first-child {
      margin-left: 0;
      max-width: 100%;
    }
  }
  .invAccept {
    color: white;
    background: #05caa8;
    border: transparent;
    border-radius: 5px;
    align-self: center;
    margin-bottom: 5px;
  }
  .invDecline {
    color: white;
    background: $red;
    border: transparent;
    border-radius: 5px;
    align-self: center;
    margin-bottom: 5px;
  }
}

.invAccept {
  margin: 0 5px 0 0;
  display: inline-block;
  flex: 1;
  // color: $green;
  // cursor: pointer;
  // @include transition(0.3s all);
  // &:before {
  //   @extend %fi;
  //   content: $fi-checkmark;
  //   vertical-align: text-bottom;
  // }
  // &:hover {
  //   color: darken($green, 20);
  // }
}

.invDecline {
  background: $red;
  margin: 0 0 0 5px;
  display: inline-block;
  flex: 1;
  max-width: 100%;

  // cursor: pointer;
  // @include transition(0.3s all);
  // &:before {
  //   @extend %fi;
  //   content: $fi-cancel;
  //   vertical-align: text-bottom;
  // }
  &:hover {
    background: darken($red, 20);
  }
}

.invRestore {
  margin: 0;
  max-width: 100%;
  // color: $blue;
  // cursor: pointer;
  // @include transition(0.3s all);
  // &:before {
  //   @extend %fi;
  //   content: $fi-restore;
  //   vertical-align: text-bottom;
  // }
  // &:hover {
  //   color: darken($blue, 20);
  // }
}
.invCompValue {
  color: $green;
  cursor: pointer;
}

.invSenderSum {
  font-size: 16px;
  margin-bottom: 15px;
  margin-top: 0;
  line-height: 1.2;
}
//Accepted,Declined And Pending
.oppAccepted {
  color: $darkGreen;
  cursor: pointer;
  @include transition(0.3s all);
  &:before {
    @extend %fi;
    content: $fi-check-circle;
    vertical-align: text-bottom;
  }
  &:hover {
    color: darken($darkGreen, 20);
  }
}
.oppDeclined {
  color: $red;
  cursor: pointer;
  @include transition(0.3s all);
  &:before {
    @extend %fi;
    content: $fi-alert-octagon;
    vertical-align: text-bottom;
  }
  &:hover {
    color: darken($red, 20);
  }
}

.oppPending {
  color: $yellow;
  cursor: pointer;
  @include transition(0.3s all);
  &:before {
    @extend %fi;
    content: $fi-schedule;
    vertical-align: text-bottom;
  }
  &:hover {
    color: darken($yellow, 20);
  }
}

//////////rc range slider
.rc-slider {
  margin-top: 8px;
  margin-bottom: 8px;
}
.rc-slider-handle {
  width: 25px;
  margin-top: -10px;
  padding-top: 0;
  line-height: 25px;
  text-align: center;
  height: 25px;
  border-radius: 50%;
  // margin-left: -12px;
  border: none;
  color: $white;
  background: $yellow;
}
.rc-slider-track {
  background: $offwhite;
}
.rc-slider-rail {
  background: $offwhite;
}
//////// date picker
.react-datepicker-popper {
  z-index: 9999999999 !important;

  // @media (max-width: 400px) {
  //   transform: translate3d(63px, 200px, 0px) !important;
  // }
  // @media (max-width: 320px) {
  //   transform: translate3d(23px, 200px, 0px) !important;
  // }
}
.datepicker-input {
  min-width: 0;
  width: 100%;
  border: transparent;
  border-radius: 3px;
  outline: none;
  padding: 6px 10px;
  height: 28px;
  font-size: 13px;
  font-family: $RubikRegular;
  background: $offwhite;

  color: $purple2;
}
.datepicker-wrapper {
  margin-bottom: 10px;
  width: 100%;
}
.react-datepicker-wrapper {
  display: block;
  position: relative;
  &:before {
    content: "";
    background: url("../images/ExtIcons/date-trigger.png") no-repeat 0 center;
    width: 23px;
    height: 18px;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 1;
    margin-top: -9px;
  }
}
.react-datepicker__input-container {
  display: block;
}
////list styles
.moreDetails {
  padding: 10px 0 0;
}
.moreCompany {
  display: block;
  color: $purple2;
  padding: 5px 10px 5px 0;
  &:before {
    @extend .fi;
    content: $fi-industry;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 6px;
    font-size: 14px;
  }
}

.moreSender {
  display: block;
  color: $purple2;
  padding: 5px 10px 5px 0;
  &:before {
    @extend .fi;
    content: $fi-user;
    display: inline-block;
    vertical-align: bottom;
    border-radius: 50%;
    margin-right: 6px;
    font-size: 14px;
  }
}
////////social links
.social-links {
  display: block;
  border-top: 1px solid $gray3;
  color: $purple2;
  padding: 7px 30px;
  position: relative;
  &:nth-child(even) {
    background: $offwhite;
  }
}
.facebook {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 10px;
    top: 8px;
    width: 8px;
    height: 15px;
    background: url("../images/Icons/facebook.png") no-repeat center;
  }
  &:before {
    background-color: #3b5997;
    @include border-radius(50%);
    content: "";
    width: 22px;
    height: 22px;
    position: absolute;
    content: "";
    top: 4px;
    left: 3px;
  }
}

.twitter {
  position: relative;
  &:after {
    content: "";
    position: absolute; // right: 7px;
    top: 9px;
    left: 9px;
    height: 13px;
    width: 12px;
    background: url("../images/Icons/twitter.png") no-repeat center;
  }
  &:before {
    background-color: #00abf0;
    @include border-radius(50%);
    content: "";
    width: 22px;
    height: 22px;
    position: absolute;
    content: "";
    top: 4px; // right: 3px;
    left: 3px;
    right: auto;
  }
}

.googleplus {
  position: relative;
  &:after {
    content: "";
    position: absolute; // right: 7px;
    top: 9px;
    left: 7px;
    width: 15px;
    height: 13px;
    background: url("../images/Icons/google.png") no-repeat center;
  }
  &:before {
    background-color: #dd4c39;
    @include border-radius(50%);
    content: "";
    width: 22px;
    height: 22px;
    position: absolute;
    content: "";
    top: 4px; // right: 3px;
    left: 3px;
  }
}

.instagram {
  position: relative;
  &:after {
    content: "";
    position: absolute; // right: 7px;
    top: 9px;
    left: 7px;
    height: 13px;
    width: 13px;
    background: url("../images/Icons/instagram.png") no-repeat center;
  }
  &:before {
    background-color: #78554c;
    @include border-radius(50%);
    content: "";
    width: 22px;
    height: 22px;
    position: absolute;
    content: "";
    top: 4px; // right: 3px;
    left: 3px;
  }
}

.linkedin {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 7px;
    top: 8px;
    height: 13px;
    width: 14px;
    background: url("../images/Icons/linkedin.png") no-repeat center;
  }
  &:before {
    background-color: #007bb6;
    @include border-radius(50%);
    content: "";
    width: 22px;
    height: 22px;
    position: absolute;
    content: "";
    top: 4px;
    left: 3px;
  }
}

.pinterest {
  position: relative;
  &:after {
    content: "";
    position: absolute; // right: 8px;
    top: 10px;
    left: 8px;
    height: 11px;
    width: 13px;
    background: url("../images/Icons/pinterest.png") no-repeat center;
  }
  &:before {
    background-color: #e3161d;
    @include border-radius(50%);
    content: "";
    width: 22px;
    height: 22px;
    position: absolute;
    content: "";
    top: 4px; //     right: 3px;
    left: 3px;
  }
}

.driblle {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 7px;
    top: 7px;
    height: 15px;
    width: 15px;
    background: url("../images/Icons/driblle.png") no-repeat center;
  }
  &:before {
    background-color: #e86181;
    @include border-radius(50%);
    content: "";
    width: 22px;
    height: 22px;
    position: absolute;
    content: "";
    top: 4px;
    left: 3px;
  }
}

.rc-menu-item {
  cursor: pointer;
}
.rc-menu-vertical {
  padding: 12px 0;
}
.rc-menu-vertical > .rc-menu-item {
  padding: 5px 0;
  font-size: 14px;
  line-height: 1.4;
}

.user-setting {
  color: $purple;
  text-align: center;
  padding: 10px;
  &:hover {
    background-color: $purple $imp;
    color: $white;
  }
}

.user-setting {
  background: url(../images/UserMenu-sprite.png) no-repeat 5px 2px $imp;
}

.logout {
  background-color: $purple;
  text-align: center;
  padding: 15px 0px $imp;
  margin-top: 15px;
  color: $white;
  font-size: 16px $imp;
  span {
    color: $white;
    font-size: 16px $imp;
  }
}

.behance {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 7px;
    top: 10px;
    width: 14px;
    height: 9px;
    background: url("../images/Icons/behance.png") no-repeat center;
  }
  &:before {
    background-color: #007bb6;
    @include border-radius(50%);
    content: "";
    width: 22px;
    height: 22px;
    position: absolute;
    content: "";
    top: 4px;
    left: 3px;
  }
}
//Resume Edit
.Modal {
  width: 40%;
  max-width: 100%;
  height: auto;
  overflow: auto;
  background: $white;
  padding: 20px 20px;
  color: $purple3;
  border-radius: 5px;
  align-content: center;
  .closeBtn {
    position: absolute;
    top: 14px;
    right: 14px;
    border: none;
    padding: 0;
    background-color: white;
    border: 2px;
    display: flex;
    border-radius: 50%;
  }
}

.error-msg {
  text-align: center;
}
.no-msg {
  margin-left: 10px;
  font-size: 12px;
  color: #9783cf;
}

.tictell-input {
  .ant-input {
    &::placeholder {
      color: #8e9092;
    }
  }
}

.tictell-login-checkbox {
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #8d93a3;
    }
  }
}

.padding-16 {
  padding: 16px;
}

////social links end
////loading mask
.loading-list-mask:empty {
  margin: auto;
  height: 250px;
  background-color: $white;
  /* change height to see repeat-y behavior */
  background-image: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    linear-gradient(#d3d3d3 20px, rgba(0, 0, 0, 0) 0%),
    linear-gradient(#d3d3d3 20px, rgba(0, 0, 0, 0) 0%),
    linear-gradient(#d3d3d3 20px, rgba(0, 0, 0, 0) 0%),
    linear-gradient(#d3d3d3 20px, rgba(0, 0, 0, 0) 0%);
  background-repeat: no-repeat;
  background-size: 50px 100%, 90% 200px, 90% 200px, 90% 200px, 90% 200px;
  background-position: 0 0, 50% 50px, 50% 100px, 50% 150px, 50% 200px;
  animation: listShine 1s infinite;
}

@keyframes listShine {
  to {
    background-position: 100% 0, /* move highlight to right */ 50% 50px,
      /* list item */ 50% 100px, /* list item */ 50% 150px,
      /* list item */ 50% 200px, /* list item */;
  }
}
//loading mask ends here
/* width */
@media (min-width: 767.98px) {
  ::-webkit-scrollbar {
    width: 10px;
    margin: 0px 5px;
  }
  .padding-16 {
    padding: 12px;
  }
}

/* Track */
@media (min-width: 767.98px) {
  ::-webkit-scrollbar-track {
    background: $white;
  }
}

/* Handle */
@media (min-width: 767.98px) {
  ::-webkit-scrollbar-thumb {
    background: $gray2;
    border-radius: 5px;
  }
}

/* Handle on hover */
@media (min-width: 767.98px) {
  ::-webkit-scrollbar-thumb:hover {
    background: #9783cf;
  }
}

input {
  -webkit-text-size-adjust: none;
}

// @media screen and (-webkit-min-device-pixel-ratio:0) {
//   select,
//   textarea,
//   input {
//     font-size: 16px;
//   }
// }

@supports (-webkit-overflow-scrolling: touch) {
  /* CSS specific to iOS devices */
  input,
  textarea {
    transform: translateZ(0px) !important;
  }
}

.cards-new {
  background-color: #fff;
  border-radius: 12px;
  padding: 16px;
  @media (max-width: 767.98px) {
    padding: 12px !important;
  }
}
.add-profile {
  border: 1px solid #e9e9e9;
  padding: 4px 10px;
  background-color: #f8f8f8;
  font-size: 16px;
  border-radius: 4px;
  @media (max-width: 767.98px) {
    font-size: 12px;
  }
}
.flex-1 {
  flex: 1;
}
.text-clamp {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
