@import "../../../../assets/sass/variables";
@import "../../../../assets/sass/mixins";

.socialLinks {
  display: flex;
  gap: 15px;
  align-items: center;
  .socialLink {
    cursor: pointer;
    font-size: 25px;
    &.activeSocialLink,
    &:hover {
      color: $lightPurple;
    }
  }
}

.editProfileButton {
  @include breakpoint("screen991") {
    position: absolute !important;
    right: 10px;
    top: 10px;
  }
}
.popupDetails {
  h5 {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }
  .ant-form-item-label {
    padding: 0 !important;
  }
  .customSelect {
    span {
      font-size: 12px;
    }
  }
}
