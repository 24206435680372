@import "../../assets/sass/importFiles";

.switch-container {
  .DashboardCompanyToggle {
    width: 45px;
    border: none $imp;
    background: transparent $imp;
    outline: none;
    box-shadow: none $imp;
    padding: 0;
    height: 46px;
    position: absolute;
    top: 0;
    right: 0;
    &:after {
      position: absolute;
      content: "";
      width: 20px;
      height: 20px;
      background: $white;
      right: 20px;
      top: 50%;
      margin-top: -10px;
      border-radius: 50%;
    }
    &:before {
      position: absolute;
      content: "";
      width: 30px;
      height: 10px;
      background: darken($green, 10);
      right: 10px;
      top: 50%;
      margin-top: -5px;
      border-radius: 10px;
    }
  }

  .DashboardFreelanceToggle {
    @extend .DashboardCompanyToggle;
    &:after {
      right: 10px;
    }
    &:before {
      background: darken($blue, 10);
    }
  }
}
