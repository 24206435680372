@import "../../../../assets/sass/importFiles";
.phillip-dashboard-searcher {
  background: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: unset;
  flex: 1 1;
  height: 100%;
  color: #3e335e;
  .main-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    color: #3e335e;
    flex-flow: row;
    justify-content: space-between;
    .first-div{
      min-width: 200px;
    }
    .right-div {
      flex: 10 1;
      align-self: center;
      h6 {
        font-size: 15px;
        text-align: center;
        margin: 0 auto;
        font-family: "rubik", Arial, sans-sarif;
        font-weight: normal;
        color: #3e335e;
      }
      .button-wrapper {
        align-self: center;
        display: flex;
        justify-content: center;
      }

      .continue-button {
        color: white;
        background: #05caa8;
        border: transparent;
        border-radius: 5px;
        align-self: center;
         padding: 5px 15px;
        margin-top: 10px;
        // color: $white;
        // padding: 5px 10px;
        // max-width: 100%;
        // background-color: $green;
        // border: none;
        // cursor: pointer;
        // border-radius: 2px;
        // font: normal 13px/16px $RubikRegular;
        // max-width: 150px;
        // align-self: center;
        // margin: 0 50% 2%;
        // min-width: 105px;
        // padding: 10px;
      }
      .phil_textarea_wrap {
        margin: 0 5%;
        .phil_textarea {
          background: #f1ecff;
          border: 0;
          width: 100%;
          border-radius: 3px;
          height: 250px;
          font-size: 13px;
          padding: 8px 10px;
          resize: none;
          display: block;
          margin: 10px 0;
          &:focus {
            outline: none;
          }
        }
      }
    }
    .left-div {
      align-self: center;
      display: flex;
      padding: 30px 60px;
      .first-view {
        display: flex;
        flex: 1;
      }
      .sliderImageWrap {
        flex: 0.3;
        min-width: 100px;
        img {
          max-height: 300px;
        }
      }
      .resume-upload-row {
        text-align: center;
        display: flex;
        flex-flow: column;
        align-self: flex-start;
        align-self: center;
      }

      .settings-container > div {
        font-size: 12px;
        margin-bottom: 10px;
      }
      .heading-div {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        margin-right: 80px;
        margin-bottom: 10px;
        .profileIcon,
        .skillIcon,
        .educationIcon {
          border-radius: 20%;
          margin-right: 10px;
          &:before {
            line-height: 2;
          }
        }
        label {
          align-self: center;
        }
      }
    }
    .draggable {
      padding: 7px 10px 7px 10px;
      &:before {
        content: none;
      }
    }
  }
  @include breakpoint(screen991) {
    .main-div {
      flex-flow: column;
      padding: 30px 10px;
      .right-div {
        min-width: 90%;
        textarea {
          margin: 0 !important;
        }
      }
      .left-div {
        width: 100%;
        .top-salaries {
          margin-right: 0px;
        }
        .mixed-chart {
          width: 100%;
        }
      }
    }
  }
  @include breakpoint(screen767) {
    .mixed-chart {
      > div {
        &:first-of-type {
          border-radius: 10px 10px 0px 0px;
        }
        &:nth-of-type(2) {
          border-radius: 0px 0px 10px 10px;
        }
      }
    }
  }
}
