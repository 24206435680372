.opportunity-form {
  .main-form {
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .collapse-title {
      margin: 0;
      color: #000000;
    }
  }
}
