.review-form{
    color: #9783cf;
    justify-content: center;
    margin: 10px;
    .star-ratings{
        margin-bottom: 15px;
    }
    p{
        text-align: start;
    }
}