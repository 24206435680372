.noExpWrapper {
  background: rgba(243, 241, 253, 0.7);
  border-radius: 8px;
  margin-top: 20px;
  padding: 40px 20px;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #161616;
    opacity: 0.7;
  }
}
.experienceWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  .ExperienceContent {
    width: 100%;
  }
  .options {
    margin-left: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon {
      margin-top: 5px;
      cursor: pointer;
      display: block;

      @media (max-width: 600px) {
        display: none;
      }
    }
  }
}
.resumeDetail {
  width: 100%;
  input {
    width: 100%;
    border: none;
    outline: none;
    background: #f7f6fe;
    border-radius: 8px;
    padding: 10px 15px;
    height: 50px;
    color: #413c3b;
    font-size: 12px;
    font-weight: 400;
    pointer-events: none;
    &::placeholder {
      color: #413c3b;
      opacity: 0.7;
    }
  }
}
.workplace {
  width: 100%;
  input {
    width: 100%;
    border: none;
    outline: none;
    background: #f7f6fe;
    border-radius: 8px;
    padding: 10px 15px;
    height: 50px;
    color: #413c3b;
    font-size: 12px;
    font-weight: 400;

    &::placeholder {
      color: #413c3b;
      opacity: 0.7;
    }
  }
}
.userResume {
  margin-top: 30px;
  .resumeTitle {
    align-items: center;
    margin-bottom: 15px;
    .title {
      background: rgb(247, 246, 254);
      border-radius: 8px;
      padding: 16px !important;
      cursor: pointer;
    }
    .paragraphResume {
      font-family: "Poppins";
      font-style: normal;
      font-size: 14px;
      line-height: 125%;
      margin: 0;
      color: #413c3b;
      display: inline-block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
    .dotSection {
      text-align: end;
    }
    svg {
      margin: 0px 6px;
    }
  }
}

.dropDownExperienceContent {
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: #8d8787;
    &:hover {
      p {
        color: #8f82f5;
      }
      img,
      svg {
        filter: invert(54%) sepia(9%) saturate(3205%) hue-rotate(206deg)
          brightness(103%) contrast(92%);
      }
    }
    img {
      margin-left: 20px;
      filter: opacity(0.6);
    }
    svg {
      width: 20px;
      filter: opacity(0.6);
    }
  }
  p {
    margin: 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-right: 20px;
  }
}

.industryRole {
  display: flex;
  width: 100%;

  div {
    width: 100%;
  }
}

.dates {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // margin-top: 20px;
  // margin-left: 5px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .fromDate,
  .toDate {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: 768px) {
      justify-content: space-between;
    }
  }

  .Working {
    display: flex;
    align-items: center;
    height: 100%;
    @media (max-width: 768px) {
      justify-content: flex-end;
    }
  }

  div {
    color: #413c3b !important;

    @media (max-width: 768px) {
      width: 100%;
    }

    .dateLabel {
      margin-right: 10px;
    }
  }
  .dateInput {
    width: 100%;
    border: none;
    outline: none;
    background: #f7f6fe;
    border-radius: 8px;
    padding: 10px 8px;
    height: 50px;
    color: #413c3b;
    font-size: 12px;
    font-weight: 400;
  }
  p {
    margin-bottom: 0;
    white-space: nowrap;
  }
}

.btn {
  border: none;
  background: #8f82f5;
  border-radius: 8px;
  width: 150px;
  padding: 10px 0px;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  margin-right: 10px;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-right: 0px;
    width: 100%;
    font-size: 14px;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .discard {
    background: #f7f6fe;
    border: 1px solid #8f82f5;
    width: 150px;
    padding: 10px 0px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    color: #413c3b;

    @media (max-width: 768px) {
      width: 100%;
      margin-top: 16px;
    }
  }
}
