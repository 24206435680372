@import "../../assets/sass/variables";
@import "../../assets/sass/mixins";
$lightPurpleBackground: rgba(241, 237, 255, 0.5);
.search-page-new-design {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: calc(100vh - 48px);
  background-color: $lightPurpleBackground;
  @media (max-width: 767.98px) {
    padding: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .views-cnt {
    padding: 0 !important;
    background-color: transparent !important;
  }
  .icon {
    height: 20px;
  }
  .label {
    color: #000;
    font-weight: 500;
  }

  .card-resume {
    border: 1px solid #e4e4e4;
    border-radius: 8px;
    padding: 14px;
  }
  .location-main {
    display: flex;
    align-items: center;
    gap: 6px;
    .location {
      font-size: 14px;
      opacity: 0.5;
      margin: 0;
    }
  }
  .resume-btn {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    .btn {
      font-size: 12px;
      color: #000;
      border-radius: 25px;
      padding: 4px 10px;
      border: 1px solid #e9e9e9;
      outline: none;
      background: #f8f8f8;
    }
  }
}

.search-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  .btn1 {
    font-size: 12px;
    color: #8e81f5;
    border-radius: 8px;
    padding: 4px 10px;
    border: 1px solid #8e81f5;
    outline: none;
    background: #fff;
  }
  .btn2 {
    background: #8e81f5;
    border: 1px solid #8e81f5;
    outline: none;
    color: #fff;
    padding: 4px 10px;
    border-radius: 8px;
  }
}

.new-design-search {
  height: 100%;
  overflow: auto;
  .resume-list {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}
.first-child-dropdown {
  .ant-dropdown-menu-title-content {
    color: #8e81f5;
    justify-content: space-between;
  }
}
