@import "../../../../assets/sass/importFiles";
.lead-page .mobile-apps {
  .mobileAppsPanel {
    .tabList {
      @extend %clearfix;
    }

    .tabItem {
      float: left;
      width: 50%;
      padding: 30px;
      text-align: center;
      color: #3e335e;
      line-height: 1;
      display: flex;

      @include breakpoint(screen1023) {
        padding: 30px 5px;
      }
      @include breakpoint(screen767) {
        width: 100%;
      }

      .tabContent {
        display: flex;
        flex-flow: column;
        margin-top: 10px;
        h5 {
          align-self: flex-start !important;
          padding: 0 !important;
          margin: 0 !important;
        }
        h6 {
          text-align: start !important;
          margin: 10px 0px 0px !important;
        }
      }
    }

    .tabImg {
      max-width: 70%;
      display: block;
      height: 80px;
      align-self: center;
      &.tictell {
        padding: 10px;
        border-radius: 20px;
      }
    }
  }

  .colored-circles {
    display: flex;
    justify-content: center;
    margin: 20px 10px;
    .item {
      width: 10px;
      height: 10px;
      border-radius: 2px;
      margin: 0 5px 0 0;
      &.blue {
        background-color: $blue;
      }
      &.green {
        background-color: $green;
      }
      &.orange {
        background-color: $orange;
      }
      &.mediumPurple {
        background-color: $mediumPurple;
      }
      &.lightGreen {
        background-color: $lightGreen;
      }
      &.lightPurple {
        background-color: $lightPurple;
      }
      &.yellow {
        background-color: $yellow;
      }
      &.red {
        background-color: $red;
      }
    }
  }
  .mobileAppsSCPanel {
    .tabItem {
      float: left;
      width: 25%;
      padding: 30px;
      text-align: center;
      line-height: 1;
      display: flex;

      @include breakpoint(screen1023) {
        padding: 30px 5px;
      }
      @include breakpoint(screen767) {
        width: 100%;
      }

      .tabImg {
        max-width: 70%;
        display: block;
        margin: 0 auto;
        border: 3px solid #3e335e;
        border-radius: 25px;
      }
    }
  }
}
