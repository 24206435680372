@import "../../assets/sass/importFiles";
.product-container {
  display: flex;
  .product-view-container {
    flex: 1;
    display: flex;
    flex-flow: column;
    background: $ViewsCnt;
    height: 100vh;
    @include breakpoint(screen1240) {
      margin-left: 0px;
    }
  }
}
