@import "../../assets/sass/variables";
@import "../../assets/sass/mixins";
$lightPurpleBackground: rgba(241, 237, 255, 0.5);

.new-dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: calc(100vh - 48px);
  background-color: $lightPurpleBackground;
  @media (max-width: 767.98px) {
    padding: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .main-dashboard {
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (max-width: 767.98px) {
      gap: 12px;
    }
    .column {
      border: 1px solid #f3f3f3;
      border-radius: 6px;
      padding: 10px;
    }
    .inner-card {
      border: 1px solid #f3f3f3;
      border-radius: 6px;
      padding: 12px;
      display: flex;
      gap: 12px;
      height: 100%;
    }
    .img-rebort {
      margin-bottom: -16px;
      @media (max-width: 767.98px) {
        height: 130px;
      }
    }
    .btn-add {
      border-radius: 25px;
      padding: 2px 12px;
      border: 1px solid #6e6e6e40;
      outline: none;
      font-size: 12px;
      color: #6e6e6ebf;
      background: transparent;
      height: fit-content;
    }
    .button-getting-started {
      margin: 12px 0px;
      border-radius: 25px;
      padding: 8px 12px;
      color: #fff;
      border: none;
      outline: none;
      background: linear-gradient(to right, #8e81f5, #554d93);
      font-size: 12px;
      display: flex;
      align-items: center;
      gap: 5px;
    }
    .view-all {
      text-align: center;
      font-size: 16px;
      margin-bottom: 0;
      color: #8e81f5;
      font-weight: 600;
      span {
        cursor: pointer;
      }
      @media (max-width: 767.98px) {
        font-size: 14px;
      }
    }
    .what-phillips {
      @media (max-width: 767.98px) {
        flex-direction: column;
      }
    }
  }
  @media (max-width: 767.98px) {
    h3 {
      font-size: 16px;
    }
    h4 {
      font-size: 14px;
    }
    h5 {
      font-size: 12px;
    }
    .fnt-s-16 {
      font-size: 14px;
    }
    .profile {
      flex-direction: column;
      align-items: flex-start !important;
    }
  }

  // iPro style
  .ant-progress-bg {
    height: 10px !important;
  }
  .ant-progress-line {
    height: auto !important;
  }

  .add-resume {
    border: 1px solid #dcd8fc;
    padding: 4px 10px;
    background-color: #f4f2fe;
    font-size: 16px;
    color: #6b61b8;
    border-radius: 4px;
    @media (max-width: 767.98px) {
      font-size: 12px;
    }
  }
  .read-more {
    background-color: #8e81f5;
    font-size: 12px;
    border: none;
    outline: none;
    color: #fff;
    border-radius: 25px;
    padding: 8px 12px;
  }
  .position-abs{
    position: absolute;
  }
}
