@import "../../assets/sass/importFiles";
.settings-page {
  .accordion {
    border: none;
  }
  .accordion__title {
    border-bottom: 1px solid $gray3;
    padding: 14px 10px;
    position: relative;
    background: $offwhite4;
    outline: none;
    font-size: 14px;
    font-family: $RubikRegular;
    font-weight: 500;
    color: $purple4;
  }
  .accordion__arrow {
    right: 7px;
    &:after,
    &:before {
      width: 8px;
    }
    &:before {
      left: 7px;
    }
  }
  .accordion__body {
    padding: 0;
  }
  .description {
    padding: 10px;
  }

  .inValid {
    border: 1px solid $red;
  }
}
