@import "../../../assets/sass/importFiles";

.search-list-component {
  width: 100%;
  border-radius: 4px;
  color: $purple4;
  padding: 10px 10px 80px 10px;
  .availability-label {
    margin-top: 0;
    margin-bottom: 5px;
  }
  .search-list-item {
    @extend %clearfix;
    display: flex;
    flex-flow: row wrap;
    margin-top: 10px;
    @include breakpoint(screen480) {
      flex-flow: column wrap;
    }
  }
  .availability-wrapper {
    margin-bottom: 10px;
  }
  .rc-slider {
    .rc-slider-handle {
      margin-left: 0;
    }
  }
  .action-slider {
    display: flex;
    align-items: center;
    .closeBtn {
      position: relative;
      top: 0;
      right: 0;
      margin-top: 8px;
      margin-left: 30px;
    }
  }
  .select-input {
    margin-bottom: 0;
    float: right;
    flex: 1;
  }
  .lblHeading {
    font-size: 15px;
    font-family: $RubikRegular;
    font-weight: 500;
    display: block;
    line-height: 1.2;
    color: $purple3;
    cursor: pointer;
    margin: 5px 0 5px;
    min-width: 120px;
    &.activeSearch {
      color: $purple2;
    }
  }
  .list-wrapper {
    width: 100%;
  }
  .round-circle-list {
    width: 100%;
  }
  .select-availability {
    display: flex;
    align-items: center;
  }
  .availablity-date-picker {
    flex: 1;
  }
  .seach-datepicker {
    .react-datepicker-popper {
      display: none !important;
    }
  }

  .searchAvailLabel {
    flex: 1;
    margin-top: 0;
    max-width: 120px;
  }
}
