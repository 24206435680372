@import "../../../assets/sass/importFiles";
.collaboration {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: calc(100vh - 48px);
  background-color: rgba(241, 237, 255, 0.5);
  .collboration-column1 {
    background-color: #fff;
    border-radius: 8px;
    height: 100%;
    overflow: auto;
  }
  .views-cnt {
    flex: 1 1;
    display: flex;
    align-content: flex-start;
    flex-flow: row wrap;
    overflow-x: auto;
    padding: 0;
    background: transparent;
  }
  .page-content {
    height: 100%;
  }
  .collaboration-rating-detail {
    flex-flow: column;
  }
  .disabled {
    opacity: 0.8;
    pointer-events: none;
  }
}
.tabs-header-col {
  margin-bottom: 16px;
  .main-header {
    overflow: auto;
  }
  .tab-btn-coll {
    border-radius: 8px;
    background-color: #e4e0f4;
    border: 1px solid #e4e0f4;
    outline: none;
    padding: 6px 14px;
    min-width: 100px;
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }
  .active-url {
    color: #fff;
    background: #8e81f5;
  }
  .inactive-url {
    color: #000;
    background-color: #e4e0f4;
  }
}

.dropdown-callooration {
  width: 100%;
  overflow: auto;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  background-color: #fff;
  min-width: 200px;
  .ant-select-selection-item {
    color: #000;
    font-weight: 500;
  }
}
