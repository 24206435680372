@import "../../../../assets/sass/importFiles";
.resume-analyzer {
  background: #3e335e;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: unset;
  flex: 1 1;
  height: 100%;
  color: $white;
  padding: 20px 5px 0px;
  justify-content: space-between;
  min-height: 100vh;
  background-size: cover;
  background-image: url("../../../../assets/images/backgrounds//resume-analyzer.png");
  .TopBar {
    width: 100%;
    margin-bottom: 0px;
    height: 10px;
    img {
      width: 100%;
      height: 10px;
    }
  }
  .head {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 100%;
    img {
      width: 200px;
    }
  }
  .mobile-head {
    display: none;
  }
  .top-navigation {
    flex: 1;
    align-self: flex-start;
    justify-content: flex-start;
    display: flex;
    position: relative;
    max-height: 50px;
    hr {
      height: 20px;
      margin-left: 50px;
      margin-right: 50px;
    }
    a,
    > div {
      margin: 5px;
      color: $white;
      text-decoration: none;
      font-size: 20px;
    }
    .sub-nav {
      display: flex;
      flex-flow: column;
      position: absolute;
      top: 25px;
      background: #413760;
      padding: 5px 10px 10px 0px;
      border-radius: 10px;
    }
  }
  //header
  .proHeading {
    color: $white !important;
    font-size: 80px !important;
    text-align: start !important;
    margin: 0px 0 0px !important;
    margin-top: 0px !important;
    text-transform: uppercase !important;
    &:first-of-type {
      font-weight: 100 !important;
    }
  }
  .subHeading1,
  .subHeading2 {
    text-align: start !important;
    font-size: 20px !important;
    text-transform: uppercase !important;
  }
  .subHeading1 {
    font-weight: 400;
  }
  .subHeading2 {
    font-weight: bolder !important;
  }
  h6 {
    color: #ffffff !important;
    margin-bottom: 10px !important;
  }
  .main-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: unset;
    width: 100%;
    color: $white;
    flex-flow: row;
    justify-content: space-between;
    padding: 30px 60px;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    .sliderImageWrap {
      flex: 1;
      min-width: 270px;
      max-width: 270px;
      min-height: 270px;
      max-height: 270px;
    }
    .left-div {
      flex: 1 1;
      align-self: center;
      padding: 20px;
    }
    .right-div {
      flex: 1 1;
      align-self: center;
      .first-view {
        display: flex;
        flex: 1;
      }
      .draggable {
        color: #bc2ffc;
        border: 1px solid $white;
        padding-left: 35px;
        background: white;
        &:before {
          content: "";
          font-size: 16px;
          position: absolute;
          left: -5px;
          top: 50%;
          margin-top: -18px;
          padding-top: 8px;
          bottom: 0;
          width: 35px;
          text-align: center;
          background: #bc2ffc;
          border-radius: 50%;
          height: 35px;
          box-sizing: border-box;
          cursor: pointer;
          color: #ffffff;
          border: 3px solid #fff;
          box-shadow: aliceblue;
          box-shadow: 1px 0px #d5d0d0;
        }
      }
      .resume-upload-row {
        text-align: center;
        align-self: center;
        flex: 1;
      }
      .heading-div {
        display: flex;
        flex-flow: row;
        justify-content: center;
        display: flex;
        flex-flow: row;
        justify-content: start;
        text-transform: uppercase;
        font-weight: bold;
      }
      h6 {
        color: $white;
      }
    }
  }
  .settings-container {
    width: 100%;
    align-self: center;
    padding-top: 10px;
  }
  .step-div {
    border: 1px solid $white;
    min-height: 70px;
    border-radius: 5px;
    margin: 0px 25px;
    justify-content: flex-start;
    display: flex;
    width: 70%;
    align-self: center;
    @include breakpoint(screen767) {
      width: 90%;
      flex-flow: column;
      height: max-content;
      min-height: max-content;
      margin-right: 5%;
      margin-left: 1%;
    }
    .step-circle {
      display: flex;
      flex-flow: column;
      background: $white;
      color: #ffffff;
      font-size: 13px;
      width: 50px;
      height: 68px;
      border-radius: 5px;
      margin-right: 10px;
      padding-top: 15px;
      text-align: center;
      width: 80px;
      height: 80px;
      @include breakpoint(screen767) {
        flex-flow: row;
        width: 100%;
        height: unset;
        justify-content: center;
        padding-bottom: 10px;
        padding-top: 10px;
        label {
          margin-right: 10px;
        }
        label,
        span {
          font-size: 16px;
        }
      }
    }
    .step-heading {
      width: 100%;
      align-self: center;
      padding: 8px;
      label {
        font-size: 17px;
        font-weight: 900;
      }
    }
  }
  .mixed-chart {
    display: flex;
    justify-content: center;
    border-radius: 10px;
    color: #3e335e;
    background: transparent;
    flex-flow: column-reverse;
    > div {
      background: transparent;
      &:first-of-type {
        border-radius: 10px 0px 0px 10px;
      }
      &:nth-of-type(2) {
        border-radius: 0px 10px 10px 0px;
      }
    }
    .apexcharts-menu-icon {
      visibility: hidden;
    }
    .apexcharts-xaxis-label {
      background: #04caa7;
    }
    @include breakpoint(screen767) {
      flex-flow: column;
    }
  }
  .upload-div {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    align-self: center;
    padding-top: 20px;
    width: 100%;
  }
  .top-salaries {
    display: flex;
    flex-flow: column;
    justify-content: center;
    .salary-item {
      margin: 5px;
      text-align: center;
      padding: 5px 5px;
      color: #3e335e;
      label {
        &:first-of-type {
          font-weight: 900;
          font-size: 15px;
          color: #fff;
        }
        &:last-of-type {
          color: #bc2ffc;
        }
      }
      &.selected {
        background: #9500ff;
        color: $white;
        padding: 5px 5px;
        border-radius: 5px;
        color: $white;
        label {
          &:last-of-type {
            color: #fff;
          }
        }
      }
    }
    @include breakpoint(screen767) {
      margin-right: 10px;
    }
  }

  .js_upload_btn {
    background: $green;
    padding: 6px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 30px;
    color: $white;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    font-size: 15px;
    min-width: 200px;
    position: relative;
    overflow: hidden;
    height: 50px;
    border: 2px solid #fff;
    background-image: linear-gradient(#9500ff, #bc2ffc, #9500ff);
    input[type="file"] {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
    }
  }
  h3 {
    margin: 0px;
    text-align: center;
  }
  .lookup-head {
    margin-bottom: 20px;
  }

  #progressInput {
    margin: 20px auto;
    width: 30%;
  }

  .circle-background,
  .circle-progress {
    fill: none;
  }

  .circle-background {
    stroke: #ddd;
  }

  .circle-progress {
    stroke: $white;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .circle-text {
    font-size: 3em;
    font-weight: bold;
    fill: $white;
  }

  .progress-bar-container {
    max-height: 150px;
    display: flex;
    align-self: center;
  }

  .nextstep-filters {
    display: flex;
    align-self: center;
    margin-top: 20px;
    @include breakpoint(screen767) {
      flex-flow: column;
    }
  }
  .select-input {
    margin: 5px;
    max-width: 200px;
    min-width: 200px;
    border: 1px solid $white;
    border-radius: 5px;
    height: 40px;
    .Select-control {
      background: #3e335e !important;
      color: $white !important;
      height: 35px;
      font-size: 15px;
      text-align: center;
      box-shadow: 1px 2px #8888888a;
      border-radius: 5px;
    }
    .Select-menu-outer {
      background: transparent;
    }
    .Select-value-label {
      line-height: 35px;
      color: $white !important;
    }
  
    .Select-arrow-zone:hover > .Select-arrow {
      border-top-color: $white !important;
      
    }
    .Select-arrow {
      border-color: $white transparent transparent;
    }
    .Select-option {
      padding: 10px 6px;
      font-size: 13px;
      color: $white;
      text-align: center;
      border: 1px solid $white;
      background: #3e335e;
      margin-bottom: 2px;
      border-radius: 5px;
      border: 2px solid transparent !important;
      border-bottom: none;
      margin-top: 2px;
      margin: 2px;
      z-index: 12342534534;
    }
  }

  @keyframes load {
    0% {
      left: 0;
      height: 30px;
      width: 15px;
    }
    50% {
      height: 8px;
      width: 40px;
    }
    100% {
      left: 235px;
      height: 30px;
      width: 15px;
    }
  }
  @include breakpoint(screen991) {
    .main-div {
      flex-flow: column;
      padding: 30px 10px;
      .right-div {
        min-width: 350px;
      }
      .left-div {
        width: 100%;
        min-width: 390px;
        .top-salaries {
          margin-right: 0px;
        }
        .mixed-chart {
          width: 100%;
          flex-flow: column-reverse;
        }
      }
    }
  }
  @include breakpoint(screen767) {
    padding: 0;
    .sliderImageWrap {
      display: none;
    }
    .mobile-head {
      display: flex;
    }
    .head {
      display: none;
    }
    .top-navigation {
      hr {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    .subHeading1,
    .subHeading2 {
      font-size: 15px !important;
    }
    .proHeading {
      font-size: 40px !important;
    }
    .mixed-chart {
      > div {
        &:first-of-type {
          border-radius: 10px 10px 0px 0px;
        }
        &:nth-of-type(2) {
          border-radius: 0px 0px 10px 10px;
        }
      }
    }
    .main-div {
      &.sub-nav {
        // margin-top: 150px;
      }
    }
    .slides-component {
      margin-top: 215px;
    }
    .main-header,
    .tab-header {
      display: none;
    }
    .mobile-head {
      display: flex;
      width: 100%;
      .top-wrapper {
        display: flex;
        flex-flow: column;
        width: 100%;
        align-items: center;
        .top-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          flex-flow: row-reverse;
          background: #9500ff;
          padding: 10px;
          .ToggleMenu {
            align-self: center;
            border: none;
            background: url("../../../../assets/images/Icons/menu.png")
              no-repeat 0 0;
            width: 30px;
            margin: 5px 5px 0;
            height: 25px;
            float: left;
            background-size: 35px 25px;
          }
          img {
            width: 100px;
            max-width: 150px;
            margin-left: 10px;
          }
        }
        .top-navigation {
          flex: 1 1;
          align-self: center;
          flex-flow: column;
          background-image: linear-gradient(#712aa6, #9669aa, #52197b);
          width: 100%;
          flex: 1;
          min-height: 285px;
          &.toggled {
            min-height: 120px;
          }
          span,
          a {
            width: 100%;
            display: block;
            padding: 10px;
            font-size: 15px;
            margin: 0;
            padding: 10px;
            border: 1px solid #fff;
            background-image:linear-gradient(#905bba, #690595, #7e48aa);
            &:hover {
              background: $green;
            }
          }
          > div {
            width: 100%;
            display: block;
            margin: 0;
            border: 1px solid #fff;
            &:hover {
              background: $green;
            }
          }
          .sub-nav {
            display: flex !important;
            flex-flow: column !important;
            position: relative !important;
            background-image: linear-gradient(#712aa6, #caacd7, #52197b);
            padding-left: 10px !important;
            top: 0;
            padding: 0;
          }
        }
      }
    }
  }
  .footer {
    height: 50px;
    background: #9500ff;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    img {
      margin-right: 5px;
    }
  }
}
