@import "../../../../assets/sass/importFiles";
.lead-page .how-it-works {
  .tabButton {
    text-align: center;
  }

  .tabBtn {
    display: inline-block;
    margin: 0 7px;
    background: #ece9f2;
    border-radius: 3px;
    padding: 5px 15px;
    border: none;
    color: $darkBlue;
    transition: 0.3s all;
    cursor: pointer;
    &.active,
    &:hover {
      color: #fff;
      background: $darkBlue;
    }
    @include breakpoint(screen1023) {
      margin-bottom: 10px;
    }
  }

  .tabList {
    @extend %clearfix;
  }

  .tabItem {
    float: left;
    width: 33.3%;
    padding: 30px 60px;
    text-align: center;
    color: $darkBlue;
    line-height: 1;
    @include breakpoint(screen1023) {
      padding: 30px 5px;
    }
    @include breakpoint(screen767) {
      width: 100%;
    }

    .moduleIcon {
      display: block;
      height: 75px;
      width: 75px;
      margin: 0 auto;
      background: #05caa8;
      border-radius: 10px;
      display: flex;
      &:before {
        @extend %fi;
        font-family: "icomoon" !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "";
        display: inline-block;
        margin: 0 auto;
        font-size: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        color: $white;
      }
    }

    .searchIcon {
      @extend .moduleIcon;
      background-color: $blue;
      &:before {
        content: $fi-Search;
      }
    }

    .presentIcon {
      @extend .moduleIcon;
      background-color: $yellow;
      &:before {
        content: $fi-Presentation;
        margin-top: 4px;
      }
    }

    .collaborationIcon {
      @extend .moduleIcon;
      background-color: $green;
      &:before {
        content: $fi-Collaboration;
      }
    }

    .shortlistIcon {
      @extend .moduleIcon;
      background-color: $purple;
      &:before {
        content: $fi-Shortlist;
      }
    }

    .workplaceIcon {
      @extend .moduleIcon;
      background-color: $orange;
      &:before {
        content: $fi-Workplace;
      }
    }

    .opportunityIcon {
      @extend .moduleIcon;
      background-color: $red;
      &:before {
        content: $fi-Opportunity;
      }
    }

    .resumeIcon {
      @extend .moduleIcon;
      background-color: $blue;
      &:before {
        font-family: "Linearicons-Free" !important;
        content: $fi-resume;
      }
    }

    .networkIcon {
      @extend .moduleIcon;
      background-color: $yellow;
      &:before {
        content: $fi-Shortlist;
      }
    }

    .messageIcon {
      @extend .moduleIcon;
      background-color: $purple;
      &:before {
        content: $fi-Message;
      }
    }

    .feedbackIcon {
      @extend .moduleIcon;
      background-color: $orange;
      &:before {
        content: $fi-feedback;
      }
    }
  }

  .tabImg {
    max-width: 70%;
    display: block;
    margin: 0 auto;
    @include breakpoint(screen767) {
      width: 160px;
    }
  }
}
