@import "../../assets/sass/importFiles";

  
#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: #000;
  opacity: 0.6
}
#loader {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #3498db;

  -webkit-animation: spin 2s linear infinite !important; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 2s linear infinite !important; /* Chrome, Firefox 16+, IE 10+, Opera */
}
