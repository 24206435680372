@import "../../assets/sass/importFiles";
@import "./variables.scss";
.timerHeader{
    font-size: 13px !important;
    margin-top:-13px !important;
}
.prodooTImerCheck {
  display: flex !important;
  flex-direction: row !important;
  padding: 10px;
  margin-left: 5px;
  input {
    width: auto !important;
    /* Double-sized Checkboxes */
    // -ms-transform: scale(2); /* IE */
    // -moz-transform: scale(2); /* FF */
    // -webkit-transform: scale(2) !important; /* Safari and Chrome */
    // -o-transform: scale(2); /* Opera */
    // transform: scale(1.5);
    // margin-top: 5px;
  }
  input-text {
    width: auto;
  }
  span {
    font-size: 15px !important;
    margin-top: 2px;
    margin-left: 10px;
  }
  @media (max-width: 330px) {
    padding: 0px 5px;
  }
}
.tictell-page {
  color: $white;
  font-family: Source Sans Pro !important;
  background: $back-color-pale_purple_light;
  .emptySkeletonResult {
    margin-top: 50px;
  }
  .views-cnt {
    
    .timelog-view,
    .expenselog-view {
      height: 100%;
      flex: 1;
      flex: 1;
      display: flex;
      height: 100%;
      .page-column {
        position: relative;
        padding: 5px;
      }
      @include breakpoint(screen767) {
        flex-flow: column;
        .page-column {
          min-height: 350px;
          height: auto;
          position: relative;
        }
        .page-column.page-column-collapse {
          height: auto;
          min-height: 0;
        }
        
      }
    }
    @include breakpoint(screen767) {
      flex-flow: column;
      .timelog-view,
      .expenselog-view {
        width: 100%;
        flex: 1;
        // margin-top: 60px;
        overflow: auto;
      }
    }
  }
  .heading {
    @extend .lg-heading;
    background: #9783cf;
    color: $white;
    text-align: center;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 8px;
  }
  .expand-heading {
    cursor: pointer;
    background: #9783cf;
    color: #ffffff;
  }
  .collapseBtn {
    height: 45px;
    width: 45px;
    background: #9783cf;
    color: $white;
    &:before {
      color: #ffffff;
      margin-top: 4px;
    }
  }
  .column-body {
    background: #dfd9f7;
    padding: 0px 0px;
    // @include breakpoint(screen767) {
    //   overflow: unset;
    // }
  }
  .buttons-common {
    width: 45px;
    height: 45px;
    background: $back-color-light_purple;
    border-radius: 5px;
    border: transparent;
    &:before {
      @extend %fi;
      color: $white;
      line-height: 1.5;
      font-size: 20px;
      line-height: 1.5;
      @include border-radius(50%);
      box-sizing: border-box;
      display: inline-block;
    }
  }
  .col-1 {
    .action-button-wrapper {
      position: absolute;
      bottom: 0px;
      margin-bottom: 10px;
      margin-left: 11px;
      &:last-of-type {
        margin-right: 15px;
        margin-bottom: 10px;
        right: 0;
      }
      .help-icon {
        top: 0px;
        right: 0px;
      }
      @include breakpoint(screen767) {
        height: 55px;
        min-height: 55px;
        .button-wrapper:first-of-type {
          margin: 0px 0px 2px 2px;
        }
        .button-wrapper:last-of-type {
          margin: 0px 2px 2px 0px;
        }
      }
      .add-btn {
        padding-top: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 55px;
        @include border-radius(50%);
        background: $green;
        outline: none;
        height: 55px;
        margin-bottom: 10px;
        border: none;
        align-self: center;
        margin-top: 5px;
        @include breakpoint(screen767) {
          height: 45px;
          width: 45px;
        }
        &.disabled-class {
          pointer-events: none;
          cursor: default;
          opacity: 1;
          border: 1px solid $back-color-light_purple;
          background:#a5a3a39e;
          &:before {
            color: $back-color-light_purple;
          }
        }
      }
      .help-btn {
        width: 55px;
        @include border-radius(50%);
        background: $green;
        outline: none;
        padding: 0;
        height: 55px;
        margin-bottom: 10px;
        border: none;
        align-self: center;
        margin-top: 5px;
        @include breakpoint(screen767) {
          height: 45px;
          width: 45px;
        }
        &.disabled-class {
          pointer-events: none;
          cursor: default;
          opacity: 0.5;
          border: 1px solid $back-color-light_purple;
          background: $back-color-pale_purple_dark;
          &:before {
            color: $back-color-light_purple;
          }
        }
        &:before {
          @extend %fi;
          font-size: 25px;
          content: $fi-helpIcon;
          color: $white;
          vertical-align: middle;
          @include rotate(0deg);
          margin: -5px 2px 0 1px;
          box-sizing: border-box;
        }
      }
      label {
        align-self: center;
        padding: 5px 30px;
        border-radius: 5px;
        box-shadow: 1px 1px silver;
        color: white;
        @include breakpoint(screen767) {
          margin-bottom: 10px;
        }
        &.status-1 {
          background: $yellow;
        }

        &.status-2 {
          background: $green;
        }

        &.status-3 {
          background: $red;
        }

        &.status-4 {
          background: $purple4;
        }
      }
      .send-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 55px;
        @include border-radius(50%);
        background: $green;
        outline: none;
        padding: 0;
        height: 55px;
        margin-bottom: 10px;
        align-self: center;
        margin-top: 5px;
        border: none;
        @include breakpoint(screen767) {
          height: 45px;
          width: 45px;
        }
        &.disabled-class {
          pointer-events: none;
          cursor: default;
          opacity: 0.5;
          border: 1px solid $back-color-light_purple;
          background: $back-color-pale_purple_dark;
          .fa-thumbs-up {
            color: $back-color-light_purple;
          }
        }

        &.status-1 {
          background: #a5a3a39e!important;
          opacity: 1!important;
          @extend .disabled-class;
          // &:before {
          //   @extend %fi;
          //   content: $fi-WaitingForApproval;
          // }
        }

        &.status-2 {
          @extend .disabled-class;
          // &:before {
          //   @extend %fi;
          //   content: $fi-Approved;
          // }
        }

        &.status-3 {
          background: $purple4;
          // &:before {
          //   @extend %fi;
          //   content: $fi-SendForApproval;
          // }
        }

        &.status-4 {
          background: $purple4;
          &.disabled-class {
            background: $back-color-pale_purple_dark;
          }
        }
      }
    }
    .date-change-wrapper {
      display: flex;
      flex-flow: row;
      .help-icon {
        top: 0px;
        right: 0px;
      }
      .homeBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        @extend .buttons-common;

        @media (max-width: 330px) {
          width: 35px;
        }
      }
      .date-header {
        display: flex;
        flex-flow: row;
        position: relative;
        height: 45px;
        background: $back-color-light_purple;
        justify-content: space-between;
        border-radius: 5px;
        flex: 1;
        margin-right: 5px;
        .button-wrapper {
          margin: 0px 10px 0px;
          @include breakpoint(screen767) {
            margin: 0px 0px 0px;
          }
        }
        &.status-1 {
          background: $yellow;
        }

        &.status-2 {
          background: $green;
        }

        &.status-3 {
          background: $red;
        }

        &.status-4 {
          background: $purple4;
        }
        .prevBtn {
          @extend .buttons-common;
          background: transparent;
          &:before {
            content: $fi-Rwd;
            margin-bottom: 3px;
          }
        }
        .nextBtn {
          @extend .buttons-common;
          background: transparent;
          &:before {
            content: $fi-Fwd;
            margin-bottom: 3px;
          }
          &.disabled {
            pointer-events: none;
            cursor: default;
            opacity: 0.5;
          }
        }
        .date-heading {
          color: 1;
          flex: 1;
          align-self: center;
          color: $white;
          text-align: center;
          display: flex;
          justify-content: space-around;
          > div {
            display: flex;
            flex-direction: column;
            label {
              @extend .lg-heading;
              @include breakpoint(screen767) {
                font-size: 10px !important;
              }
              @media (max-width: 360px) {
                font-size: 8px !important;
                font-weight: 400;
              }
            }
            label {
              &:last-child {
                @extend .md-heading;
                line-height: 1rem;
              }
            }
          }
          .expense-amount {
            justify-content: center;
            label {
              align-self: center;
              font-size: 15px !important;
              @include breakpoint(screen767) {
                font-size: 11px !important;
              }
            }
          }
        }
      }
    }
    .select-input {
      margin-bottom: 5px;
      .Select-control {
        margin-left: 0px;
        height: 45px;
        background: $back-color-light_purple !important;
        color: $white !important;
        margin-bottom: 0px;
        .Select-value {
          line-height: 45px;
        }
        font-size: 15px;
      }
      .Select-value-label {
        color: $white !important;
        font-weight: 500;
      }
      .Select-clear-zone {
        color: $white !important;
      }
      .Select-arrow-zone {
        color: $white !important;
        .Select-arrow {
          border-color: $white transparent transparent;
        }
      }
    }
  }

  .tictell-list-wrapper {
    position: relative;
    max-height: 100%;
    min-height: 100%;
    display: flex;
    flex-flow: column;
    overflow: hidden;
    flex: 1 1;
    padding-top: 10px;
    .expense-list {
      overflow: auto;
      max-height: 100%;
      flex: 1;
    }
  }
  .expense-detail {
    padding-top: 10px;
    font-family: "Roboto";
    .react-datepicker-wrapper {
      &:before {
        @extend %fi;
        color: $font-color-light_grey_purple;
        line-height: 1;
        content: $fi-calendar2;
        font-size: 20px;
        background: none;
        line-height: 1.5;
        @include border-radius(50%);
        box-sizing: border-box;
        display: inline-block;
        margin-top: -17px;
      }
    }
    .expense-amount {
      display: flex;
      justify-content: space-between;
      > div {
        flex: 1;
        &:last-of-type {
          margin-left: 10px;
        }
      }
    }
    .time-range-picker {
      display: flex;
      .react-datepicker-wrapper {
        &:before {
          @extend %fi;
          color: $font-color-light_grey_purple;
          background: none;
          line-height: 1;
          content: $fi-Timepicker;
          font-size: 20px;
          line-height: 1.5;
          @include border-radius(50%);
          box-sizing: border-box;
          display: inline-block;
          margin-top: -16px;
        }
      }
      > label {
        align-self: center;
        color: $font-color-light_grey_purple;
        @extend .lg-heading;
        white-space: nowrap;
        margin: 0px 10px 20px 10px;
      }
      .input-field {
        flex: 1;
      }
    }
    .attach-list-item {
      display: flex;
      justify-content: space-between;
      padding-left: 10px;
      margin: 5px 0px 0px;
      label {
        max-width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        align-self: center;
      }
    }
  }
  .timelogs-list {
    flex: 1;
    overflow: auto;
  }
  .emptySkeletonResult {
    @include breakpoint(screen767) {
      align-self: center;
      overflow: hidden;
      max-height: 100%;
      flex: 1 1;
      background-size: 80px;
      padding: 90px 10px 0;
      margin: 0px;
    }
  }
  .js_upload_btn {
    background: $green;
    padding: 6px 0px 6px 7px;
    border: none;
    border-radius: 3px;
    color: $white;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    font-size: 13px;
    width: 110px;
    position: relative;
    overflow: hidden;
    align-self: center;
    margin-bottom: 5px;
    input[type="file"] {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
    }
  }
  .textarea-field {
    margin-bottom: 0px;
  }
  .attachment-lists {
    padding: 0px 10px 10px;
  }
  a {
    text-decoration: none;
    color: $font-color-light_grey_purple;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      .column-head {
        margin-top: -10px;
      }
      .column {
        height: 93%;
      }
      .react-datepicker-popper {
        left: 0 !important;
        right: 0 !important;
      }
      input[disabled],
      textarea[disabled],
      select[disabled="disabled"] {
        color: #000001 !important;
      }
      .date-header {
        .button-wrapper {
          margin: 0px !important;
        }
      }
      .nextBtn,
      .prevBtn {
        width: 38px !important;
        padding: 0px;
      }
      .action-button-wrapper {
        bottom: 5px !important;
      }

      .expenselog-view,
      .timelog-view {
        height: 93% !important;
        .col-1,
        .col-2 {
          margin-top: 10px !important;
        }
      }
      .page-column-collapse {
        padding-top: 0px !important;
      }
      .homeBtn {
        &::before {
          margin: 0px -5px;
        }
      }
      .react-datepicker-wrapper {
        &::before {
          margin-top: -15px !important;
        }
      }
    }
  }
}
