@import "../../../../assets/sass/importFiles";
.mywork-view {
  flex: 1;
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  > * {
    flex: 1 1 250px;
  }
  .flow-detail {
    margin-top: 10px;
  }
  .button-wrapper {
    align-self: center;
  }
  .complete-label {
    align-self: center;
  }
  @include breakpoint(screen767) {
    flex-flow: column;
    .page-column {
      min-height: 350px;
      height: auto;
    }
    .page-column.page-column-collapse {
      height: auto;
      min-height: 0;
    }
  }
  .snapshot-collaboration-detail,
  .expense-detail,
  .time-expense-detail {
    padding-top: 10px;
  }
  .col-1 {
    @include breakpoint(screen767) {
      min-height: 250px;
      height: auto;
      &.page-column.page-column-collapse {
        min-height: auto;
      }
      margin-top: 60px;
    }

    .select-input {
      margin: 5px 0px 0px 0px;
      width: 100%;
      .Select-control {
        background: $purple4 !important;
        color: $white !important;
        height: 43px;
        font-size: 15px;
        text-align: center;
        box-shadow: 1px 2px #8888888a;
        border-radius: 5px;
      }
      .Select-menu-outer {
        background: transparent;
      }
      .Select-value-label {
        line-height: 43px;
        color: $white !important;
      }
      .Select-arrow-zone:hover > .Select-arrow {
        border-top-color: $white !important;
      }
      .Select-arrow {
        border-color: $white transparent transparent;
      }
      .Select-option {
        padding: 10px 6px;
        font-size: 13px;
        color: #ffffff;
        text-align: center;
        background: #9783cf;
        margin-bottom: 2px;
        border-radius: 5px;
        border: 2px solid transparent !important;
        border-bottom: none;
        margin-top: 2px;
        margin: 2px;
      }
    }
  }
  .col-2 {
    .collaboration-heading {
      margin-top: 5px;
      border-radius: 5px;
    }
  }
}
