@import "../../assets/sass/importFiles";
.list-group-wrapper {
  .list-group-ul {
    list-style: none;
    margin: 0;
  }
  .list-group-item {
    color: $purple4;
    font-size: 13px;
    display: flex;
    position: relative;
    transition: 0.3s all;
    border: none;
  }
  .list-group-column {
    flex: 1;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
    &.no-flex {
      flex: 0;
    }
    
  }
  @media (max-width:330px) {
    .list-group-item{
      flex-direction: column-reverse;
    }
    .list-group-column{
      margin-right: 0px;
    }
    .no-flex:first-child{
      position: absolute;
      bottom:70%;
      margin-bottom: 7px;
    }
    .no-flex:last-child{
      margin-left: auto;
      margin-right: 5px;
    }
   
  }
  .closeBtn {
    position: relative;
    top: 0;
    margin-top: 0;
    right: 0;
  }
}
