@import "../../../../assets/sass/importFiles";

#LoginSliderContainer {
  display: flex;
  align-items: center;
  &.hidden {
    display: none;
  }
}

.LoginForm {
  border-radius: 4px;
  background: #fff;
}

.otherOption {
  margin: 10px 0;
  text-align: center;
  position: relative;
  &:before {
    background: #000;
    height: 1px;
    width: 100%;
    content: "";
    top: 11px;
    display: block;
    position: absolute;
  }
  span {
    display: inline-block;
    position: relative;
    padding: 0 10px;
    background: $white;
  }
}

.select {
  height: 35px;
  line-height: 45px;
  font-family: $RubikRegular;
  padding: 7px;
  margin-bottom: 15px;
  width: 100%;
  border-radius: 4px;
  background: #e6e7e8;
  border: none;
  font-size: 15px;
}

.login {
  padding: 30px;
}

.LoginInput {
  height: 35px;
  font-family: $RubikRegular;
  text-align: center;
  margin-bottom: 15px;
  width: 100%;
  background: $offwhite;
  border: none;
  border-radius: 4px;
  font-size: 15px;
  @include breakpoint(screen1023) {
    margin-bottom: 18px;
  }
  @include input-placeholder {
    font-style: italic;
    color: #bdbfc1;
  }
  &.inValid {
    border: 1px solid $red;
  }
}

.noAccount {
  font-size: 15px;
  text-align: center;
  display: block;
  font-family: $RubikRegular;
  .link {
    color: blue;
  }
}

.invalidField {
  border: 1px solid $red;
}

.validationMsg {
  display: none;
  color: $red;
  margin-top: 10px;
}

.SignUp {
  font-family: $RubikRegular;
  background: $green;
  display: block;
  color: #fff;
  text-align: center;
  padding: 7px;
  border-radius: 4px;
  margin-bottom: 15px;
  &:hover {
    background: darken($green, 10);
  }
}

.Forgot {
  font-family: $RubikRegular; 
  display: block;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 7px;
  &:hover {
    color: #fff;
    background: $green;
  }
}

.SocialBtn {
  display: flex;
  width: 100%;
  @extend %clearfix;
  flex-direction: column;
}

.FacebookBtn {
  flex: 1;
  margin-bottom: 10px;
  position: relative;
  background: #3b5997;
  padding: 7px;
  display: block;
  border: none;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  line-height: 1.6;
  @include breakpoint(screen1023) {
    width: 100%;
    display: block;
    text-align: center;
  }
  &:before {
    float: right;
    @extend %fa;
    content: $fa-facebook;
    @include breakpoint(screen1023) {
      float: left;
    }
  }
  &:hover {
    background: darken(#3b5997, 5);
  }
}

.LinkedinBtn {
  flex: 1;
  margin-bottom: 10px;
  background: #007bb6;
  padding: 7px;
  color: #fff;
  display: block;
  border-radius: 4px;
  text-align: center;
  position: relative;
  line-height: 1.6;
  @include breakpoint(screen1023) {
    width: 100%;
    text-align: center;
    display: block;
    padding: 7px;
    margin-bottom: 15px;
  }
  &:before {
    float: left;
    @extend %fa;
    content: $fa-linkedin;
  }
  &:hover {
    background: darken(#007bb6, 5);
  }
}

.LoginSubmit, .loginsubmitgoogle {
  font-family: $RubikRegular;
  background: $green;
  width: 100%;
  color: #fff;
  font-size: 15px;
  padding: 7px;
  border-radius: 4px;
  height: 35px;
  border: none;
  cursor: pointer;
  outline: none;
  @include transition(0.3s all);
  &:hover {
    background: darken($green, 5);
  }
}

.BottomArrowCnt {
  text-align: center;
  padding: 10px;
  background: #2b2342;
  cursor: pointer;
  @include transition(0.3s all);
  &:hover {
    background: darken(#2b2342, 5);
  }
}

.BottomArrow {
  position: relative;
  display: inline-block;
  &:after {
    @include transition(0.3s all);
    content: "";
    position: absolute;
    top: 0;
    margin: -12px auto 0;
    right: 0;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: #fff transparent transparent transparent;
  }
  &:hover {
    &:after {
      border-color: #fff transparent transparent transparent;
    }
  }
}

.MoveBottom {
  // float: left;
  // margin-top: 7px;
  display: inline-block;
  letter-spacing: 2px;
  margin-right: 10px;
  color: #fff;
  font-size: 20px;
}

.leftContent {
  width: 30%;
  float: left;
  @include breakpoint(screen1023) {
    padding-right: 20px;
  }
  @include breakpoint(screen767) {
    padding: 0;
    float: none;
    margin: 0 auto;
    width: 220px;
  }
}

.rightContent {
  width: 70%;
  float: left;
  text-align: right;
  padding-left: 20px;
  @include breakpoint(screen1023) {
    width: auto;
    float: none;
    text-align: left;
    padding-left: 0;
  }
  @include breakpoint(screen767) {
    width: 100%;
    margin-top: 20px;
    text-align: center;
  }
}

.freelancerContent {
  @extend %clearfix;
  border-bottom: 1px solid #d0d1da;
  padding: 30px 0;
  h1 {
    margin-right: 100px;
    @include breakpoint(screen1023) {
      margin-right: 0;
    }
  }
  p {
    font-size: 20px;
    @include breakpoint(screen1023) {
      font-size: 18px;
      line-height: 1.2;
    }
  }
  img {
    max-width: 100%;
  }
}

.resumeContent {
  @extend %clearfix;
  border-bottom: 1px solid #d0d1da;
  padding: 30px 0;
  p {
    font-size: 20px;
    @include breakpoint(screen1023) {
      font-size: 18px;
      line-height: 1.2;
    }
  }
  .leftContent {
    width: 70%;
    padding-right: 20px;
    @include breakpoint(screen1023) {
      width: auto;
      padding-right: 0;
      float: none;
    }
    @include breakpoint(screen767) {
      width: 100%;
      margin-top: 20px;
      text-align: center;
    }
  }
  .rightContent {
    width: 30%;
    padding-left: 0;
    float: right;
    @include breakpoint(screen1023) {
      padding-left: 20px; // float: right;
    }
    @include breakpoint(screen767) {
      padding: 0;
      float: none;
      margin: 0 auto;
      width: 220px;
    }
  }
  img {
    max-width: 100%;
  }
}

.chooseContent {
  @extend %clearfix;
  padding: 90px 0;
  border-bottom: 1px solid #d0d1da;
  @include breakpoint(screen767) {
    padding: 50px 0;
  }
}

.heaingCnt {
  display: table;
  margin: 0 auto 75px;
  border-bottom: 2px solid #483e64;
  padding: 0 40px;
  @include breakpoint(screen767) {
    margin-bottom: 35px;
    padding: 0;
    text-align: center;
  }
}

.ConsultantCnt {
  border-right: 1px solid #d0d1da;
  float: left;
  width: 50%;
  padding-right: 20px;
  @include breakpoint(screen767) {
    width: 100%;
    padding: 0;
    text-align: center;
    border-right: none; // border-bottom: 1px solid #d0d1da;
  }
  h1 {
    text-align: center;
    font-family: arial;
    font-weight: bold;
  }
  p {
    font-size: 20px;
    @include breakpoint(screen767) {
      font-size: 18px;
      line-height: 1.2;
    }
  }
}

@include breakpoint(screen767) {
}

.CompaniesCnt {
  float: left;
  width: 50%;
  padding-left: 20px;
  @include breakpoint(screen767) {
    width: 100%;
    padding: 0;
    text-align: center;
    border: none;
    margin-top: 20px;
  }
  h1 {
    text-align: center;
    font-family: arial;
    font-weight: bold;
  }
  p {
    font-size: 20px;
    @include breakpoint(screen767) {
      font-size: 18px;
      line-height: 1.2;
    }
  }
}

.UserInfo {
  padding: 60px 0;
}

.UserSummaryCnt {
  text-align: center;
  li {
    display: inline-block;
    width: 33%;
    border-right: 1px solid #d0d1da;
    @include breakpoint(screen767) {
      width: 100%;
      padding-bottom: 30px;
      margin-bottom: 40px;
      border-bottom: 1px solid #d0d1da;
      border-right: none;
    }
    &:last-child {
      border: none;
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
  }
}

.skillsAvailable,
.freelanceHire,
.workdone {
  border: 1px solid #483e64;
  display: inline-block;
  padding: 7px 10px;
  text-align: center;
  min-width: 170px;
  position: relative;
  @include breakpoint(screen1023) {
    min-width: 200px;
    padding: 10px;
  }
  &:before {
    position: absolute;
    content: "";
    top: -25px;
    margin-left: -17px;
    left: 50%;
    background: center no-repeat #ededee;
  }
}

.skillsAvailable {
  &:before {
    @include spriteIcon(skill);
    background-color: #ededee;
  }
}

.freelanceHire {
  &:before {
    @include spriteIcon(profile);
    background-color: #ededee;
  }
}

.workdone {
  &:before {
    @include spriteIcon(workdone);
    background-color: #ededee;
    top: -17px; 
  }
}

.SkillValue {
  font-size: 50px;
  color: #483e64;
  @include breakpoint(screen767) {
    font-size: 35px;
  }
}

.skillText {
  font-size: 15px;
  position: absolute;
  bottom: -10px;
  right: 20px;
  left: 20px;
  display: block;
  padding: 0 10px;
  background: #ededee;
}

.lightBg {
  background: #fafafa;
  width: 100%;
}

.pageScrollr {
  position: fixed;
  right: 20px;
  width: 20px;
  z-index: 100;
  text-align: center;
  top: 50%;
  font-size: 0;
  li {
    display: block;
    a {
      border-radius: 50%;
      display: inline-block;
      margin-bottom: 10px;
      background: #e6e7e8;
      width: 15px;
      height: 15px;
      cursor: pointer;
      .bgUpdate & {
        background: $darkBlue;
      }
    }
    &.active {
      a {
        border: 5px solid #e6e7e8;
        background: #fff;
        width: 20px;
        height: 20px;
        .bgUpdate & {
          border: 5px solid darken($darkBlue, 10);
          background: $darkBlue;
        }
      }
    }
  }
}

.close-btn {
  display: inline-block;
  border: none;
  background: transparent;
  @include border-radius(50%);
  width: 25px;
  height: 25px;
  position: relative;
  cursor: pointer;
  right: 5px;
  top: 5px;
  position: absolute;
  @include transition(0.3s all);
  &:hover,
  &.activeBtn {
    background: $red $imp;
  }
  &:before {
    display: inline-block;
    content: "";
    margin: auto;
    width: 10px;
    height: 10px;
    background: url("../../../../assets/images/Icons/cross.png") no-repeat 0 0;
  }
}

