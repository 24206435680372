@import "../../../../assets/sass/importFiles";
.searcher-opportunity-form {
  width: 100%;
  position: relative;
  padding: 10px;
  .company-form {
    width: 100%;
  }
  @media (max-width:330px) {
    .form-row {
      display: flex;
      flex-direction: column;
      color: $purple2;
     
    }
  }

  .rc-slider {
    margin-right: 30px;
  }

  .hourly-fee {
    margin-right: 15px;
  }
  .round-circle-list {
    display: table-cell;
    height: 100%;
    vertical-align: top;
  }
  .SendButton {
    margin: 0 10px;
  }
  .action-buttons {
    justify-content: center;
    .button-wrapper{
      width: 100%;
      max-width: 300px;
      margin: 0 10px;
      @media (max-width:330px ) {
        margin: 5px 0px;
      }
      .SendButton{
        margin: 0 0;
      }
    }
  }

  .datepicker-input {
    min-width: 0;
    width: 100%;
    border: transparent;
    border-radius: 3px;
    outline: none;
    padding: 6px 10px;
    height: 28px;
    font-size: 13px;
    font-family: "rubik", Arial, sans-sarif;
    background: #f5f2ff;
    color: #9783cf;
    &.inValid {
      border: 1px solid $red;
    }
  }
}
