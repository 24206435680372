@import "../../../../../assets/sass/importFiles";
.present-detail-component-with-opportunity {
  padding: 10px 10px;
  width: 100%;
  .company-image-wrap {
    display: table;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    background: $offwhite2;
    width: 105px;
    height: 105px;
  }
  .roundImg {
    max-width: 100%;
  }
  .company-name {
    padding: 10px 0 0;
    display: block;
  }
  .MsgWrapper {
    padding-top: 0;
  }
}
