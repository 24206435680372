@import "../../assets/sass/importFiles";
.default-button {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  border: none;
  display: block;
  background: $green;
  padding: 12px;
  margin-top: 20px;
  border-radius: 3px;
  font-size: 16px;
  font-family: $RubikRegular; // @extend .bebas500;
  color: $white;
  line-height: 1;
  transition: 0.3s all;
  &:hover {
    background: darken($green, 5);
  }
}
