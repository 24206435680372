@import "../../../assets/sass/importFiles";
.message-inbox {
  .listLabel {
    margin: 0;
    font-family: $RubikRegular;
    font-size: 14px;
  }
  .SelectedItem {
    font-weight: 500;
  }
}

.chip-new-msg {
  background-color: #f4f2fe;
  border-radius: 25px;
  padding: 2px 4px;
  color: #8E81F5;
  text-align: center;
}
.time-message{
  margin: 6px 0px 0px 0px;
}
.message-detail-new{
  display: flex;
  flex-direction: column;
  height: 100%;
  .message-detail-inner{
    flex: 1;
    overflow: auto;
  }
}

