@import "../../../assets/sass/importFiles";
.message-new {
  .search-list-wrapper {
    flex: 1;
    position: relative;
  }
  .round-search {
    width: 100%;
  }
  .list-wrapper {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1;
    left: 0;
    background: $white;
    box-shadow: lighten($color: $black, $amount: 50) 0px 2px 6px 0;
  }
  .round-circle-list {
    padding: 10px 7px;
    .closeBtn {
      background: $offwhite2 $imp;
      position: relative;
      right: 2px;
      margin-top: 1px;
      top: 0;
      margin: 4px 4px 4px 10px;
      &:hover {
        background: $red $imp;
      }
    }
  }
  .SendBtn:before {
    margin-bottom: 6px;
  }
  @media (max-width: 330px) {
    .heading {
      font-size: 12px;
    }
  }
  .message-search{
    .ant-select-selection-overflow{
      flex-wrap: nowrap;
      overflow: auto;
    }
  }
 
}

.cursor-pointer{
  cursor: pointer;
}