
@import "../../../../assets//sass/variables";
.CButton {
	padding: 0px 20px;
	border-radius: 4px;
	display: flex !important;
	justify-content: center;
	align-items: center;
	gap: 4px;
	// background-color: $lightPurple;
	color: white;
	// border-color: $lightPurple;
	// &:hover , &:focus {
	// 	background-color: $lightPurple;
	// 	color: white;
	// 	border-color: $lightPurple;
	// }
	> span {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;

	}
    &.noBorder {
        border: none;
    }
    &.whiteBackground {
        background-color: white;
		border-color: $lightPurple;
		> span {
			color: $lightPurple;
		}
    }
}

.CButton_large {
	// height: 40px !important;
}
