@import "../../../../assets/sass/importFiles";
.resume-parser {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .js_upload_btn {
    position: relative;
  }
.p-text1,.p-text2{
  font-size: 24px !important;
}
  h3 {
    margin: 0px;
  }
  .lookup-head {
    margin-bottom: 20px;
  }
  .settings-container {
    .draggable:before {
      background: #ffffff!important;
      border-radius: 50%;
      height: 22px;
      box-sizing: border-box;
      cursor: pointer;
      color: #f54949;
      transform: rotate(45deg);
    }
  }
}
