@import "../../../../../assets/sass/importFiles";
.opportunity-detail-component {
  display: flex;
  width: 100%;
  .coll-expenses {
    > div {
      display: flex;
      justify-content: space-between;
      margin: 5px;
      label {
        flex: 1;
        &:first-child {
          flex: 2;
        }
        &:not(:first-child) {
          text-align: inherit;
        }
      }
    }
  }
  .add-btn {
    width: 30px;
    border-radius: 50%;
    background: #04caa7;
    outline: none;
    padding: 0;
    height: 30px;
    margin-bottom: 20px;
    border: none;
    align-self: center;
    margin-top: 5px;
    margin-right: 5px;
    align-self: center;
    margin-left: 5px;
    &.disabled-class {
      pointer-events: none;
      cursor: default;
      opacity: 0.5;
      border: 1px solid #9783cf;
      background: #dfd9f7;
      &:before {
        color: #9783cf;
      }
    }
    &:before {
      @extend %fa;
      font-size: 13px;
      content: $fa-pencil;
      color: $white;
      vertical-align: middle;
      @include rotate(-5deg);
      margin: 5px;
      box-sizing: border-box;
    }
    @include breakpoint(screen767) {
      height: 20px;
      width: 20px;
      &:before {
        margin: -4px 1px 0px 1.5px;
        font-size: 15px;
      }
    }
  }
  .start-end {
    .button-wrapper {
      max-width: 40px;
      .help-icon {
        right: 0;
      }
    }
  }

  .invLabelInterest {
    display: inline-block;
  }
  .MsgWrapper {
    width: 100%;
  }
  .accepted {
    color: $green;
  }
  .declined {
    color: $red;
  }
  .noAction {
    color: $yellow;
  }
  .action-buttons {
    margin: 0 10px;
  }
  .labelName {
    min-width: 100px;
  }
  .opp-accepted {
    border: none $imp;
    padding: 0;
    height: 40px;
    border-radius: 3px;
    background: $lightPurple2 $imp;
    width: 100%;
    color: $white;
    flex: 1;
    margin-bottom: 10px;
    &.active {
      background: $green $imp;
    }
  }
  .opp-declined {
    @extend .opp-accepted;
    &.active {
      background: $red $imp;
    }
  }
  .opp-pending {
    @extend .opp-accepted;
    &.active {
      background: $yellow $imp;
    }
  }
}
