@import "../../../../assets/sass/importFiles";
.lead-page .benefit-page {
  h6,
  h5,
  .proHeading {
    color: $white !important;
  }
  .carousel .slide {
    background: #3e335e;
  }
  .benefitPanel {
    .tabList {
      display: flex;
      flex-flow: row wrap;
    }
    display: flex;
    flex-flow: column;
    .tabItem {
      float: left;
      width: 32%;
      padding: 20px;
      color: $white;
      line-height: 1;
      @include breakpoint(screen1023) {
        padding: 30px 5px;
      }
      @include breakpoint(screen767) {
        width: 100%;
      }
      h6,
      h5 {
        text-align: left !important;
        margin: 10px 0px !important;
      }
      h5 {
        font-size: 15px !important;
      }
    }

    .inline-flex {
      display: flex;
      flex-flow: row;
      > img {
        width: 400px;
        flex-basis: 50%;
        margin: 10px 50px;
        align-self: center;
      }
      @include breakpoint(screen1023) {
        flex-flow: column;
        &.ipro-flex {
          flex-flow: column-reverse;
        }
      }
      @include breakpoint(screen767) {
        flex-flow: column;
        > img {
          width: 90%;
        }
        &.ipro-flex {
          flex-flow: column-reverse;
        }
      }
    }

    .tabImg {
      max-width: 70%;
      display: block;
      margin: 0 auto;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      padding: 20px;
      background-color: $white;
      &.purple {
        border: 3px solid $purple;
      }
      &.orange {
        border: 3px solid $orange;
      }
      &.green {
        border: 3px solid $green;
      }
      @include breakpoint(screen767) {
        width: 150px;
        height: 150px;
      }
    }
  }

  .toggle-button {
    align-self: center;
    font-family: "rubik", Arial, sans-sarif;
    background: #04caa7;
    color: $white;
    font-size: 14px;
    padding: 6px 10px;
    display: inline-block;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    outline: none;
    margin-right: 10px;
    transition: 0.3s all;
    margin-bottom: 10px;
  }
}
