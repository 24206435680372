@import "../../assets/sass/importFiles";

.resume-page {
  .importBtn {
    margin: 0 auto;
    display: table;
    font-size: 15px;
    color: $green;
    line-height: normal;
    font-family: $RubikRegular;
    background: none;
    border: none;
  }

  .pdfText {
    // position: absolute;
    text-align: center;
    margin-top: 15px;
    color: $offwhite2;
    font-size: 16px;
    font-family: $RubikRegular;
    word-wrap: break-word;
  }

  .downloadPdfDrag {
    width: 100%;
    float: right;
  }

  .demo-droppable {
    height: 200px;
    margin-left: 0 $imp;
    &.drag-accepted {
      border: 3px dashed $green;
    }
    p {
      margin: auto;
    }
  }

  .inputfile {
    content: "Choose PDF File";
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }

  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: "Select some files";
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }
  .custom-file-input:hover::before {
    border-color: black;
  }
  .custom-file-input:active::before {
    background: linear-gradient(top, #e3e3e3, #f9f9f9);
  }

  .resumeImportText {
    font-family: $RubikRegular;
    font-size: 15px;
    color: $purple2;
    display: block;
    line-height: 1.2;
    float: right;
    width: 70%;
    padding-left: 30px;
  }
  .drag-pdf-component {
    width: 100%;
    padding: 10px 0 0;
    .closeBtn {
      top: 20px;
    }
  }
  .center-content {
    display: flex;
    align-items: center;
    padding-top: 62px;
    padding: 30px;
  }
  .phillipImg {
    width: 52px;
    height: 52px;
    left: 20px;
    top: 15px;
    img {
      width: 52px;
    }
  }

  .companyBigIcon {
    width: 20%;
    float: left;
    img {
      max-width: 100%;
    }
  }
  .import-resume-body {
    padding: 10px;
  }
  @include breakpoint(screen767) {
    .ResumeHeading {
      padding: 6px 100px 6px 10px !important;
    }
    .DownArrow {
      // right: 50px !important;
    }
  }
  @include breakpoint(screen320) {
    .ResumeHeading {
      font-size: 10px;
    }
    .ResumeItem {
      font-size: 10px !important;
    }
  }
  .AvailableList {
    ul {
      margin-bottom: 0;
    }
  }
  @media (max-width: 330px) {
    .heading {
      font-size: 12px;
    }
  }
}
