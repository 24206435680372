@import "../../../assets/sass/importFiles";
.network-detail-component {
  display: flex;
  width: 100%;

  .networkImage {
    max-width: 100%;
  }
  .MsgWrapper {
    width: 100%;
  }

  .roundeImg {
    max-width: 100%;
    height: 105px;
  }

  .invRequest {
    width: 100%;
    .button-wrapper{
      margin: 0 10px;
      width: 100%;
    }
  }

  .invAccept {
    margin: 0 10px 0 0;
  }

  .dashItemImg {
    width: 104px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 104px;
    position: relative;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    background: $offwhite2;
  }
  .no-dp {
    .roundeImg {
      max-width: 40px;
    }
  }
}
