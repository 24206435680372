@import "../../../../assets/sass/importFiles";
.market-analyzer {
  background: #3e335e;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: unset;
  flex: 1 1;
  height: 100%;
  color: $white;
  padding: 20px;
  .proHeading {
    color: $white !important;
  }
  h6 {
    color: #ffffff !important;
    margin-bottom: 10px !important;
  }
  .main-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: unset;
    width: 100%;
    color: $white;
    flex-flow: row;
    justify-content: space-between;
    padding: 30px 60px;
    .left-div {
      flex: 1 1;
      align-self: center;
      padding: 20px;
    }
    .right-div {
      flex: 1 1;
      align-self: center;
      .first-view {
        display: flex;
        flex: 1;
      }
      .draggable {
        color: $white;
        border: 1px solid $white;
      }
      .resume-upload-row {
        text-align: center;
        align-self: center;
        flex: 1;
      }
      .sliderImageWrap {
        flex: 0.3;
      }
      .heading-div {
        display: flex;
        flex-flow: row;
        justify-content: center;
      }
      h6 {
        color: $white;
      }
    }
  }
  .settings-container {
    width: 100%;
    align-self: center;
    padding-top: 10px;
  }
  .step-div {
    border: 1px solid $white;
    min-height: 70px;
    border-radius: 5px;
    margin: 0px 25px;
    justify-content: flex-start;
    display: flex;
    width: 70%;
    align-self: center;
    @include breakpoint(screen767) {
      width: 90%;
      flex-flow: column;
      height: max-content;
      min-height: max-content;
      margin-right: 5%;
      margin-left: 1%;
    }
    .step-circle {
      display: flex;
      flex-flow: column;
      background: $white;
      color: #ffffff;
      font-size: 13px;
      width: 50px;
      height: 68px;
      border-radius: 5px;
      margin-right: 10px;
      padding-top: 15px;
      text-align: center;
      width: 80px;
      height: 80px;
      @include breakpoint(screen767) {
        flex-flow: row;
        width: 100%;
        height: unset;
        justify-content: center;
        padding-bottom: 10px;
        padding-top: 10px;
        label {
          margin-right: 10px;
        }
        label,
        span {
          font-size: 16px;
        }
      }
    }
    .step-heading {
      width: 100%;
      align-self: center;
      padding: 8px;
      label {
        font-size: 17px;
        font-weight: 900;
      }
    }
  }

  .mixed-chart {
    display: flex;
    justify-content: center;
    border-radius: 10px;
    color: #3e335e;
    > div {
      background: white;
      &:first-of-type {
        border-radius: 10px 0px 0px 10px;
      }
      &:nth-of-type(2) {
        border-radius: 0px 10px 10px 0px;
      }
    }
    .apexcharts-menu-icon {
      visibility: hidden;
    }
    @include breakpoint(screen767) {
      flex-flow: column;
    }
  }
  .upload-div {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    align-self: center;
    padding-top: 20px;
    width: 100%;
  }
  .top-salaries {
    display: flex;
    flex-flow: column;
    justify-content: center;
    .salary-item {
      margin: 5px;
      text-align: start;
      padding: 5px 5px;
      color: #3e335e;
      label {
        &:first-of-type {
          font-weight: 900;
          font-size: 15px;
        }
      }
      &.selected {
        background: #04caa7;
        color: $white;
        padding: 5px 5px;
        border-radius: 5px;
      }
    }
    @include breakpoint(screen767) {
      margin-right: 10px;
    }
  }

  .js_upload_btn {
    background: $green;
    padding: 6px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 3px;
    color: $white;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    font-size: 13px;
    min-width: 155px;
    position: relative;
    overflow: hidden;
    height: 30px;
    input[type="file"] {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
    }
  }
  h3 {
    margin: 0px;
    text-align: center;
  }
  .lookup-head {
    margin-bottom: 20px;
  }

  #progressInput {
    margin: 20px auto;
    width: 30%;
  }

  .circle-background,
  .circle-progress {
    fill: none;
  }

  .circle-background {
    stroke: #ddd;
  }

  .circle-progress {
    stroke: $white;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .circle-text {
    font-size: 3em;
    font-weight: bold;
    fill: $white;
  }

  .progress-bar-container {
    max-height: 150px;
    display: flex;
    align-self: center;
  }

  .nextstep-filters {
    display: flex;
    align-self: center;
    margin-top: 20px;
    @include breakpoint(screen767) {
      flex-flow: column;
    }
  }
  .select-input {
    margin: 5px;
    max-width: 200px;
    min-width: 200px;
    border: 1px solid $white;
    border-radius: 5px;
    height: 40px;
    .Select-control {
      background: #3e335e !important;
      color: $white !important;
      height: 35px;
      font-size: 15px;
      text-align: center;
      box-shadow: 1px 2px #8888888a;
      border-radius: 5px;
    }
    .Select-menu-outer {
      background: transparent;
    }
    .Select-value-label {
      line-height: 35px;
      color: $white !important;
    }
    .Select-arrow-zone:hover > .Select-arrow {
      border-top-color: $white !important;
    }
    .Select-arrow {
      border-color: $white transparent transparent;
    }
    .Select-option {
      padding: 10px 6px;
      font-size: 13px;
      color: $white;
      text-align: center;
      border: 1px solid $white;
      background: #3e335e;
      margin-bottom: 2px;
      border-radius: 5px;
      border: 2px solid transparent !important;
      border-bottom: none;
      margin-top: 2px;
      margin: 2px;
      z-index: 12342534534;
    }
  }

  @keyframes load {
    0% {
      left: 0;
      height: 30px;
      width: 15px;
    }
    50% {
      height: 8px;
      width: 40px;
    }
    100% {
      left: 235px;
      height: 30px;
      width: 15px;
    }
  }
  @include breakpoint(screen991) {
    .main-div {
      flex-flow: column;
      padding: 30px 10px;
      .right-div {
        min-width: 350px;
      }
      .left-div {
        width: 100%;
        .top-salaries {
          margin-right: 0px;
        }
        .mixed-chart {
          width: 100%;
        }
      }
    }
  }
  @include breakpoint(screen767) {
    .mixed-chart {
      > div {
        &:first-of-type {
          border-radius: 10px 10px 0px 0px;
        }
        &:nth-of-type(2) {
          border-radius: 0px 0px 10px 10px;
        }
      }
    }
  }
}
