@import "../../assets/sass/importFiles";
@import "./variables.scss";
.page-wrapper.philip-page {
  color: $white;
  font-family: 'Source Sans Pro' !important;
  background: $back-color-pale_purple_light;

  .product-menus{
    flex: 1 1;
    max-width: 80px;
    min-width: 80px;
    margin:10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0px;
     @include breakpoint(screen767) {
      flex: 1 1;
      max-width: 80%;
      margin: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      // margin-top: 45px;
      left:32px;
    }
    @media (max-width:330px){
      left:32px;
      flex-direction: row;
      max-width: 83%;
      
    }
  }
  .js_textarea_wrap{
    color: $white;
  }
  .loading-list-mask:empty {
    background-color: $back-color-pale_purple_dark;
    /* change height to see repeat-y behavior */
    background-image: linear-gradient(
        100deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%
      ),
      linear-gradient(#F1EDFF 20px, rgba(0, 0, 0, 0) 0%),
      linear-gradient(#F1EDFF 20px, rgba(0, 0, 0, 0) 0%),
      linear-gradient(#F1EDFF 20px, rgba(0, 0, 0, 0) 0%),
      linear-gradient(#F1EDFF 20px, rgba(0, 0, 0, 0) 0%);
  }
  .page-column{
    position: relative;
  }
  .datepicker-input,.input-text{
    font-family: 'Source Sans Pro' !important;  
  }
  .views-cnt{
    overflow: auto;
    flex-flow: row;
    .dashboard-view,.collaborations-view,.reports-view,.pools-view{
      height: 100%;
      flex: 1;
    }
    @include breakpoint(screen767) {
      flex-flow: column;
      .dashboard-view,.collaborations-view,.reports-view,.pools-view{
        width: 100%;
        flex: 1;
        // margin-top: 5px;
        overflow: auto;
      }
     
    }
  }
  .heading {
    @extend .lg-heading;
    background: #9783CF;
    color: $white;
    text-align: center;
    height: 45px;
  }
  .expand-heading {
    cursor: pointer;
    background: #9783CF;
    color: #ffffff;
  }
  .collapseBtn {
    height: 45px;
    width: 45px;
    background: #9783CF;
    color: $white;
    &:before {
      color: #ffffff;
    }
  }
  .emptySkeletonResult {
    margin-top: 50px;
  }
  .column-body {
    background: #DFD9F7;
    padding: 0px 0px;
    // overflow: auto;
    .start-end{
      display: flex;
      justify-content: space-between;
      >div{
        flex: 1;
        &:first-of-type{
          margin-right: 5px;
        }
      }
      >label{
        color: $font-color-light_grey_purple;
        margin-right: 5px;
        align-self: center;
        margin-bottom: 20px;
      }
    }
  }
  .tictell-list{
    padding-bottom: 50px;
  }
  .input-field textarea {
    height: 50px;
  }
  .action-button-wrapper{
    position: absolute;
    bottom: 0px;
    z-index: 999;
    margin-left: 10px;
    &:last-of-type{
      margin-right:10px;
      right: 0;
    }
    .help-icon{
      top: 0px;
      right: 0px;
    }
    @include breakpoint(screen767) {
      height: 55px;
      min-height: 55px;
      .button-wrapper:first-of-type{
        margin: 0px 0px 2px 2px;
      }
      .button-wrapper:last-of-type{
        margin: 0px 2px 2px 0px;
      }
    }
    .add-btn {
      width: 55px;
      @include border-radius(50%);
      background: $green;
      outline: none;
      padding: 0;
      height: 55px;
      margin-bottom: 10px;
      border: none;
      align-self: center;
      margin-top: 5px;
      @include breakpoint(screen767) {
        height: 45px;
        width: 45px;
      }
      &.disabled-class{
        pointer-events: none;
        cursor: default;
        opacity: 0.5;
        border: 1px solid $back-color-light_purple;
        background: $back-color-pale_purple_dark;
        &:before{
          color: $back-color-light_purple;
        }
      }
      &:before {
        @extend %fi;
        font-size: 30px;
        content: $fi-close;
        color: $white;
        vertical-align: middle;
        @include rotate(45deg);
        margin: -2px -4px 0 1px;
        box-sizing: border-box;
      }
    }
    .approve-btn {
      width: 55px;
      @include border-radius(50%);
      background: $green;
      outline: none;
      padding: 0;
      height: 55px;
      margin-bottom: 10px;
      border: none;
      align-self: center;
      margin-top: 5px;
      @include breakpoint(screen767) {
        height: 40px;
        width: 40px;
      }
      &.disabled-class{
        pointer-events: none;
        cursor: default;
        opacity: 0.5;
        border: 1px solid $back-color-light_purple;
        background: $back-color-pale_purple_dark;
        &:before{
          color: $back-color-light_purple;
        }
      }
      &:before {
        @extend %fi;
        font-size: 30px;
        content: $fi-Approved;
        color: $white;
        vertical-align: middle;
        margin: -1px 0px 0 1px;
        box-sizing: border-box;
      }
    }
    .reject-btn {
      width: 55px;
      @include border-radius(50%);
      background: $red;
      outline: none;
      padding: 0;
      height: 55px;
      margin-bottom: 10px;
      align-self: center;
      margin-top: 5px;
      border: none;
      @include breakpoint(screen767) {
        height: 40px;
        width: 40px;
      }
      &.disabled-class{
        pointer-events: none;
        cursor: default;
        opacity: 0.5;
        border: 1px solid $back-color-light_purple;
        background: $back-color-pale_purple_dark;
          &:before{
          color: $back-color-light_purple;
        }
      }
      &:before {
        @extend %fi;
        font-size: 30px;
        content: $fi-ApprovalRejected;
        color: $white;
        vertical-align: middle;
        margin: -1px 0px 0 1px;
        box-sizing: border-box;
      }
      &.status-1{
        @extend .disabled-class;
        &:before {
          @extend %fi;
          content: $fi-WaitingForApproval;
        }
      }
      
      &.status-2{
        @extend .disabled-class;
        &:before {
          @extend %fi;
          content: $fi-Approved;
        }
      }
      
      &.status-3{
        background: $purple4;
        &:before {
          @extend %fi;
          content: $fi-SendForApproval;
        }
      }

      &.status-4{
        background: $purple4;
        &.disabled-class{
          background: $back-color-pale_purple_dark;
        }
        &:before {
          @extend %fi;
          content: $fi-SendForApproval;
        }
      }
    }
  }
  .textarea-field{
    margin-bottom: 0px;
  }
  a{
    text-decoration: none;
    color: #8d82ab;
  }
  .coll-time,.coll-expenses{
    display: flex;
    flex-flow: column;
    color: #8d82ab;
    margin-bottom: 5px;
    >div{
      display: flex;
      justify-content: space-between;
      margin: 5px;
      label{
        flex: 1;
        &:first-child{
          flex: 2;
        }
        &:not(:first-child){
            text-align: end;
        }
      }
    }
  }
}
