@import "../../assets/sass/importFiles";
.custom-select {
  flex: 1 1;
  position: relative;
  input {
    width: 100%;
    background: $offwhite !important;
    height: 28px;
    box-shadow: none !important;
    color: $purple2;
    font-size: 13px;
    border: transparent;
    border-radius: 3px;
    line-height: normal;
  }
  .select-loading {
    -webkit-animation: Select-animation-spin 400ms infinite linear;
    animation: Select-animation-spin 400ms infinite linear;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    border-radius: 50%;
    border: 2px solid $gray3;
    border-right-color: $gray20;
    display: inline-block;
    position: absolute;
    vertical-align: middle;
    right: 24px;
    top: 6px;
    cursor: pointer;
  }
  .carrot-down {
    border-color: $gray60 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px;
    display: inline-block;
    height: 0;
    width: 0;
    position: absolute;
    right: 15px;
    top: 11px;
    cursor: pointer;
    margin-right: -0.4rem;
    background-color: #f7edff;
  }
  .carrot-down:hover {
    border-top-color: $loadingmaskText;
  }
  .carrot-up:hover {
    border-top-color: $loadingmaskText;
  }
  .carrot-up {
    @extend .carrot-down;
    border-color: transparent transparent $gray60;
    border-width: 0 5px 5px;
  }
  input:after {
    @extend %fi;
    content: $fi-arrow-down;
    border: 4px solid transparent;
    border-top: 4px solid $white;
    margin-left: 2px;
    margin-bottom: 3px;
    display: inline-block;
    vertical-align: bottom;
  }

  .custom-select-list {
    background: $white;
    width: 100%;
    position: absolute;
    top: 29px;
    z-index: 1;
    opacity: 1111;
    overflow-y: auto;
    max-height: 200px;
    -webkit-box-shadow: 0px 1px 8px 0px rgb(91, 90, 94);
    -moz-box-shadow: 0px 1px 8px 0px rgb(91, 90, 94);
    box-shadow: 0px 1px 8px 0px rgb(91, 90, 94);
    .list-item {
      padding: 5px 6px;
      color: #5b5757;
      background: $white $imp;
    }
    .list-item:hover {
      font-weight: normal;
      color: $black;
      background: $offwhite !important;
    }
  }
  .bold-text {
    font-weight: 600;
    color: $darkBlue;
  }
}
