@import "../../assets/sass/importFiles";
.notification-dialog {
  top: 0px;
  left: 0;
  right: 0;
  margin: auto;
  position: fixed;
  width: 90%;
  text-align: center;
  padding: 8px 10px 9px;
  z-index: 2000;
  font-size: 13px;
  border-radius: 0px 0px 5px 5px;
  border-bottom: 2px solid darken($lightGreen, 10);
  color: $white;
  box-shadow: rgba($black, 0.1) 0px 2px 4px;
  background: url("../../assets/images/dialog-bg.png") repeat-x 0 0 $lightGreen;

  cursor: pointer;
  &.notification-success {
    background-color: $lightGreen;
  }
  &.notification-error {
    background-color: $red;
    border-bottom: 2px solid darken($red, 20);
  }
  &.notification-info {
    background-color: $blue;
    border-bottom: 2px solid darken($blue, 20);
  }
}
