.modal-for-save-search {
  .ant-modal-content {
    height: 100%;
  }
  .ant-modal-body {
    height: 100%;
  }
  .main-modal {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
  }
}
